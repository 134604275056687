export class WebsiteAppConstants {
    public static PykubeLogo: string = "pykubelogo.png";
    public static TechnoHunkLogo: string = "tech-logo.png";
    public static PyTitle: string = "PyKube";
    public static TechnoTitle: string = "TechnoHunk";
    public static PyAddress: string = "2300 Valley View Lane, Irving TX, 75062";
    public static TechnoAddress: string =
        " H-11, Sector-23, Sanjay Nagar, Ghaziabad - 201002 (U.P.), India";
    public static PyEmail: string = "hr@pykube.net";
    public static TechnoEmail: string = "Info@technohunk.co.in";
    public static PyPhone: string = "417-254-9197";
    public static TechnoPhone: string = "+91 9873003702";
    public static PyFaviconLogo: string = "pykube-favicon.png";
    public static TechnoFaviconLogo: string = "techno-favicon.jpg";
    public static PyFaviconTitle: string = "PyKube - Career Transition";
    public static TechnoFaviconTitle: string = "TechnoHunk - Career Solustions";
    public static Year: string = "2023";
    public static PageSize: number[] = [10, 50, 100, 200, 500];
    public static AllowFiltering: boolean = true;
}
