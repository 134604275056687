<!-- Start Banner Area -->
<div class="banner-section">
    <div class="container">
        <div class="row m-0 align-items-center">
            <div class="col-lg-5 col-md-12 p-0">
                <div class="hero-content">
                    <h1 style="font-family: Raleway, sans-serif;">Training Orientation Session By <span style="color: #44ce6f;">Nagendra Kumar</span></h1>
                    <p>The Full Stack Java Developer Program is an intense bootcamp offering a complete suite of
                        software development skills.</p>
                    <a routerLink="/" class="btn btn-primary">Ask More Get More!</a>

                </div>
            </div>
            <div class="col-lg-7 col-md-12 p-0">
                <div class="hero-image">
                    <div class="main-image">
                        <img src="assets/img/banner-image/banner1.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/banner-image/banner2.png" class="wow animate__animated animate__fadeInLeft"
                            data-wow-delay="1.5s" alt="image" style="height:120px;">
                        <img src="assets/img/banner-image/banner3.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="2s" alt="image" style="height:100px;">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/banner-image/main-pic.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/banner-image/circle1.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image">
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner Area -->

<!-- Start Partner Area -->
<div class="partner-style-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner1.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner2.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner3.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner4.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner5.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner6.png" alt="image"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="margin-top:70px ;">
    <div class="container">
        <div>
            <div>
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;"><b>
                        <img src="https://findicons.com/files/icons/1788/large_icons_social/256/social_network.png" style="height:90px;">
                            
                        Interview Questions</b></h2>
                    <div class="bar"></div>
                    <p style="font-size:22px;color:black">
                       Here we will help consultant to be ready for the market. We will do practice with most frequently asked interview questions and coding execercise so that at least consultant can answers at least initial level of questions.
                    </p>
                  

                    <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">1. What is JavaScript?</span>
                        <br/> 
                        
                     </p>
                     <p class="p-ans">A high-level, interpreted programming language called JavaScript makes it possible to create interactive web pages and online apps with dynamic functionality. Commonly referred to as the universal language, Javascript is primarily used by developers for front-end and back-end work. </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    


                             <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">2. What are the different data types in JavaScript?</span>
                                <br/> 
                             </p>
                                <p class="p-ans">JavaScript has six primitive data types: </p> 
                                   <div class="blog-ans">
                                    <p class="p-ans">
                                   Number
                                    <br>
                                    Boolean
                                    <br>
                                    Null
                                    <br>
                                    Undefined
                                    <br>
                                 
                                   </div> <br>
                                   <p class="p-ans">It also has two compound data types:

                                   </p><br>
                                   <div class="blog-ans">
                                    <p class="p-ans">
                                   Number
                                    <br>
                                    Object
                                    <br>
                                    Array
                                    <br>
                                   
                                   </div> 
                                
                             <div style="height:30px;background-color:#fffff4 ">
                             </div>


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">3. What is hoisting in JavaScript?</span>
                                <br/> 
                                
                             </p>
                             <p class="p-ans">Hoisting is a JavaScript concept that refers to the process of moving declarations to the top of their scope. This means that variables and functions can be used before they are declared, as long as they are declared before they are used in a function.<br>
                                <br>

                                For example, the following code will print "Hello, world!" even though the greeting variable is not declared until after the console.log() statement.<br>
                              

                               
                                <img src="assets\img\javaScript.ans\ans3.png" class="img-ans">
                            </p> 

                             <div style="height:30px;background-color:#fffff4 ">
                            </div>

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">4. What is the difference between null and undefined?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">null is an assignment value that represents no value or an empty value, while undefined is a variable that has been declared but not assigned a value. <br>
                                <img src="assets\img\javaScript.ans\ans4.png" class="img-ans">
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">5. Why do we use the word “debugger” in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans"> The word “debugger” is used in JavaScript to refer to a tool that can be used to step through JavaScript code line by line. This can be helpful for debugging JavaScript code, which is the process of finding and fixing errors in JavaScript code. To use the debugger, you need to open the JavaScript console in your browser. Then, you can use debugger commands to comb through your code line by line.<br><br>

                                It's essential to know debugging techniques as well as the more general ideas behind code optimization and speed improvement. In addition to operating smoothly, efficient code significantly enhances the user experience.<br><br>
                                
                                For example, the following code will print the value of the x variable at each step of the debugger.<br>
                                <img src="assets\img\javaScript.ans\ans5.png" class="img-ans">
                                
                                </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">6. What is the purpose of the “this” keyword in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">The  <span style="color: rgb(9, 93, 203);"> this</span> keyword refers to the object that is executing the current function or method. It allows access to object properties and methods within the context of that object.
                            <br>
                            <img src="assets\img\javaScript.ans\ans6.png" class="img-ans">

                        </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">7. What is the difference between == and === operators in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">The equality <span style="color: royalblue;">==</span> operator is a comparison operator that compares two values and returns true if they are equal. The strict equality <span style="color: royalblue;">===</span> operator is also a comparison operator, but it compares two values and returns true only if they are equal and of the same type.<br>
                                <br><br> For example, the following code will return true, because the values of the x and y variables are equal.<br><br>
                                <img src="assets\img\javaScript.ans\ans7.png" class="img-ans">
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">8. What is the difference between “var” and “let” keywords in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">The <span style="color: royalblue;">var</span> and <span style="color: royalblue;">let</span>  keywords are both used to declare variables in JavaScript. However, there are some key differences between the two keywords.

                                The <span style="color: royalblue;">var</span>  keyword declares a global variable, which means that the variable can be accessed from anywhere in the code. The <span style="color: royalblue;">let</span>  keyword declares a local variable, which means that the variable can only be accessed within the block of code where it is declared.<br>
                               <br> <img src="assets\img\javaScript.ans\ans8.png" class="img-ans">
                                
                                </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">9. What are closures in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">Closures ( <span style="color: royalblue;"> closureFn</span>) are functions that have access to variables from an outer function even after the outer function has finished executing. They “remember” the environment in which they were created.
                                <br> <img src="assets\img\javaScript.ans\ans9.png" class="img-ans">
                            
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">10. What is event delegation in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">Event delegation is a technique where you attach a single event listener to a parent element, and that event listener handles events occurring on its child elements. It helps optimize performance and reduce memory consumption.
                                <br> <img src="assets\img\javaScript.ans\ans10.png" class="img-ans">

                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">11. What is the difference between “let”, “const”, and “var”?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans"><span style="color: royalblue;">let</span> and <span style="color: royalblue;">const</span> were introduced in ES6 and have block scope. <span style="color: royalblue;">let</span> is reassignable, and <span style="color: royalblue;">const</span> is non-reassignable. <span style="color: royalblue;">var</span> is function-scoped and can be redeclared and reassigned throughout the function.
                                <br> <img src="assets\img\javaScript.ans\ans11.png" class="img-ans">
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">12. What is implicit type coercion in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">Implicit type coercion is a JavaScript concept that refers to the automatic conversion of a value from one type to another. In JavaScript, this conversion follows a priority order that typically begins with strings, then numbers, and finally booleans. If you try to add a string to a number, JavaScript will implicitly coerce the number to a string before performing the addition operation because strings have the highest priority in type coercion.<br>
                            <br>
                            For example, when you combine the number 5 with the string '10' using the addition operator, the result is the string '510'. This occurs because JavaScript will implicitly convert the number 5 to a string following the priority of coercion, and then concatenate it to the string '10'.
                            <br> <img src="assets\img\javaScript.ans\ans11.png" class="img-ans">
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">13.Explain the concept of prototypes in JavaScript.</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">Prototypes are a mechanism used by JavaScript objects for inheritance. Every JavaScript object has a prototype, which provides properties and methods that can be accessed by that object.
                                <br> <img src="assets\img\javaScript.ans\ans13.png" class="img-ans">
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">14. What is the output of the following code? <br>
                                    <img src="assets\img\javaScript.ans\qsn14.png" class="img-ans">
                                </span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">The output will be <span style="color: navy;"> "57"</span>. The addition operation is performed from left to right, and when a string is encountered, it performs concatenation.</p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">15. How can you clone an object in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans"> There are multiple ways to clone an object in JavaScript. One common method is using the <span style="color: navy;"> Object.assign()</span> method or the spread operator <span style="color: navy;"> (...) </span>. 
                                <img src="assets\img\javaScript.ans\ans15.png" class="img-ans">
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <div style="background-color: #44ce6f;">
                 
                                <h1>Intermediate Concepts</h1>
                                
            
                             </div>
            
                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">16. What are higher-order functions in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">Higher order functions are functions that can accept other functions as arguments or return functions as their results. They enable powerful functional programming patterns in JavaScript.

                                <br><img src="assets\img\javaScript.ans\ans16.png" class="img-ans">
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">17. What is the purpose of the bind() method in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">The <span style="color: navy;"> bind()</span>  method is used to create a new function with a specified <span style="color: navy;"> this</span>  value and an initial set of arguments. It allows you to set the context of a function permanently.
                                <br><img src="assets\img\javaScript.ans\ans17.png" class="img-ans">
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">18. What is the difference between function declarations and function expressions?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">Function declarations are defined using the function keyword, while function expressions are defined by assigning a function to a variable. Function declarations are hoisted, while function expressions are not.
                                <br>
                                <img src="assets\img\javaScript.ans\ans17.png" class="img-ans">
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">19. What are the different types of errors in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">JavaScript can throw a variety of errors, including:

                             </p>
                             <div class="blog-ans">
                                <p >
                                   <b class="p-ans">a. errors:-</b> <span style="color: navy;">These errors occur when the JavaScript code is not syntactically correct. </span>
                                <br>
                                <b class="p-ans">b. Runtime errors:-</b> <span style="color: navy;">These errors occur when the JavaScript code is executed and there is a problem.</span>
                                <br>
                                <b class="p-ans">c. Logical errors:- </b><span style="color: navy;">These errors occur when the JavaScript code does not do what it is supposed to do.</span>
                                <br>
                               </p>
                               </div> 

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">20. What is memoization in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">Memoization is a technique that can be used to improve the performance of JavaScript code. Memoization works by storing the results of expensive calculations in a cache. This allows the JavaScript code to avoid re-performing the expensive calculations if the same input is provided again.
                                <br>
                                For example, the following code calculates the factorial of a number. The factorial of a number is the product of all the positive integers from one to the number.
                                <br>
                                <br>
                                <img src="assets\img\javaScript.ans\ans20.png" class="img-ans">

                                <br>
                                This code can be memoized as follows:

                                <img src="assets\img\javaScript.ans\ans20.1.png" class="img-ans">
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">21. What is recursion in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">Recursion is a programming technique that allows a function to call itself. Recursion can be used to solve a variety of problems, such as finding the factorial of a number or calculating the <span style="  text-decoration: underline;
                                text-decoration-color: rgb(0, 255, 102);"> Fibonacci sequence</span>. <br>
                                The following code shows how to use recursion to calculate the factorial of a number:
                                <br>
                                <img src="assets\img\javaScript.ans\ans21.png" class="img-ans">
                            
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">22. What is the use of a constructor function in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">A constructor function is a special type of function that is used to create objects. Constructor functions are used to define the properties and methods of an object.
                                <br>
                                The following code shows how to create a constructor function:

                                <br>
                                <img src="assets\img\javaScript.ans\ans22.png" class="img-ans">
                            
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">23. What is the difference between a function declaration and a function expression in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">A function declaration is a statement that defines a function. A function expression is an expression that evaluates to a function. 

                                <br>
                                <br>
                                The following code shows an example of a function declaration. This code defines a function named factorial. The factorial function calculates the factorial of a number.
                                <br>
                                <img src="assets\img\javaScript.ans\ans23.1.png" class="img-ans">
                                <br>
                                The following code shows an example of a function expression:

                            

                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">24. What is a callback function in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">A <span style="color:  #44ce6f;"> callback </span>function is a function passed as an argument to another function, which is then invoked inside the outer function. It allows asynchronous or event-driven programming.
                                <br>
                                <img src="assets\img\javaScript.ans\ans24.png" class="img-ans">
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">25. What are promises in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">Promises are objects used for asynchronous operations. They represent the eventual completion or failure of an asynchronous operation and allow chaining and handling of success or error cases.

                                <br>
                                <img src="assets\img\javaScript.ans\ans25.png" class="img-ans">
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">26. What is the difference between synchronous and asynchronous programming?</span>
                                <br/> 
                                Synchronous coding example:

                             </p>
                              
                             <p class="p-ans">In synchronous programming, the program execution occurs sequentially, and each statement blocks the execution until it is completed. In asynchronous programming, multiple tasks can be executed concurrently, and the program doesn’t wait for a task to finish before moving to the next one.
                                <br>
                                <br>
<br>
                                <img src="assets\img\javaScript.ans\ans26.png" class="img-ans">
                                <br>
                                Asynchronous code example:
                                <br>

                                <img src="assets\img\javaScript.ans\ans26.1.png" class="img-ans">
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">27. How do you handle errors in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">Errors in JavaScript can be handled using <span style="color: red;">try-catch</span>  blocks. The <span style="color: red;">try</span> block contains the code that may throw an error, and the <span style="color: red;">catch </span> block handles the error and provides an alternative execution path.
                                <br>
                                <br>
                                <img src="assets\img\javaScript.ans\ans27.png" class="img-ans">


                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">28. Explain the concept of event bubbling in JavaScript.</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">Event bubbling is the process where an event triggers on a nested element, and then the same event is propagated to its parent elements in the document object model (DOM) tree. It starts from the innermost element and goes up to the document root.

                                Example: <br>
                                <img src="assets\img\javaScript.ans\ans28.png" class="img-ans">
                                <br>

                                When you click on the child element, both the child and parent event handlers will be triggered, and the output will be:
                                <br>
                                <br>
                                <img src="assets\img\javaScript.ans\ans28,1.png" class="img-ans">
                            
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">29. What are arrow functions in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">Arrow functions are a concise syntax for writing JavaScript functions. They have a more compact syntax compared to traditional function expressions and inherit the this value from their surrounding scope.
<br>
<br>
                                For example:<br>

                            <img src="assets\img\javaScript.ans\ans29.png" class="img-ans">
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">30. What is the difference between querySelector and getElementById?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">querySelector is a more versatile method that allows you to select elements using CSS-like selectors, while getElementById specifically selects an element with the specified ID.<br>
                            
                                <br>
                                <img src="assets\img\javaScript.ans\ans30.png" class="img-ans">
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">31. What is the purpose of the setTimeout() function in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">The setTimeout() function is used to delay the execution of a function or the evaluation of an expression after a specified amount of time in milliseconds.


                               <br>
                                <br>
                                <img src="assets\img\javaScript.ans\ans31.png" class="img-ans"> 
                                <br>
                                Output after two seconds:
                                <br>
                                <br>
                                <img src="assets\img\javaScript.ans\ans31.1.png" class="img-ans">
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">32. What is event delegation and why is it useful?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">Event delegation is a technique where you attach a single event listener to a parent element to handle events occurring on its child elements. It’s useful for dynamically created elements or when you have a large number of elements.<br>
                                <br>
                                <img src="assets\img\javaScript.ans\ans32.png" class="img-ans">
                            
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">33. How can you prevent the default behavior of an event in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">You can use the preventDefault() method on the event object within an event handler to prevent the default behavior associated with that event.

                                <br>
                                <br>
                                <img src="assets\img\javaScript.ans\ans33.png" class="img-ans">
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">34. What is the difference between localStorage and sessionStorage in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">Both localStorage and sessionStorage are web storage objects in JavaScript, but they have different scopes and lifetimes.
                                <br>

                             </p>
                             <div class="blog-ans">
                                <p >
                               <span style="color: red;"> localStorage</span> persists data even after the browser window is<br> closed and is accessible across different browser tabs/windows of the same origin.<br>
                               <span style="color: red;"> sessionStorage</span> stores data for a single browser session and is accessible only <br>within the same tab or window.<br>
                               </p>
                               </div> 
                               <p>
                                <br>
                                <img src="assets\img\javaScript.ans\ans34.png" class="img-ans">
                               </p>
            </div>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">35. How can you convert a string to lowercase in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">You can use the toLowerCase() method to convert a string to lowercase in JavaScript.
                                <br>
                                <br>
                                <img src="assets\img\javaScript.ans\ans35.png" class="img-ans">
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                        </div> 

                        <div style="background-color: #44ce6f;">
             
                            <h1>Advanced Concepts</h1>
                            
        
                         </div>
                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">36. What is the purpose of the map() function in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">The map() function is used to iterate over an array and apply a transformation or computation on each element. It returns a new array with the results of the transformation.<br>
                            
                                <br>
                                <img src="assets\img\javaScript.ans\ans36.png" class="img-ans">

                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">37. What is the difference between splice() and slice()?</span>
                                <br/> 
                                
                             </p>
                              
                             <div class="blog-ans">
                                <p >
                               <span style="color: red;"> splice() </span> is used to modify an array by adding, removing, or replacing elements at a specific position.<br>
                               <span style="color: red;"> slice()</span> is used to create a new array that contains a portion of an existing array, specified by the starting and ending indices.<br>
                               </p>
                               </div> 
                              
           
                             <p class="p-ans">Example of splice():<br>

                                <br>
                                <img src="assets\img\javaScript.ans\ans37.png" class="img-ans">

                                Example of slice():

                                <br>
                                <br>

<br>
                                <img src="assets\img\javaScript.ans\ans37.1.png" class="img-ans">
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">38. What is the purpose of the reduce() function in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">The reduce() function is used to reduce an array to a single value by applying a function to each element and accumulating the result.<br>
                            
                                <br><img src="assets\img\javaScript.ans\ans38.png" class="img-ans">
                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">39. How can you check if an array includes a certain value in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">You can use the includes() method to check if an array includes a specific value. It returns true if the value is found, and false otherwise.
                                <br><br><img src="assets\img\javaScript.ans\ans39.png" class="img-ans">
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">40. What is the difference between prototype and instance properties in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">A prototype property is a property that is defined on the prototype object of a constructor function. Instance properties are properties that are defined on individual objects that are created by a constructor function.

                                Prototype properties are shared by all objects that are created by a constructor function. Instance properties are not shared by other objects.</p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">41. What is the difference between an array and an object in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">An array is a data structure that can store a collection of values. An object is a data structure that can store a collection of properties.<br>
                                Arrays are indexed by numbers. Objects are indexed by strings. Arrays can only store primitive data types and objects. Objects can store primitive data types, objects and arrays.
                           
                                <br><img src="assets\img\javaScript.ans\ans41.png" class="img-ans">
                           
                                <br>

                            </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">42. How can you remove duplicates from an array in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">One way to remove duplicates from an array is by using the Set object or by using the filter() method with the indexOf() method.

                                <br>
                                <br><img src="assets\img\javaScript.ans\ans42.png" class="img-ans">
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">43. What is the purpose of the fetch() function in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">The fetch() function is used to make asynchronous HTTP requests in JavaScript. It returns a Promise that resolves to the response from the server.
                                <br>
                                Example:
                                <br><img src="assets\img\javaScript.ans\ans43.png" class="img-ans">

                                
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">44. What is a generator function in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">A generator function is a special type of function that can be paused and resumed during its execution. It allows generating a sequence of values over time, using the yield keyword.
                                <br>
                                <br><img src="assets\img\javaScript.ans\ans44.png" class="img-ans">
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">45. What are the different events in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">There are many different events in JavaScript, but some of the most common events include:<br></p>
                             <div class="blog-ans">
                                <p >
                               <span style="color: red;"> Click: </span> The click event occurs when a user clicks on an HTML element.<br>
                              
                               <span style="color:#44ce6f;"> Mouseover: </span> The mouseover event occurs when a user's mouse pointer moves over an HTML element.<br>
                               <span style="color: black"> Keydown :</span> he keydown event occurs when a user presses a key on the keyboard.<br>
                               <span style="color:  chocolate;"> Keyup: </span> The keyup event occurs when a user releases a key on the keyboard.<br>
                               <span style="color: cornflowerblue"> Change :</span> The change event occurs when a user changes the value of an HTML input element.<br>
                               </p>
                               </div> 

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">46. What are the different ways to access an HTML element in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">There are three main ways to access an HTML element in JavaScript:</p>

                             <div class="blog-ans">
                                <p >
                               <span style="color: red;"> Click: </span> The click event occurs when a user clicks on an HTML element.<br>
                              
                              1.<b>Using the</b> <span style="color:#44ce6f;"> getElementById() </span> <b>method: </b> The <span style="color: #44ce6f;">getElementById()</span>  method takes a string as an argument and returns the HTML element with the specified ID.<br>
                              2.<b>Using the</b><span style="color: black"> getElementsByTagName() </span><b>method: </b> The <span style="color: crimson;">getElementsByTagName()</span>  method takes a string as an argument and returns an array of all the HTML elements with the specified tag name.<br>
                              3.<b>Using the</b> <span style="color:  chocolate;"> querySelector() </span><b>method: </b> The<span style="color: crimson;"> querySelector()</span>  method takes a CSS selector as an argument and returns the first HTML element that matches the selector.<br>
                               </p>
                               </div> 

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">47. What is the scope of a variable in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">The scope of a variable in JavaScript is the part of the code where the variable can be accessed. Variables declared with the var keyword have a local scope, which means that they can only be accessed within the block of code where they are declared. Variables declared with the let keyword have a block scope, which means that they can only be accessed within the block of code where they are declared and any nested blocks. Variables declared with the const keyword have a global scope, which means that they can be accessed from anywhere in the code.</p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">48. What are the different ways to create objects in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">There are multiple ways to create objects in JavaScript, including object literals, constructor functions, the<span style="color: #44ce6f;"> Object.create()</span> method and the class syntax introduced in ECMAScript 2015 (ES6).
                            <br>
                            <br>
                            Example using object literals:
                            <br>
                            <br><img src="assets\img\javaScript.ans\ans48.png" class="img-ans">
                        
                        </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">49. What is the purpose of the window object in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">The <span style=" color: blue;">window </span>object represents the browser  window. The window object can be used to access the browser’s features, such as the location bar, the status bar and the bookmarks bar.</p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">50. What is the purpose of the async and await keywords in JavaScript?</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans">The async and await keywords are used for handling asynchronous operations in a more synchronous-like manner. The async keyword is used to define an asynchronous function, and the await keyword is used to pause the execution of an async function until a promise is fulfilled or rejected.

                                <br>
                                <br>
                                Example:
                                <br>
                                <br><img src="assets\img\javaScript.ans\ans50.png" class="img-ans">
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            
                              
                             <p class="p-ans"></p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">.........</span>
                                <br/> 
                                
                             </p>
                              
                             <p class="p-ans"></p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 
                             

