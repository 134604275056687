import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-angular-interview-questions',
  templateUrl: './angular-interview-questions.component.html',
  styleUrls: ['./angular-interview-questions.component.scss']
})
export class AngularInterviewQuestionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
