<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2> Assistance for getting job</h2>
            
        </div>
    </div>
</div>

<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Assistance for getting job</h2>
                    <div class="bar"></div>
                    
                    <ul class="services-list1">
                        <li><span><i class="flaticon-check-mark"></i>Assessing the candidate's skills, experience, and career goals to identify job opportunities that are a good fit.
                            </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Providing guidance on how to create a compelling resume and cover letter that will attract the attention of potential employers. 
                            </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Offering advice on how to prepare for job interviews, including tips on what to expect and how to answer common questions.
                            </span></li>
                        <li><span><i class="flaticon-check-mark"></i>Connecting the candidate with potential employers and helping them network to increase their chances of finding job opportunities.</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Providing access to job training and education programs to help the candidate acquire the skills and knowledge needed to succeed in their chosen field.</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Supporting and guiding the candidate throughout the job search process, providing advice and assistance as needed.</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Continually assessing the candidate's progress and providing feedback and support to help them succeed in their job search.</span></li>
                       
                        
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
