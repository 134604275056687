<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <p>Our latest news</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<div class="blog-details-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-image">
                        <img src="https://img.freepik.com/free-vector/programer-learning-programming-languages-by-computer-laptop-website-tutorial-channel-online-education-class-vector-illustration-software-development-programming-languages-learning_1150-55428.jpg?w=1380&t=st=1671050557~exp=1671051157~hmac=0f73226f945b0a169255685a4d96ceb9753f82fae890ac995495374768f6fbef"
                            alt="image">
                    </div>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><span>Posted On:</span> <a routerLink="/">NOVEMBER 28, 2022</a></li>
                                <!-- <li><span>Posted By:</span> <a routerLink="/">John Anderson</a></li> -->
                            </ul>
                        </div>
                        <h3>A Beginner’s Guide to the 10 Most Popular Programming Languages</h3>
                        <p>You’re sitting in a Starbucks, trying to get through the countless emails and reports that
                            your boss needed by yesterday, when you hear some interesting chatter from the next table
                            over.</p>

                        <p>They’re throwing around terms like “Ruby”, “Java”, “Python”, and “Swift”. You wonder what
                            kind of conversation involves gems, coffee, snakes, and a controversial musical artist?</p>

                        <p>Hopefully, a quick Google search has led you to this article, and you can now join in the
                            enlightening debate and tell them interesting facts about “PHP”, all the while ignoring that
                            urgent text message from your boss asking why you’re researching illegal drugs on your work
                            computer.</p>

                        <!-- <blockquote class="wp-block-quote">
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                            <cite>Tom Cruise</cite>
                        </blockquote> -->
                        <p>Read on to learn about the most popular programming languages being used in today’s tech
                            world. Before we start, it’s useful to note that some of these might also be among our list
                            of the easiest programming languages for beginners, but not many. If you are looking to
                            become a web developer and want to know where is the best way to start learning to code,
                            it’s best to follow those guides instead.</p>
                        <p>So without further ado, here is out line-up of the most popular programming languages. If you
                            want to skip ahead to a particular one, just use the clickable menu.</p>

                        <ul class="article-features-list">
                            <li>Enjoy a cup of Java</li>
                            <li>JavaScript is not a cup of Java with cream and sugar</li>
                            <li>PHP is not the new street name for a drug</li>
                            <li>It’s Go time</li>
                            <li>Swift was not made by Taylor Swift</li>
                            <li>C is for…</li>
                            <li>C++ isn’t an above-average C student’s grade</li>
                            <li>C# was named after the musical note</li>
                            <li>Python was named after Monty Python. Yes, really </li>
                            <li>Ruby is a gem to learn</li>
                        </ul>

                        <!-- <ul class="wp-block-gallery columns-3">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog-image/blog1.jpg" alt="image">
                                </figure>
                            </li>
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog-image/blog2.jpg" alt="image">
                                </figure>
                            </li>
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog-image/blog3.jpg" alt="image">
                                </figure>
                            </li>
                        </ul> -->
                        <!-- <h3>10 Tactics for marketing your company</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry’s standard dummy text ever since the 1500s, when an unknown printer took.
                        </p> -->
                        <!-- <ul class="article-features-list">
                            <li>Devices can grind down to a halt.</li>
                            <li>Small companies Repairing business.</li>
                            <li>Repairs are competitive with anyone</li>
                            <li>Repair broken buttons, speakers</li>
                            <li>Repairs are competitive with anyone</li>
                        </ul> -->
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book. It has survived not only five
                            centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry’s standard dummy text ever since the 1500s, when an unknown printer took.
                        </p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book. It has survived not only five
                            centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p> -->
                    </div>
                    <!-- <div class="article-footer">
                        <div class="article-tags">
                            <span><i class="fas fa-bookmark"></i></span>
                            <a routerLink="/">Fashion</a>,
                            <a routerLink="/">Games</a>,
                            <a routerLink="/">Travel</a>
                        </div>
                        <div class="article-share">
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="comments-area">
                    <h3 class="comments-title">2 Comments:</h3>
                    <ol class="comment-list">
                        <li class="comment">
                            <article class="comment-body">
                                <footer class="comment-meta">
                                    <div class="comment-author vcard">
                                        <img src="assets/img/author1.jpg" class="avatar" alt="image">
                                        <b class="fn">James Anderson</b>
                                        <span class="says">says:</span>
                                    </div>
                                    <div class="comment-metadata">
                                        <a routerLink="/">
                                            <time>April 24, 2020 at 10:59 am</time>
                                        </a>
                                    </div>
                                </footer>
                                <div class="comment-content">
                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                                <div class="reply">
                                    <a routerLink="/" class="comment-reply-link">Reply</a>
                                </div>
                            </article>
                            <ol class="children">
                                <li class="comment">
                                    <article class="comment-body">
                                        <footer class="comment-meta">
                                            <div class="comment-author vcard">
                                                <img src="assets/img/author2.jpg" class="avatar" alt="image">
                                                <b class="fn">Steven Smith</b>
                                                <span class="says">says:</span>
                                            </div>
                                            <div class="comment-metadata">
                                                <a routerLink="/">
                                                    <time>April 24, 2020 at 10:59 am</time>
                                                </a>
                                            </div>
                                        </footer>
                                        <div class="comment-content">
                                            <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                        <div class="reply">
                                            <a routerLink="/" class="comment-reply-link">Reply</a>
                                        </div>
                                    </article>
                                </li>
                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/author3.jpg" class="avatar" alt="image">
                                                    <b class="fn">Sarah Taylor</b>
                                                    <span class="says">says:</span>
                                                </div>
                                                <div class="comment-metadata">
                                                    <a routerLink="/">
                                                        <time>April 24, 2020 at 10:59 am</time>
                                                    </a>
                                                </div>
                                            </footer>
                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                            <div class="reply">
                                                <a routerLink="/" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>
                                </ol>
                            </ol>
                        </li>
                        <li class="comment">
                            <article class="comment-body">
                                <footer class="comment-meta">
                                    <div class="comment-author vcard">
                                        <img src="assets/img/author2.jpg" class="avatar" alt="image">
                                        <b class="fn">John Doe</b>
                                        <span class="says">says:</span>
                                    </div>
                                    <div class="comment-metadata">
                                        <a routerLink="/">
                                            <time>April 24, 2020 at 10:59 am</time>
                                        </a>
                                    </div>
                                </footer>
                                <div class="comment-content">
                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                                <div class="reply">
                                    <a routerLink="/" class="comment-reply-link">Reply</a>
                                </div>
                            </article>
                            <ol class="children">
                                <li class="comment">
                                    <article class="comment-body">
                                        <footer class="comment-meta">
                                            <div class="comment-author vcard">
                                                <img src="assets/img/author4.jpg" class="avatar" alt="image">
                                                <b class="fn">James Anderson</b>
                                                <span class="says">says:</span>
                                            </div>
                                            <div class="comment-metadata">
                                                <a routerLink="/">
                                                    <time>April 24, 2020 at 10:59 am</time>
                                                </a>
                                            </div>
                                        </footer>
                                        <div class="comment-content">
                                            <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                        <div class="reply">
                                            <a routerLink="/" class="comment-reply-link">Reply</a>
                                        </div>
                                    </article>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <div class="comment-respond">
                        <h3 class="comment-reply-title">Leave a Reply</h3>
                        <form class="comment-form">
                            <p class="comment-notes">
                                <span id="email-notes">Your email address will not be published.</span>
                                Required fields are marked
                                <span class="required">*</span>
                            </p>
                            <p class="comment-form-comment">
                                <label>Comment</label>
                                <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525" required="required"></textarea>
                            </p>
                            <p class="comment-form-author">
                                <label>Name <span class="required">*</span></label>
                                <input type="text" id="author" name="author" required="required">
                            </p>
                            <p class="comment-form-email">
                                <label>Email <span class="required">*</span></label>
                                <input type="email" id="email" name="email" required="required">
                            </p>
                            <p class="comment-form-url">
                                <label>Website</label>
                                <input type="url" id="url" name="url">
                            </p>
                            <p class="comment-form-cookies-consent">
                                <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent">
                                <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                            </p>
                            <p class="form-submit">
                                <input type="submit" name="submit" id="submit" class="submit" value="Post Comment">
                            </p>
                        </form>
                    </div>
                </div> -->
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="fas fa-search"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_klob_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <article class="item">
                            <a routerLink="/blog-Details1" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2020-06-30">DECEMBER 14, 2022</time>
                                <h4 class="title usmall"><a routerLink="/blog-Details1">How To Get Into Tech (With
                                        Little to No Experience)</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-Details2" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2020-06-30">DECEMBER 6, 2022</time>
                                <h4 class="title usmall"><a routerLink="/blog-Details2">9 of the Best UI Design
                                        Portfolios That Will Inspire You</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-Details3" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2020-06-30">NOVEMBER 28, 2022</time>
                                <h4 class="title usmall"><a routerLink="/blog-Details3">A Beginner’s Guide to the 10
                                        Most Popular Programming Languages</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_recent_entries">
                        <h3 class="widget-title">Recent Posts</h3>
                        <ul>
                            <li><a>How to Become a Successful Entry Level UX Designer</a>
                            </li>
                            <li><a>How to start your business as an entrepreneur</a></li>
                            <li><a>How to be a successful entrepreneur</a></li>
                            <li><a>10 Building Mobile Apps With Ionic And React</a></li>
                            <li><a>Protect your workplace from cyber attacks</a></li>
                        </ul>
                    </div>
                    <!-- <div class="widget widget_archive">
                        <h3 class="widget-title">Archives</h3>
                        <ul>
                            <li><a routerLink="/">May 2020</a></li>
                            <li><a routerLink="/">April 2020</a></li>
                            <li><a routerLink="/">June 2020</a></li>
                        </ul>
                    </div> -->
                    <!-- <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li><a routerLink="/">Business</a></li>
                            <li><a routerLink="/">Privacy</a></li>
                            <li><a routerLink="/">Technology</a></li>
                            <li><a routerLink="/">Tips</a></li>
                            <li><a routerLink="/">Uncategorized</a></li>
                        </ul>
                    </div> -->
                    <!-- <div class="widget widget_meta">
                        <h3 class="widget-title">Meta</h3>
                        <ul>
                            <li><a routerLink="/">Log in</a></li>
                            <li><a routerLink="/">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/">WordPress.org</a></li>
                        </ul>
                    </div> -->
                    <!-- <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/">IT <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Klob <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Games <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </div> -->
                </aside>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Details Area -->

<!-- <app-account-button></app-account-button> -->
