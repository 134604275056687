<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Software Development</h2>

        </div>
    </div>
</div>

<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>   Software Development</h2>
                    <div class="bar"></div>

                    <ul class="services-list1">
                       
                        <li><span><i class="flaticon-check-mark"></i>  Software development: This involves
                                creating software applications to meet  specific business needs or requirements. This may involve designing and building a new                          application from scratch, or modifying and extending an existing application..</span>
                        </li>
                        <li><span><i class="flaticon-check-mark"></i> Web development: This involves the development of websites and web-based  applications. This may include the design and layout of the site, as well as the  development of the underlying functionality.</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Mobile app development: This involves the
                                development of apps for smartphones and tablets. This may include the design and development of both native apps (built
                                specifically for a particular platform) and cross-platform apps (built to run on
                                multiple
                                platforms).</span></li>
                        

                        <li><span><i class="flaticon-check-mark"></i>Maintenance and support: Software development
                                companies may also provide ongoing                        
                                maintenance and support for software applications to ensure that they remain up-to-date and functioning properly. This may include bug fixes, security updates, and the
                                addition of new features.</span></li>



                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>