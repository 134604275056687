<!-- Start Signup Area -->
<div class="signup-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="signup-image">
                <img src="assets/img/signup-page-bg.jpeg" alt="image">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="signup-content">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="signup-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/{{logo}}" alt="image"></a>
                            </div>
                            <h3>Are you ready to signup</h3>
                            <p>Already signed up? <a routerLink="/signin">Log in</a></p>
                            <ng-container *ngIf="response">
                                <div class="container" style="font-size:20px ;color: green;"
                                    *ngIf="response.status=='success'">
                                    {{message}}
                                </div>
                                <div class="container" style="font-size:20px ;color: red;"
                                    *ngIf="response.status=='fail'">
                                    {{message}}
                                </div>
                            </ng-container>
                            <div class="form">
                                <form #signupForm="ngForm">
                                    <table>
                                        <tr>
                                            <td class="text">Name*</td>
                                            <td class="input"><input type="text" name="name"
                                                    placeholder="Enter Your Name" [(ngModel)]="consultantsVO.name">
                                                <span>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text">Email*</td>
                                            <td><input type="text" name="email" placeholder="Enter Your Email"
                                                    [(ngModel)]="consultantsVO.email">
                                                <span style="width: min-content;">

                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text">Mobile*</td>
                                            <td><input type="text" name="mobile" placeholder="Enter Your Mobile Numer"
                                                    [(ngModel)]="consultantsVO.mobile">
                                                <div>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text">DOJ</td>
                                            <td><input type="date" name="doj" [(ngModel)]="consultantsVO.doj"></td>
                                        </tr>
                                        <tr>
                                            <td class="text">Stream*</td>
                                            <td>
                                                <select #teams (change)="onSelected()" id="select">
                                                    <option style="width:500px ;">Select a Stream</option>
                                                    <option value="'IT'">IT </option>
                                                    <option value="'Non IT'">Non IT</option>
                                                    <option value="'Other'">Other</option>
                                                </select>
                                                <div>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text">Gender</td>
                                            <td>
                                                <input type="radio" value="male" name="gender" id="male"
                                                    [(ngModel)]="consultantsVO.gender">
                                                <label for="male">Male</label>
                                                <input type="radio" name="gender" id="female" value="female">
                                                <label for="female">Female</label>
                                                <input type="radio" name="gender" id="other" value="other">
                                                <label for="other">other</label>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text">Photo</td>
                                            <td><input type="file" name="image" [(ngModel)]="consultantsVO.image"></td>
                                        </tr>
                                        <tr>
                                            <td class="text">Address*</td>
                                            <td><input type="text" name="address" [(ngModel)]="consultantsVO.address">
                                            </td>
                                            <div>

                                            </div>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <button class="btn1"
                                                    (click)="signupConsultants(); signupForm.resetForm()">Sign
                                                    up</button>
                                            </td>
                                        </tr>
                                    </table>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login Area -->