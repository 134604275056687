<!-- Start Banner Area -->
<div class="banner-section">
    <div class="container">
        <div class="row m-0 align-items-center">
            <div class="col-lg-5 col-md-12 p-0">
                <div class="hero-content">
                    <h1 style="font-family: Raleway, sans-serif;">Interview Preparation Session By <span style="color: #44ce6f;">Nagendra Kumar</span></h1>
                    <p>The Full Stack Java Developer Program is an intense bootcamp offering a complete suite of
                        software development skills.</p>
                    <a routerLink="/" class="btn btn-primary">Ask More Get More!</a>

                </div>
            </div>
            <div class="col-lg-7 col-md-12 p-0">
                <div class="hero-image">
                    <div class="main-image">
                        <img src="assets/img/banner-image/banner1.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/banner-image/banner2.png" class="wow animate__animated animate__fadeInLeft"
                            data-wow-delay="1.5s" alt="image" style="height:120px;">
                        <img src="assets/img/banner-image/banner3.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="2s" alt="image" style="height:100px;">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/banner-image/main-pic.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/banner-image/circle1.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image">
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner Area -->

<!-- Start Partner Area -->
<div class="partner-style-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner1.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner2.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner3.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner4.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner5.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner6.png" alt="image"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<br/>  <br/>   

<div class="invoicing-area ptb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;"><b>
                        <img src="https://findicons.com/files/icons/1788/large_icons_social/256/social_network.png" style="height:90px;">
                            
                        Interview Preparation & Making Ready for the market</b></h2>
                    <div class="bar"></div>
                    <p style="font-size:22px;color:black">
                       Here we will help consultant to be ready for the market. We will do practice with most frequently asked interview questions and coding execercise so that at least consultant can answers at least initial level of questions.
                    </p>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Otter</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Zoom</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Gmeet</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Webex</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Line 2</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Eclipse</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">MAVEN</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Gradle</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image">
                        <img src="https://clarusway.com/wp-content/uploads/2021/12/what-is-mock-interview.png" height="400px"
                            class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        
                      
                    </div>
                    
                   
                </div>
            </div>
        </div>
    </div>
</div>

<div style="margin-top:70px ;">
    <div class="container">
        <div>
            <div>
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;"><b>
                        <img src="https://findicons.com/files/icons/1788/large_icons_social/256/social_network.png" style="height:90px;">
                            
                        Interview Questions</b></h2>
                    <div class="bar"></div>
                    <p style="font-size:22px;color:black">
                       Here we will help consultant to be ready for the market. We will do practice with most frequently asked interview questions and coding execercise so that at least consultant can answers at least initial level of questions.
                    </p>
                    <div style="height:30px;background-color:#fffff4 ">

                    </div>
                    <p style="font-size:22px;color:black;background-color: ffeafc;">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: normal;color:black;font-size:24px;margin-left: 16px;"> What are different oops concept in java?</span>
                         <br/>
                            OOPs stands for Object Oriented Programming. The concepts in oops are similar to any other programming languages. Basically, it is program agnostic.
                            The different OOps concepts are :
                        </p>
<ul style="font-size:20px;color:black;font-weight: bold;">
    <li>
        <img src="assets/img/hicon.png"/> Polymorphism
</li>
<li>
    <img src="assets/img/hicon.png"/> Inheritance
</li>
<li>
    <img src="assets/img/hicon.png"/> Abstraction
</li>
<li>
    <img src="assets/img/hicon.png"/> Encapsulation
</li>
<li>
    <img src="assets/img/hicon.png"/> Aggreagation
</li>
<li>
    <img src="assets/img/hicon.png"/> Composition
</li>
<li>
    <img src="assets/img/hicon.png"/> Association
</li>
</ul>
      
                   <img style="height:60px ;" src="https://cdn-icons-png.flaticon.com/512/1090/1090965.png?w=826&t=st=1671838677~exp=1671839277~hmac=94400edc4240b584398dc0107c625895413cb72b5c61537e93173774268fb932">
                     <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: normal;color:black;font-size:24px;margin-left: 16px;">  What is polymorphism?</span>
                         <br/>
                        <img style="height:60px ;" src="https://cdn-icons-png.flaticon.com/512/1090/1090965.png?w=826&t=st=1671838677~exp=1671839277~hmac=94400edc4240b584398dc0107c625895413cb72b5c61537e93173774268fb932">
                     </p>
                     <div style="height:30px;background-color:#fffff4 ">
                    </div>


            <div style="height:30px;background-color:#fffff4 ">
            </div>
           <p style="font-size:22px;color:black;background-color: ffeafc;">
            <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
             <span style="font-weight: normal;color:black;font-size:24px;margin-left: 16px;">       What is ConcurrentModificationException in Java?</span>
             <br/>
              <img src="assets/img/kb/concurrent-modification-exception.png">
              
            </p>

            <div style="height:30px;background-color:#fffff4 ">
            </div>
            <div style="height:30px;background-color:#fffff4 ">
            </div>

            <div style="height:30px;background-color:#fffff4 ">
            </div>
           <p style="font-size:22px;color:black;background-color: ffeafc;">
            <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
             <span style="font-weight: normal;color:black;font-size:24px;margin-left: 16px;">Why we should write JUnit test cases?</span>
             <br/>
             <img src="assets/img/kb/why-we-should-write-junit.png"/>
            </p>

            <div style="height:30px;background-color:#fffff4 ">
            </div>
           <p style="font-size:22px;color:black;background-color: ffeafc;">
            <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
             <span style="font-weight: normal;color:black;font-size:24px;margin-left: 16px;">What is used of volatile keyword?</span>
             <br/>
             <img src="assets/img/kb/volatile.png"/>
            </p>


            <div style="height:30px;background-color:#fffff4 ">
            </div>
           <p style="font-size:22px;color:black;background-color: ffeafc;">
            <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
             <span style="font-weight: normal;color:black;font-size:24px;margin-left: 16px;">What is used of transient keyword?</span>
             <br/>
             <img src="assets/img/kb/why-we-should-write-junit.png"/>
            </p>

    </div>
</div>




<app-feedback></app-feedback>
<hr style="color:bisque"/>

<app-download></app-download>