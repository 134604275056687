<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Interview Preparation</h2>

        </div>
    </div>
</div>

<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2> Interview Preparation for job</h2>
                    <div class="bar"></div>

                    <ul class="services-list1">
                        <li><span><i class="flaticon-check-mark"></i> Assessing the candidate's skills, experience, and career goals to identify potential job opportunities and the types of interviews they may encounter.
                            </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Providing guidance on how to create a compelling resume and cover letter that will attract the attention of potential employers.
                            </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Offering advice on how to research the company and the job role to prepare for the interview, including tips on what to expect and how to answer common questions.</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Conducting mock interviews to help the candidate practice and improve their interview skills.</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Providing feedback and guidance on how to improve the candidate's performance during interviews, including advice on body language, tone of voice, and other factors that can impact their chances of success.</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Helping the candidate prepare for potential challenges or obstacles they may encounter during the interview process, such as difficult questions or unexpected scenarios.</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Supporting and guiding the candidate throughout the interview process, providing advice and assistance as needed to help them succeed.</span></li>

                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
