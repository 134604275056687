<!-- <section class=" bg-image"
    style="background-image: url('https://mdbcdn.b-cdn.net/img/Photos/new-templates/search-box/img4.webp');">
    <div class="mask d-flex align-items-center h-100 gradient-custom-3">
        <div class="container h-100">
            <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-12 col-md-9 col-lg-7 col-xl-6">
                    <div class="card" style="border-radius: 15px;">
                        <div class="card-body p-5">
                            <h2 class="text-uppercase text-center mb-5">Create an account</h2>

                            <form>

                                <div class="form-outline mb-4">
                                    <label class="form-label" for="form3Example1cg">Your Name</label>
                                    <input type="text" id="form3Example1cg" class="form-control form-control-lg" />
                                </div>

                                <div class="form-outline mb-4">
                                    <label class="form-label" for="form3Example3cg">Your Email</label>
                                    <input type="email" id="form3Example3cg" class="form-control form-control-lg" />
                                </div>

                                <div class="form-outline mb-4">
                                    <label class="form-label" for="form3Example4cg">Mobile</label>
                                    <input type="password" id="form3Example4cg" class="form-control form-control-lg" />
                                </div>

                                <div class="form-outline mb-4">
                                    <label class="form-label" for="form3Example4cdg">Date of Join</label>
                                    <input type="password" id="form3Example4cdg" class="form-control form-control-lg" />
                                </div>
                                <div class="form-outline mb-4 mb-0 me-4">
                                    <label class="form-label" for="form3Example4cdg">Gender:</label>
                                    <div class="form-check form-check-inline mb-0 me-4">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                            id="femaleGender" value="option1" />
                                        <label class="form-check-label" for="femaleGender">Female</label>
                                    </div>
                                    <div class="form-check form-check-inline ">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                            id="maleGender" value="option2" />
                                        <label class="form-check-label" for="maleGender">Male</label>
                                    </div>
                                    <div class="form-check form-check-inline ">
                                        <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                            id="otherGender" value="option3" />
                                        <label class="form-check-label" for="otherGender">Other</label>
                                    </div>
                                </div>
                                <div class="form-outline mb-4">
                                    <label class="form-label" for="form3Example4cdg">Stream</label>
                                    <input type="password" id="form3Example4cdg" class="form-control form-control-lg" />
                                </div>
                                <div class="form-outline mb-4">
                                    <label class="form-label" for="form3Example4cdg">Address</label>
                                    <input type="password" id="form3Example4cdg" class="form-control form-control-lg" />
                                </div>

                                <div class="form-check d-flex justify-content-center mb-5">
                                    <input class="form-check-input me-2" type="checkbox" value=""
                                        id="form2Example3cg" />
                                    <label class="form-check-label" for="form2Example3g">
                                        I agree all statements in <a href="#!" class="text-body"><u>Terms of
                                                service</u></a>
                                    </label>
                                </div>

                                <div class="d-flex justify-content-center">
                                    <button type="button"
                                        class="btn btn-success btn-block btn-lg gradient-custom-4 text-body">Register</button>
                                </div>

                                <p class="text-center text-muted mt-5 mb-0">Have already an account? <a href="#!"
                                        class="fw-bold text-body"><u>Login here</u></a></p>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->


<body class="bg-color">
    <section class="container">
        <div class="row justify-content-md-center">
            <form class="col-md-6 col-sm-12 bg-white p-5 rounded shadow"
                style="margin-top: 120px; margin-bottom: 50px;">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/pykube logo1.png" alt="image"></a>
                </div>
                <div class="col-12 text-center">
                    <h3>Open up your PyKube Account now</h3>
                    <p>Already signed up? <a routerLink="/signin">Log in</a></p>
                </div>
                <div class="mb-2">
                    <label for="name" class="form-label">Name</label>
                    <input type="text" class="form-control input-height" id="name">
                </div>
                <div class="mb-2">
                    <label for="email" class="form-label">Email address</label>
                    <input type="email" class="form-control input-height" id="email">
                </div>
                <div class="mb-2">
                    <label for="mobile" class="form-label">Mobile</label>
                    <input type="tel" class="form-control input-height" id="mobile">
                </div>
                <div class="mb-2">
                    <label for="date" class="form-label">Date of Join</label>
                    <input type="date" class="form-control input-height" id="date">
                </div>
                <div class="mb-2">
                    <label class="form-label" for="form3Example4cdg">Gender :</label>&nbsp;&nbsp;
                    <div class="form-check form-check-inline mb-0 me-4">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="femaleGender"
                            value="option1" />
                        <label class="form-check-label" for="femaleGender">Female</label>
                    </div>

                    <div class="form-check form-check-inline mb-0 me-4">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="maleGender"
                            value="option2" />
                        <label class="form-check-label" for="maleGender">Male</label>
                    </div>

                    <div class="form-check form-check-inline mb-0">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="otherGender"
                            value="option3" />
                        <label class="form-check-label" for="otherGender">Other</label>
                    </div>
                </div>
                <div class="input_field mb-2"><label for="date"
                                class="form-label">Stream</label>
                    <select>
                        <option>Select-</option>
                        <option>IT</option>
                        <option>ComputerScience</option>
                        <option>Other</option>
                    </select>
                    <div class="select_arrow"></div>
                </div>
                <!-- <div class="mb-2 dropdown">
                    <label for="confirm_password" class="form-label">Stream</label>
                    <input type="select" class="form-control input-height" id="stream">
                </div> -->
                <div class="mb-2">
                    <label for="confirm_password" class="form-label">Address</label>
                    <textarea type="text" class="form-control" id="address"></textarea>
                </div>
                <div class="mb-2">
                    <label for="myfile">Select a Profile Picture : </label>
                    <input type="file" id="myfile" name="myfile">
                </div>
                <div class="mb-2 form-check">
                    <input type="checkbox" class="form-check-input" id="terms">
                    <label class="form-check-label" for="terms">I agree terms and conditions.</label>
                </div>
                <div class="text-center mt-3">
                    <button type="submit" class="btn btn-primary btn-rounded w-75">Register Now</button>
                </div>
                <!-- <div class="mb-3 text-center text-secondary mt-3">
                    or register using
                </div>
                <div class="d-flex justify-content-around mb-3">
                    <button type="submit" class="btn btn-danger btn-rounded"><i
                            class="bi bi-google"></i>&nbsp;&nbsp;Google</button>
                    <button type="submit" class="btn btn-rounded" style="color: white; background-color: #1877F2 "><i
                            class="bi bi-facebook"></i>&nbsp;&nbsp;Facebook</button>
                    <button type="submit" class="btn btn-rounded" style="color: white; background-color: #0A66C2"><i
                            class="bi bi-linkedin"></i>&nbsp;&nbsp;Linkedin</button>
                </div> -->
            </form>
        </div>
    </section>
</body>