<!-- Start User Header Area -->
<div class="navbar-area">
    <div class="klob-nav">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/{{clogo}}" alt="logo">
                    <img src="assets/img/{{clogo}}" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link" routerLink="/" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home </a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/about-us" routerLinkActive="active" class="nav-link">About Us</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/services" routerLinkActive="active" class="nav-link">Services</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/courses" routerLinkActive="active" class="nav-link">Courses</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/blog" routerLinkActive="active" class="nav-link">Blog</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contact</a>
                        </li>
                    </ul>
                    <div class="others-options">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link" routerLink="/signin" class="login-btn"><i
                                        class="flaticon-user">
                                    </i> Logout</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End User Header Area -->
