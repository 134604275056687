<!-- Start Banner Area -->
<div class="banner-section">
    <div class="container">
        <div class="row m-0 align-items-center">
            <div class="col-lg-5 col-md-12 p-0">
                <div class="hero-content">
                    <h1 style="font-family: Raleway, sans-serif;">Training Orientation Session By <span style="color: #44ce6f;">Nagendra Kumar</span></h1>
                    <p>The Full Stack Java Developer Program is an intense bootcamp offering a complete suite of
                        software development skills.</p>
                    <a routerLink="/" class="btn btn-primary">Ask More Get More!</a>

                </div>
            </div>
            <div class="col-lg-7 col-md-12 p-0">
                <div class="hero-image">
                    <div class="main-image">
                        <img src="assets/img/banner-image/banner1.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/banner-image/banner2.png" class="wow animate__animated animate__fadeInLeft"
                            data-wow-delay="1.5s" alt="image" style="height:120px;">
                        <img src="assets/img/banner-image/banner3.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="2s" alt="image" style="height:100px;">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/banner-image/main-pic.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/banner-image/circle1.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image">
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner Area -->

<!-- Start Partner Area -->
<div class="partner-style-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner1.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner2.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner3.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner4.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner5.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner6.png" alt="image"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="margin-top:70px ;">
    <div class="container">
        <div>
            <div>
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;"><b>
                        <img src="https://findicons.com/files/icons/1788/large_icons_social/256/social_network.png" style="height:90px;">
                            
                        Interview Questions</b></h2>
                    <div class="bar"></div>
                    <p style="font-size:22px;color:black">
                       Here we will help consultant to be ready for the market. We will do practice with most frequently asked interview questions and coding execercise so that at least consultant can answers at least initial level of questions.
                    </p>
                  

                    <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is on and using on?</span>
                        <br/> 
                        <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                     </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    


                             <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">How to access external API inside AWS Lambda?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div>
                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">how we can inject external file from  docker container?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>

                             <div style="height:30px;background-color:#fffff4 ">
                            </div>

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is config map and secrets?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">How to manage token in aws platform?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">explain process template in aws?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;"> What is Microservices monitoring tools?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;"> What is AWS Lamda limitation?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;"> What is SQS?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What are the different type of SQS ?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 



                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">WHat is Amazon Elastic Container Service (ECS) ?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 


                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">Difference between mock and spy?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 
                             
                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">How to injection environment in docker file?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">Oauth is used for Authorization or Authentication?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is trust store?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">How to implement SSL in spring boot project?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 
                               
                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is different between Apache Kafka vs JMS?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">Mongodb vs mysql?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What  is docker and why?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 

                            <p style="font-size:22px;color:black">
                                <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                                 <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What  is docker and why?</span>
                                <br/> 
                                <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                             </p>
                             <div style="height:30px;background-color:#fffff4 ">
                            </div> 
                             
                            
                            
                            

                       <br/><br/><br/>          
         
        </div>
    </div>
</div>

