import { WebsiteService } from "./../../shared/websiteservice.service";
import { Component, Inject, OnInit } from "@angular/core";
import { SharedData } from "src/app/shared/shareddata";
import { DOCUMENT } from "@angular/common";

@Component({
    selector: "app-privacypolicy",
    templateUrl: "./privacypolicy.component.html",
    styleUrls: ["./privacypolicy.component.scss"],
})
export class PrivacypolicyComponent implements OnInit {
    title: string;

    constructor(
        private websiteService: WebsiteService,
        @Inject(DOCUMENT) private document: any
    ) {}

    ngOnInit(): void {
        let hostname = this.document.location.hostname;
        console.warn(hostname)
        this.websiteService
            .getShareData()
            .subscribe((sharedData: SharedData) => {
                this.title = sharedData.title;
            });
    }
}
