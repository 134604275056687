import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dataengineer',
  templateUrl: './dataengineer.component.html',
  styleUrls: ['./dataengineer.component.scss']
})
export class DataengineerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
