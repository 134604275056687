<!-- Start Feedback Area -->
<div class="feedback-area ptb-70 bg-f7fafd">
    <div class="container">
        <div class="section-title">

            <h2>What Our Clients Say about Us</h2>
            <div class="bar"></div>
            <p>Uniquely promote adaptive quality vectors rather than stand-alone e-markets. pontificate alternative
                architectures whereas iterate.</p>
        </div>
        <div class="feedback-slides">
            <div class="client-feedback">
                <div>
                    <ngx-slick-carousel class="carousel feedback" #slickModal="slick-carousel" [config]="imagesSlider">
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client1.jpg" alt="image">
                                </div>
                                <h3>Joe Richard</h3>
                                <span>Visual Designer</span>
                                <p>“Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce
                                    cross-media infrastructures rather than ethical sticky alignments rather than
                                    global. Plagiarize technically sound total linkage for leveraged value media
                                    web-readiness and premium processes.”</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client2.jpg" alt="image">
                                </div>
                                <h3>Rupan Oberoi</h3>
                                <span>Web Designer</span>
                                <p>“Energistically streamline robust architectures whereas distributed mindshare.
                                    Intrinsicly leverage other's backend growth strategies through 24/365 products.
                                    Conveniently pursue revolutionary communities for compelling process improvements.”
                                </p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client3.jpg" alt="image">
                                </div>
                                <h3>Jon Doe</h3>
                                <span>Software Engineer</span>
                                <p>“Appropriately negotiate interactive niches rather orchestrate scalable benefits
                                    whereas flexible systems. Objectively grow quality outsourcing without vertical
                                    methods of empowerment. Assertively negotiate just in time innovation after
                                    client-centered.”</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client4.jpg" alt="image">
                                </div>
                                <h3>Hanry Luice</h3>
                                <span>App Developer</span>
                                <p>“Competently repurpose cost effective strategic theme areas and customer directed
                                    meta-services. Objectively orchestrate orthogonal initiatives after enterprise-wide
                                    bandwidth. Dynamically generate extensive through cooperative channels time
                                    partnerships.”</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client5.jpg" alt="image">
                                </div>
                                <h3>Ami Nijai</h3>
                                <span>Customer Support</span>
                                <p>“Appropriately disintermediate one-to-one vortals through cross functional
                                    infomediaries. Collaboratively pursue multidisciplinary systems through stand-alone
                                    architectures. Progressively transition covalent architectures whereas vertical
                                    applications procrastinate professional.”</p>
                            </div>
                        </div>
                        <!-- <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client1.jpg" alt="image">
                                </div>
                                <h3>Steven Smith</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
                                    lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client2.jpg" alt="image">
                                </div>
                                <h3>Steven Lucy</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
                                    lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div>
                        <div ngxSlickItem class="item slide">
                            <div class="single-feedback">
                                <div class="client-img">
                                    <img src="assets/img/client-image/client3.jpg" alt="image">
                                </div>
                                <h3>John Terry</h3>
                                <span>Web Developer</span>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
                                    lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                    eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                        </div> -->
                    </ngx-slick-carousel>
                </div>
            </div>
            <div class="client-thumbnails">
                <div>
                    <ngx-slick-carousel class="carousel thumbs" #slickModal="slick-carousel"
                        [config]="thumbnailsSlider">
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client1.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client2.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client3.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client4.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client5.jpg" alt="client"></div>
                        </div>
                         <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client1.jpg" alt="client"></div>
                        </div>
                       <!-- <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client2.jpg" alt="client"></div>
                        </div>
                        <div ngxSlickItem class="item">
                            <div class="img-fill"><img src="assets/img/client-image/client3.jpg" alt="client"></div>
                        </div> -->
                    </ngx-slick-carousel>
                </div>
                <button class="prev-arrow slick-arrow">
                    <i class="fas fa-arrow-left"></i>
                </button>
                <button class="next-arrow slick-arrow">
                    <i class="fas fa-arrow-right"></i>
                </button>
            </div>
        </div>
    </div>
</div>
<!-- End Feedback Area -->