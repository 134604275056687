<!-- Start Footer Area -->
<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/{{clogo}}" alt="logo"></a>
                        <p>{{title}} laid its roots from basics of employee management, thereby bringing a unique
                            approach
                            to cater the requirements of our Clients.</p>
                    </div>
                    <ul class="social-links">
                        <!-- <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li> -->
                        <!-- <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li> -->
                        <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                        <!-- <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li> -->
                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget pr-5">
                    <h3>Company</h3>

                    <ul class="list">
                        <li><a routerLink="/about-us">About Us</a></li>
                        <li><a routerLink="/courses">Our Courses</a></li>
                        <li><a routerLink="/blog">Blog</a></li>
                        <!-- <li><a routerLink="/">Our Pricing</a></li> -->

                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>

                    <ul class="list">
                        <!-- <li><a routerLink="/faq">FAQ's</a></li> -->
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-and-conditions">Terms & Condition</a></li>
                        <!-- <li><a routerLink="/">Community</a></li> -->
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>

                    <ul class="footer-contact-info">
                        <li><span>Location:</span>{{address}}</li>
                        <li><span>Email:</span> <a href="{{email}}">{{email}}</a></li>
                        <li><span>Phone:</span> <a href="{{phone}}">{{phone}}</a></li>
                        <!-- <li><span>Fax:</span> <a href="tel:+12129876543">+1-212-9876543</a></li> -->
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <p>{{title}} © {{year}} All rights reserved. Terms of use and Privacy Policy<a
                    href="https://technohunk.live/" target="_blank"></a></p>
        </div>
    </div>

    <div class="map-image"><img src="assets/img/map.png" alt="map"></div>
</footer>
<!-- End Footer Area -->

<div class="go-top"><i class="fas fa-arrow-up"></i></div>
