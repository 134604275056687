import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aws-interview-qustions',
  templateUrl: './aws-interview-qustions.component.html',
  styleUrls: ['./aws-interview-qustions.component.scss']
})
export class AwsInterviewQustionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
