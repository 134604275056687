<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <p>Our Latest News</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<div class="blog-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-Details1">
                            <img src="assets/img/blog-image/young-attractive-smiling-student.webp" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 15, 2020
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3 style="margin-bottom: 10px;"><a routerLink="/blog-Details1">How To Get Into Tech</a></h3>
                        <!-- <span>by <a routerLink="/">admin</a></span> -->
                        <p>Tech is a great career choice if you want stability, good pay, and immense growth potential
                            in uncertain times. The industry is a fast-paced, ever-evolving field and you don’t need a
                            degree or years of experience to break.....</p>
                        <a routerLink="/blog-Details1" class="read-more-btn">Read More <i
                                class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-Details2">
                            <img src="assets/img/blog-image/young-student-woman.webp" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 17, 2020
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3 style="margin-bottom: 10px;"><a routerLink="/blog-Details2">9 of the Best UI Design
                                Portfolios</a></h3>
                        <!-- <span>by <a routerLink="/">admin</a></span> -->
                        <p>In the eyes of future employers and clients, a UI designer is only as good as their
                            portfolio. But what does an impressive UI design portfolio actually look like—and how can
                            you take your portfolio to the next level?</p>
                        <a routerLink="/blog-Details2" class="read-more-btn">Read More <i
                                class="fas fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-Details3">
                            <img src="assets/img/blog-image/programmer-coding-software.webp" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> NOVEMBER 28, 2022
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3 style="margin-bottom: 10px;"><a routerLink="/blog-Details3">10 Most Popular Programming
                                Lang</a></h3>
                        <!-- <span>by <a routerLink="/">admin</a></span> -->
                        <p> A quick Google search has led you to this article, and you can now join in the enlightening
                            debate and tell them interesting facts about “PHP”, all the while ignoring that urgent text
                            message from your boss asking....</p>
                        <a routerLink="/blog-Details3" class="read-more-btn">Read More <i
                                class="fas fa-arrow-right"></i></a>
                        <!-- <a href="#course">Course</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-Details4">
                            <img src="assets/img/blog-image/handsome-young-man.webp" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> DECEMBER 7, 2022
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3 style="margin-bottom: 10px;"><a routerLink="/blog-Details4">A Guide to Entry-Level Tech Jobs
                            </a></h3>
                        <!-- <span>by <a routerLink="/">admin</a></span> -->
                        <p> For many career changers, the fear of starting from scratch in a junior position is high on
                            the list of concerns. It doesn’t help that the term “entry-level” is often associated with
                            other, more negative terms like...</p>
                        <a routerLink="/blog-Details4" class="read-more-btn">Read More <i
                                class="fas fa-arrow-right"></i></a>
                        <!-- <a href="#course">Course</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-Details5">
                            <img src="assets/img/blog-image/business-executives.jpg" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> NOVEMBER 29, 2022
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3 style="margin-bottom: 10px;"><a routerLink="/blog-Details5">Which Tech Career Path Is
                                Right</a></h3>
                        <!-- <span>by <a routerLink="/">admin</a></span> -->
                        <p> Should you become a web developer or a data analyst? Perhaps UI or UX design would be a
                            better fit? How about the ever-growing fields of digital marketing and product
                            management?....</p>
                        <a routerLink="/blog-Details5" class="read-more-btn">Read More <i
                                class="fas fa-arrow-right"></i></a>
                        <!-- <a href="#course">Course</a> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-Details6">
                            <img src="assets/img/blog-image/business-woman-leader.jpg" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> NOVEMBER 28, 2022
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3 style="margin-bottom: 10px;"><a routerLink="/blog-Details6">11 Top Coding Jobs in 2023 </a>
                        </h3>
                        <!-- <span>by <a routerLink="/">admin</a></span> -->
                        <p>If you’re considering a transition into the field, you might be wondering what kind of jobs
                            are out there. For example, did you know coding is used in other jobs besides programming
                            websites and mobile apps....</p>
                        <a routerLink="/blog-Details6" class="read-more-btn">Read More <i
                                class="fas fa-arrow-right"></i></a>
                        <!-- <a href="#course">Course</a> -->
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/" class="prev page-numbers"><i class="fas fa-angle-double-left"></i></a>
                    <a routerLink="/" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/" class="page-numbers">3</a>
                    <a routerLink="/" class="page-numbers">4</a>
                    <a routerLink="/" class="next page-numbers"><i class="fas fa-angle-double-right"></i></a>
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- End Blog Area -->

<!-- <app-account-button></app-account-button> -->
