import { WebsiteService } from "src/app/shared/websiteservice.service";
import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { SharedData } from "src/app/shared/shareddata";

@Component({
    selector: "app-termsandconditions",
    templateUrl: "./termsandconditions.component.html",
    styleUrls: ["./termsandconditions.component.scss"],
})
export class TermsandconditionsComponent implements OnInit {
    title: string;
    address: string;
    constructor(
        @Inject(DOCUMENT) private document: any,
        private websiteService: WebsiteService
    ) {}

    ngOnInit(): void {
        let hostname = this.document.location.hostname;
        console.warn(hostname);
        this.websiteService
            .getShareData()
            .subscribe((sharedData: SharedData) => {
                this.title = sharedData.title;
                this.address = sharedData.address;
            });
    }
}
