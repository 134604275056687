<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Terms and Conditions</h2>
            <p>Simplifying Agreements and Contracts</p>
        </div>
    </div>
</div>

<div class="about-area ptb-50">
    <div class="container">
        <div class="row align-items-center">
            <div class="about-content">
                <h3 style="text-align: center;">WEBSITE TERMS AND CONDITIONS</h3>
            </div>
            <div class="container" style="margin-block: 25px;width: 70%;text-align: justify;">
                <p>
                    This document is an electronic record in terms of Information Technology Act, and rules there
                    under as applicable and the amended provisions pertaining to electronic records in various Statutes
                    as amended by the Information Technology Act, envis electronic record is generated by a
                    computer system and does not require any physical or digital signatures.</p>
                <p>
                    These terms and conditions ("Terms") govern the use of {{title}} IT consultancy
                    services ("Services"). By accessing or using our Services, you agree to be bound by these
                    terms. If you do not agree to these Terms, you are not authorized to use our Services.
                </p>
                <span>Definitions:</span>
                <ul>
                    <li>
                        "Client": refers to the person or entity that engages {{title}} to provide the
                        Services.</li>
                    <li>
                        "Consultant": refers to the person or entity providing the Services on behalf of {{title}}.
                    </li>
                    <li>
                        "Services": refers to the IT consultancy services provided by {{title}}, including
                        but not limited to consulting, development, support, and maintenance.
                    </li>

                </ul>
                <span>Scope of Services:</span>
                <p>
                    The scope of the Services will be agreed upon in writing by the Client and {{title}}
                    prior to the commencement of the Services. The Services will be provided in accordance
                    with this agreed-upon scope.
                </p>
                <span>Fees and Payment:</span>
                <p>
                    The fees for the Services will be agreed upon in writing by the Client and {{title}}
                    prior to the commencement of the Services. The Client will pay the fees in accordance with
                    the payment terms agreed upon.
                </p>
                <span>Confidentiality:</span>
                <p>
                    {{title}} and the Consultant will maintain the confidentiality of the Clients
                    information and will not disclose any confidential information to third parties without the
                    prior written consent of the Client.
                </p>
                <span>Intellectual Property:</span>
                <p>
                    All intellectual property rights in the Services and any deliverables produced by {{title}} or the
                    Consultant in the course of providing the Services will be owned by the Client.
                </p>
                <span>Warranty and liability.</span>
                <p>
                    {{title}} and the Consultant warrant that the Services will be provided with
                    reasonable care and skill. {{title}} and the Consultant will not be liable for any
                    indirect or consequential damages arising from the provision of the Services.
                </p>
                <span>Termination:</span>
                <p>
                    Either party may terminate the agreement for the provision of the Services by giving written
                    notice to the other party. Upon termination, the Client will pay for any Services provided up
                    to the date of termination.
                </p>
                <span>Governing law:</span>
                <p>
                    These Terms will be governed by and construed in accordance with the laws of jurisdiction.
                </p>
                <span>Severability:</span>
                <p>
                    If any provision of these Terms is found to be invalid or unenforceable, that provision will be
                    enforced to the maximum extent possible, and the remaining provisions will remain in full
                    force and effect.
                </p>
                <span>Entire agreement:</span>
                <p>
                    These Terms constitute the entire agreement between the Client and {{title}} and
                    supersede all prior agreements or understandings, whether written or oral.
                </p>
                <span>Contact us:</span>
                <p>
                    If you have any questions or concerns about these Terms, please contact us at {{address}}
                </p>
                <!-- These Terms were last updated on 22/12/2022. -->
            </div>
        </div>
    </div>
</div>
