<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Services</h2>
            <p>{{title}} makes complex business challenges easy and simple with straightforward solutions.</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Step 1: Submit Application</h2>
                    <div class="bar"></div>
                    <p>Start by filling out your application, which takes around 10-20 minutes to complete. You’ll be
                        asked to share a bit about your background, coding experience, and professional goals.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Free plan available</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Full data privacy compliance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> 100% transparent costs</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Commitment-free</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Real-time spending overview</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Debit Mastercard included</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2>Step 2: Non-Technical Skills Assessments</h2>
                    <div class="bar"></div>
                    <p>After you submit your application, you’ll be required to complete the following non-technical
                        assessments:</p>
                    <ul id="list">
                        <li>The Criteria Cognitive Aptitude Test (CCAT) (15 minutes)</li>
                        <li>A quick typing test (1 minute)</li>
                    </ul>
                    <p>The CCAT is a proven, scientifically validated aptitude test that measures an individual’s
                        ability to solve problems, digest and apply information, learn new skills, and think critically.
                    </p>

                    <p>We chose the CCAT because it’s created by an unbiased third party and helps us learn about you in
                        a unique way that’s not dependent on your previous jobs, careers, or level of education.
                        Research shows that cognitive aptitude is one of the most accurate predictors of job success: <i
                            style="font-weight:600;">twice as predictive as job interviews, three times as predictive as
                            experience, and four times as predictive as education level.</i></p>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Easy transfers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Deposit checks instantly</span></li>
                        <li><span><i class="flaticon-check-mark"></i> A powerful open API</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Coverage around the world</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Business without borders</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Affiliates and partnerships</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- Start Services Area -->
<!-- <div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>Step 3: Complete Basic Prep (Recommended)</h2>
                    <div class="bar"></div>
                    <p>To get into our 12- and 36-week programs, all applicants are required to pass our Technical
                        Admissions Assessment (TAA), which tests for intermediate coding competency.</p>
                    <p>To help you prepare, we offer a free, self-paced Basic Prep course with a curriculum that builds
                        from software engineering basics to more complex problems.</p>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Corporate Cards</span></li>
                        <li><span><i class="flaticon-check-mark"></i> International Payments</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Automated accounting</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Request Features</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Premium Support</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Direct Debit</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Services Area -->

<!-- step-4 area start -->

<!-- <div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2>Step 4: Complete Two Technical Admissions Assessments</h2>
                    <div class="bar"></div>
                    <p>First, the coding challenge: After passing both non-technical assessments, you’ll move on to our
                        coding challenge, which covers many of the concepts and skills required of our bootcamp
                        candidates. This challenge will give you a good idea of your preparedness for the TAA and the
                        bootcamp. Struggling for a long duration on the challenge can be a sign that more study is
                        necessary.</p>
                    <p>Then, the Technical Admissions Assessment (TAA): After you successfully complete the coding
                        challenge, you’ll be asked to book your TAA. During this assessment, we’ll present you with a
                        coding problem that evaluates your fluency in JavaScript fundamentals and your ability to tackle
                        problems in a collaborative setting. You may take the assessment up to three times, with at
                        least two weeks between attempts. After each attempt, you’ll be contacted by an Enrollment
                        Advisor within two business days to discuss results and next steps.</p>
                    <p>By the time you take the TAA, you should be comfortable with callbacks in JavaScript, passing
                        functions as arguments to other functions, and using both anonymous functions and functions
                        stored in variables as callback arguments. You’ll need a strong foundation in composing and
                        using objects, arrays, and functions.</p>

                    <p>Each proctored TAA session is approximately 75 minutes long. Although up to 20 candidates may be
                        participating, you’ll be working on the assessment problems by yourself. A session consists of:
                    </p>
                    <p>A group introduction:<br>– in a group Zoom room <br>– 5 to 10 minutes </p>
                    <p>Individual work on the assessment problems:<br>– in your own recorded Zoom room <br>– help from
                        proctors is available
                        <br> – 55 minutes (timed)
                    </p>
                    <p>A group wrap up:<br>– in a group Zoom room<br>– includes a question and answer period
                        <br> – 5 to 10 minutes
                    </p>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Easy transfers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Deposit checks instantly</span></li>
                        <li><span><i class="flaticon-check-mark"></i> A powerful open API</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Coverage around the world</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Business without borders</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Affiliates and partnerships</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- step-4 area end -->

<!-- <div class="features-area ptb-70 bg-f6f4f8">
    <div class="container">
        <div class="section-title">
            <h2>Best Services for Your Business</h2>
            <div class="bar"></div>
            <p>We handle the sourcing, screening, paperwork, initial interviews and background screening services and
                present you with only well qualified candidates.</p>
            <p>Our developers are well-trained and skilled to fulfil the promises made to you and dedicated to building
                feature rich-applications with the latest technologies.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-settings">
                            <img src="assets/img/java.png" alt="">

                        </i>
                    </div>
                    <h3>Register</h3>
                    <p>Get Registered for free demo course or online classes</p>

                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-f78acb">
                        <i class="flaticon-envelope-of-white-paper">
                            <img src="assets/img/dot_net.png" alt="">
                        </i>
                    </div>
                    <h3>Interview Preparation for Job</h3>
                    <p>How to prepare for a Job interview so you'll convince them you're the right hire</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-cdf1d8">
                        <i class="flaticon-menu">
                            <img src="assets/img/python.png" alt="">
                        </i>
                    </div>
                    <h3>Sale and Marketing</h3>
                    <p>Sales and marketing are two business functions within an organization-they both impact lead
                        generation and revenue</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-c679e3">
                        <i class="flaticon-info">
                            <img src="assets/img/devops.png" alt="">
                        </i>
                    </div>
                    <h3>Assistance for getting job</h3>
                    <p>100% job assistance assessing the various job options open to you get professional Personalized
                        job Guidance</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon bg-eb6b3d">
                        <i class="flaticon-cursor">
                            <img src="assets/img/qa-logo.png" alt="">
                        </i>
                    </div>
                    <h3>Software Integration</h3>
                    <p> The future is here with TechnoHunk software solutions that consist of productive and engaging
                        solutions for hybrid and improved service. We provide you with data integration, reliable API,
                        and microservices to improve your softwar </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-alarm">
                        </i>
                    </div>
                    <h3>Amicable After Service</h3>
                    <p>We value your time and money. Therefore, we provide round the clock service not only in the
                        on-going process but even after sales. Get expertise with effective and efficient management. We
                        give personal unbiased attention to all our clients.</p>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- Start Blog Wrap Area -->
<div class="blog-wrap-area pt-100 pb-75">
    <div class="container">
        <div class="section-title">
            <h2>Best Services for Your Business</h2>
            <div class="bar"></div>
            <p>We handle the sourcing, screening, paperwork, initial interviews and background screening services and
                present you with only well qualified candidates.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/interview-preparation" class="d-block">
                            <img src="assets/img/services/job-interview.webp" alt="image">
                        </a>
                        <!-- <span class="date">October 30, 2022</span> -->
                    </div>
                    <div class="content">
                        <h3><a routerLink="/interview-preparation">Interview Preparation for job</a></h3>
                        <p>How to Prepare for a Job Interview So You’ll Convince Them You’re the Right Hire. Here
                            you can work on in your practice interviews.</p>
                        <a routerLink="/interview-preparation" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/sale-marketing" class="d-block">
                            <img src="assets/img/services/sales-markrting.jpg" alt="image">
                        </a>
                        <!-- <span class="date">October 30, 2022</span> -->
                    </div>
                    <div class="content">
                        <h3><a routerLink="/sale-marketing">Sales and Marketing </a></h3>
                        <p>Sales and marketing are two business functions within an organization -- they both impact
                            lead generation and revenue. </p>
                        <a routerLink="/sale-marketing" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/job-assistance" class="d-block">
                            <img src="assets/img/services/corporate-business.webp" alt="image">
                        </a>
                        <!-- <span class="date">October 30, 2022</span> -->
                    </div>
                    <div class="content">
                        <h3><a routerLink="/job-assistance">Assistance for getting job</a></h3>
                        <p>100% Job Assistance. Assessing the Various Job Options Open to You. Get Professional,
                            Personalized Job Guidance.</p>
                        <a routerLink="/job-assistance" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6" *ngIf="technoSection">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/software-developer" class="d-block">
                            <img src="assets/img/services/engineer-programming.jpeg" alt="image">
                        </a>
                        <!-- <span class="date">October 30, 2022</span> -->
                    </div>
                    <div class="content">
                        <h3><a routerLink="/software-developer"> Software Development</a></h3>
                        <p>We offer custom software development services specifically designed to meet business
                            goals at
                            reduced costs.</p>
                        <a routerLink="/software-developer" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6" *ngIf="technoSection">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/ui-ux-design" class="d-block">
                            <img src="assets/img/services/ui-ux-background.jpeg" alt="image">
                        </a>
                        <!-- <span class="date">October 30, 2022</span> -->
                    </div>
                    <div class="content">
                        <h3><a routerLink="/ui-ux-design">UX/UI Design & Ideation</a></h3>
                        <p>Engross more customers by leveraging a well-defined design thinking approach and UI/UX
                            strategies helpful...</p>
                        <a routerLink="/ui-ux-design" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6" *ngIf="technoSection">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/web-development" class="d-block">
                            <img src="assets/img/services/programmers.webp" alt="image">
                        </a>
                        <!-- <span class="date">October 30, 2022</span> -->
                    </div>
                    <div class="content">
                        <h3><a routerLink="/web-development">Web Development</a></h3>
                        <p>We offer a variety of web-based software development services from creating mobile web
                            development solutions....</p>
                        <a routerLink="/web-development" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Wrap Area -->

<!-- <app-talk-button></app-talk-button>

<app-partner></app-partner>

<app-download></app-download>

<app-account-button></app-account-button> -->
