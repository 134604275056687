import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DefaultComponent } from "./demos/default/default.component";
import { DemoTwoComponent } from "./demos/demo-two/demo-two.component";
import { DemoThreeComponent } from "./demos/demo-three/demo-three.component";
import { AboutComponent } from "./pages/about/about.component";
import { FeaturesComponent } from "./pages/features/features.component";
import { TeamComponent } from "./pages/team/team.component";
import { PricingComponent } from "./pages/pricing/pricing.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { BlogDetailsComponent } from "./pages/blog-details/blog-details.component";
import { SignupComponent } from "./pages/signup/signup.component";
import { SigninComponent } from "./pages/signin/signin.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { DemoFourComponent } from "./demos/demo-four/demo-four.component";
import { DemoFiveComponent } from "./demos/demo-five/demo-five.component";
import { DemoSixComponent } from "./demos/demo-six/demo-six.component";
import { BlogRightSidebarComponent } from "./pages/blog-right-sidebar/blog-right-sidebar.component";
import { JavaComponent } from "./courses/java/java.component";
import { DatascienceComponent } from "./courses/datascience/datascience.component";
import { DotnetComponent } from "./courses/dotnet/dotnet.component";
import { AdmissionProcessComponent } from "./pages/admission-process/admission-process.component";
import { JavaSyllabusDetailsComponent } from "./courses/java-syllabus-details/java-syllabus-details.component";
import { StudentSignupComponent } from "./pages/student-signup/student-signup.component";
import { SignupMassageComponent } from "./pages/signup-massage/signup-massage.component";
import { SalesMarketingComponent } from "./pages/howPykubeWorks/sales-marketing/sales-marketing.component";
import { AssistanceJobComponent } from "./pages/howPykubeWorks/assistance-job/assistance-job.component";
import { InterviewPreComponent } from "./pages/howPykubeWorks/interview-pre/interview-pre.component";
import { GetCallComponent } from "./pages/howPykubeWorks/get-call/get-call.component";
import { PaymentComponent } from "./pages/howPykubeWorks/payment/payment.component";
import { BlogDetails1Component } from "./pages/blogs/blog-details1/blog-details1.component";
import { BlogDetails2Component } from "./pages/blogs/blog-details2/blog-details2.component";
import { BlogDetails3Component } from "./pages/blogs/blog-details3/blog-details3.component";
import { TermsandconditionsComponent } from "./common/termsandconditions/termsandconditions.component";
import { PrivacypolicyComponent } from "./common/privacypolicy/privacypolicy.component";
import { DataengineerComponent } from "./courses/dataengineer/dataengineer.component";
import { DataanalystComponent } from "./courses/dataanalyst/dataanalyst.component";
import { BlogDetails4Component } from "./pages/blogs/blog-details4/blog-details4.component";
import { BlogDetails5Component } from "./pages/blogs/blog-details5/blog-details5.component";
import { BlogDetails6Component } from "./pages/blogs/blog-details6/blog-details6.component";
import { PythonComponent } from "./courses/python/python.component";
import { QaengineerComponent } from "./courses/qaengineer/qaengineer.component";
import { CoursesComponent } from "./courses/courses.component";
import { CustomSoftDevComponent } from "./pages/howPykubeWorks/custom-soft-dev/custom-soft-dev.component";
import { WebDevelopmentComponent } from "./pages/howPykubeWorks/web-development/web-development.component";
import { UIUXDesignComponent } from "./pages/howPykubeWorks/ui-ux-design/ui-ux-design.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { AngularComponent } from "./courses/angular/angular.component";
import { JavaAdvanceQuestionComponent } from "./courses/java-advance-question/java-advance-question.component";
import { JavaInterviewQuizComponent } from "./courses/java-interview-quiz/java-interview-quiz.component";
import { JavaMiscAdvanceQuestionComponent } from "./courses/Interview-miscellanies-Questions/Interview-miscellanies-Questions.component";
import { AwsInterviewQustionsComponent } from "./courses/aws-interview-qustions/aws-interview-qustions.component";
import { JavaScriptInterviewQuestionsComponent } from "./courses/java-script-interview-questions/java-script-interview-questions.component";
import { AngularInterviewQuestionsComponent } from "./courses/angular-interview-questions/angular-interview-questions.component";
// import { PaymentComponent } from './pages/payment/payment.component';

const routes: Routes = [
    { path: "", component: DefaultComponent },
    { path: "demo-2", component: DemoTwoComponent },
    { path: "demo-3", component: DemoThreeComponent },
    { path: "demo-4", component: DemoFourComponent },
    { path: "demo-5", component: DemoFiveComponent },
    { path: "demo-6", component: DemoSixComponent },
    { path: "about-us", component: AboutComponent },
    { path: "services", component: FeaturesComponent },
    { path: "team", component: TeamComponent },
    // { path: "bootcamp", component: PricingComponent },
    { path: "angular-interview-questions", component: AngularInterviewQuestionsComponent},
    { path: "java-script-interview-questions", component: JavaScriptInterviewQuestionsComponent},
    { path: "java-syllabus-details", component: JavaSyllabusDetailsComponent },
    { path: "admission-process", component: AdmissionProcessComponent },
    { path: "faq", component: FaqComponent },
    { path: "blog", component: BlogComponent },
    { path: "blog-right-sidebar", component: BlogRightSidebarComponent },
    { path: "blog-details", component: BlogDetailsComponent },
    { path: "signup", component: SignupComponent },
    { path: "signin", component: SigninComponent },
    { path: "contact", component: ContactComponent },
    { path: "java", component: JavaComponent },
    { path: "python", component: PythonComponent },
    { path: "dotnet", component: DotnetComponent },
    { path: "student-signup", component: StudentSignupComponent },
    { path: "signup-massage", component: SignupMassageComponent },
    { path: "sale-marketing", component: SalesMarketingComponent },
    { path: "job-assistance", component: AssistanceJobComponent },
    { path: "interview-preparation", component: InterviewPreComponent },
    { path: "java-advance-questions", component: JavaAdvanceQuestionComponent },
    { path: "java-interview-quiz", component: JavaInterviewQuizComponent },
    { path: "java-interview-misc", component: JavaMiscAdvanceQuestionComponent},
    { path: "get-call-from-executive", component: GetCallComponent },
    { path: "payment", component: PaymentComponent },
    { path: "blog-Details1", component: BlogDetails1Component },
    { path: "blog-Details2", component: BlogDetails2Component },
    { path: "blog-Details3", component: BlogDetails3Component },
    { path: "blog-Details4", component: BlogDetails4Component },
    { path: "blog-Details5", component: BlogDetails5Component },
    { path: "blog-Details6", component: BlogDetails6Component },
    { path: "terms-and-conditions", component: TermsandconditionsComponent },
    { path: "privacy-policy", component: PrivacypolicyComponent },
    { path: "data-analyst", component: DataanalystComponent },
    { path: "data-engineer", component: DataengineerComponent },
    { path: "data-scientist", component: DatascienceComponent },
    { path: "quality-assurance", component: QaengineerComponent },
    { path: "courses", component: CoursesComponent },
    { path: "software-developer", component: CustomSoftDevComponent },
    { path: "web-development", component: WebDevelopmentComponent },
    { path: "ui-ux-design", component: UIUXDesignComponent },
    { path: "dashboard", component:DashboardComponent },
    { path: "angular", component:AngularComponent },
    { path: "aws-interview-Qustions", component:AwsInterviewQustionsComponent },




    
    // Here add new pages component

    { path: "**", component: NotFoundComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: "legacy", useHash:true }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
