export class SharedData {
    logo: string;
    email: string;
    phone: string;
    title: string;
    address: string;
    year: string;
    faviconLogo: string;
    faviconTitle: string;
}
