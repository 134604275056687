<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Web Development</h2>

        </div>
    </div>
</div>

<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>  Web Development</h2>
                    <div class="bar"></div>

                    <ul class="services-list1">
                        
                        <li><span><i class="flaticon-check-mark"></i> Custom website design and development: This involves creating a unique website from scratch, or customizing an existing website to meet specific business needs or requirements.</span>
                        </li>
                        <li><span><i class="flaticon-check-mark"></i>  E-commerce development: This involves building and maintaining online stores and other e-commerce platforms. This may include the development of shopping carts, payment gateways, and other features necessary for online transactions.</span></li>

                        <li><span><i class="flaticon-check-mark"></i>Content management systems (CMS) development: This involves developing or customizing CMS platforms to allow businesses to easily create and manage the content on their websites.</span></li>

                        <li><span><i class="flaticon-check-mark"></i>Responsive web design: This involves designing websites that are optimized for viewing on a variety of devices, including desktop computers, laptops, tablets, and smartphones.</span></li>

                        <li><span><i class="flaticon-check-mark"></i> Search engine optimization (SEO): This involves optimizing websites to improve their visibility and ranking in search engine results pages.</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Website maintenance and support: This may include ongoing support and maintenance to ensure that the website remains up-to-date and functioning properly, as well as the addition of new features or functionality.</span></li>



                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>