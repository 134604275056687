<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" style="background-image: url(assets/img/course/java_ban.webp);"
    data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>JAVA</h2>
            <p>Java Syllabus</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start FAQ Area -->
<div class="faq-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="faq-content">
                    <h2>Full Stack Java Developer</h2>
                    <div class="bar"></div>
                    <p>Full Stack Java Developer Program is designed to give you an essence of front-end, middleware,
                        and back-end Java web developer technologies.
                        You will learn to build an end-to-end application, test and deploy code, store data using
                        MongoDB, and much more.</p>
                    <div class="faq-image">
                        <img src="assets/img/course/java-fullstack-development.jpeg" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <ul class="accordion">
                        <li class="accordion-item">
                            <a class="accordion-title active" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                The Java Environment:
                            </a>
                            <p class="accordion-content fast">Installing Java, Java Program Development , Java Source
                                File Structure, Compilation, Executions.</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Basic Language Elements:
                            </a>
                            <p class="accordion-content">Lexical Tokens, Identifiers, Keywords, Literals, Comments
                                ,Primitive Datatypes, Operators Assignments.</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Object Oriented Programming:
                            </a>
                            <p class="accordion-content">Class Fundamentals , Object & Object reference , Object Life
                                time & Garbage Collection, Creating and Operating Objects , Constructor &
                                initialization code block, Access Control, Modifiers, methods Nested , Inner Class &
                                Anonymous Classes, Abstract Class & Interfaces Defining
                                Methods, Argument Passing Mechanism , Method Overloading, Recursion, Dealing with Static
                                Members, Finalize() Method, Native Method. Use of
                                “this “ reference, Use of Modifiers with Classes & Methods, Design of Accessors and
                                Mutator Methods Cloning Objects, shallow and deep
                                cloning, Generic Class Types.</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Extending Classes and Inheritance:
                            </a>
                            <p class="accordion-content">Use and Benefits of Inheritance in OOP, Types of Inheritance in
                                Java, Inheriting Data members and Methods , Role of Constructors in
                                inheritance , Overriding Super Class Methods ,Use of “super” , Polymorphism in
                                inheritance ,Type Compatibility and Conversion
                                Implementing interfaces.</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Package:
                            </a>
                            <p class="accordion-content">Organizing Classes and Interfaces in Packages , Package as
                                Access Protection , Defining Package ,CLASSPATH Setting for Packages , Making
                                JAR Files for Library Packages Import and Static Import Naming Convention For Packages
                            </p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Exception Handling:
                            </a>
                            <p class="accordion-content">The Idea behind Exception ,Exceptions & Errors ,Types of
                                Exception ,Control Flow In Exceptions, JVM reaction to Exceptions ,Use of try, catch,
                                finally, throw, throws in Exception Handling ,In-built and User Defined Exceptions,
                                Checked and Un-Checked Exceptions..</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Array & String :
                            </a>
                            <p class="accordion-content">Defining an Array, Initializing & Accessing Array, Multi
                                –Dimensional Array, Operation on String, Mutable & Immutable String, Using Collection
                                Bases Loop for String, Tokenizing a String, Creating Strings using StringBuffer..</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Thread :
                            </a>
                            <p class="accordion-content">Understanding Threads , Needs of Multi-Threaded Programming
                                ,Thread Life-Cycle, Thread Priorities ,Synchronizing Threads, Inter Communication
                                of Threads ,Critical Factor in Thread –DeadLock,</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                A Collection of Useful Classes:
                            </a>
                            <p class="accordion-content">Utility Methods for Arrays ,Observable and Observer Objects ,
                                Date & Times ,Using Scanner Regular Expression, Input/Output Operation in
                                Java(java.io Package),Streams and the new I/O Capabilities, Understanding Streams, The
                                Classes for Input and Output, The Standard
                                Streams, Working with File Object, File I/O Basics, Reading and Writing to Files, Buffer
                                and Buffer Management, Read/Write Operations with File
                                Channel, Serializing Objects .</p>
                        </li>
                        <li class="accordion-item">
                            <a class="accordion-title" href="javascript:void(0)">
                                <i class="fas fa-plus"></i>
                                Database Programming using JDBC:
                            </a>
                            <p class="accordion-content">Introduction to JDBC,JDBC Drivers & Architecture, CURD
                                operation Using JDBC, Connecting to non-conventional Databases.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="faq-area ptb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 col-md-12">
                        <div class="faq-accordion">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Servlet
                                    </a>
                                    <p class="accordion-content">Web Application Basics, Architecture and challenges of
                                        Web Application, Introduction to servlet, Servlet life cycle, Developing and
                                        Deploying Servlets, Exploring Deployment , Descriptor (web.xml), Handling
                                        Request and Response.</p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="fas fa-plus"></i>
                                        Spring:
                                    </a>
                                    <p class="accordion-content">Spring core, Spring JDBC, Spring aop, Spring
                                        Transaction, Spring mvc, Spring Boot, Spring microservices.</p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title">
                                        <i class="fas fa-plus"></i>
                                        Apache
                                    </a>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title">
                                        <i class="fas fa-plus"></i>
                                        Kafka
                                    </a>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title">
                                        <i class="fas fa-plus"></i>
                                        Docker
                                    </a>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title">
                                        <i class="fas fa-plus"></i>
                                        Jankins
                                    </a>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title">
                                        <i class="fas fa-plus"></i>
                                        HTML &
                                        CSS3
                                    </a>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title">
                                        <i class="fas fa-plus"></i>
                                        Bootstrap
                                    </a>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title">
                                        <i class="fas fa-plus"></i>
                                        TypeScript
                                    </a>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title">
                                        <i class="fas fa-plus"></i>
                                        Angular 14
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-12">
                        <div class="faq-content">
                            <!-- <h2>Full Stack Java Developer</h2>
                            <div class="bar"></div> -->
                            <div class="faq-image">
                                <img src="assets/img/course/java-programming.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="faq-contact">
                <div class="section-title">
                    <h2>Do You Have Any Questions</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore
                        et dolore magna aliqua.</p>
                </div>
                <div class="faq-contact-form">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" required
                                        data-error="Please enter your name" placeholder="Name">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" required
                                        data-error="Please enter your email" placeholder="Email">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="phone_number" id="phone_number" required
                                        data-error="Please enter your number" class="form-control" placeholder="Phone">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required
                                        data-error="Please enter your subject" placeholder="Subject">
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="6"
                                        required data-error="Write your message" placeholder="Your Message"></textarea>
                                    <div class="help-block with-errors"></div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Send Message</button>
                                <div id="msgSubmit" class="h3 text-center hidden"></div>
                                <div class="clearfix"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>


    <!-- End FAQ Area -->

    <app-account-button></app-account-button>