<!-- Start Banner Area -->
<div class="banner-section">
    <div class="container">
        <div class="row m-0 align-items-center">
            <div class="col-lg-5 col-md-12 p-0">
                <div class="hero-content">
                    <h1 style="font-family: Raleway, sans-serif;">Training Orientation Session By <span style="color: #44ce6f;">Nagendra Kumar</span></h1>
                    <p>The Full Stack Java Developer Program is an intense bootcamp offering a complete suite of
                        software development skills.</p>
                    <a routerLink="/" class="btn btn-primary">Ask More Get More!</a>

                </div>
            </div>
            <div class="col-lg-7 col-md-12 p-0">
                <div class="hero-image">
                    <div class="main-image">
                        <img src="assets/img/banner-image/banner1.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/banner-image/banner2.png" class="wow animate__animated animate__fadeInLeft"
                            data-wow-delay="1.5s" alt="image" style="height:120px;">
                        <img src="assets/img/banner-image/banner3.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="2s" alt="image" style="height:100px;">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/banner-image/main-pic.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/banner-image/circle1.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image">
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner Area -->

<!-- Start Partner Area -->
<div class="partner-style-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner1.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner2.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner3.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner4.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner5.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner6.png" alt="image"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="margin-top:70px ;">
    <div class="container">
        <div>
            <div>
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;"><b>
                        <img src="https://findicons.com/files/icons/1788/large_icons_social/256/social_network.png" style="height:90px;">
                            
                        Interview Questions</b></h2>
                    <div class="bar"></div>
                    <p style="font-size:22px;color:black">
                       Here we will help consultant to be ready for the market. We will do practice with most frequently asked interview questions and coding execercise so that at least consultant can answers at least initial level of questions.
                    </p>
                  

                    <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is difference between Application Context vs Bean Context ?</span>
                        <br/> 
                        <img src="https://img.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg?w=2000" style="height: 200px;">
                     </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is Servlet life cycle?</span>
                        <br/> 
                        <img src="assets/img/kb/life-cycle-of-servlet.png">
                     </p>
                     
                     <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is JAX-RS Specification?</span>
                        <br/> 
                        <img src="assets/img/kb/what-jax-rs.png">
                     </p>

                      
                     <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is Rest API guidelines?</span>
                        <br/> 
                        <img src="assets/img/kb/rest-api-guidelines-1.png">
                        <br/> 
                        <img src="assets/img/kb/rest-api-guidelines-1.png">
                        <br/> 
                        <img src="assets/img/kb/rest-api-guidelines-2.png">
                        <br/> 
                        <img src="assets/img/kb/rest-api-guidelines-3.png">
                        <br/> 
                        <img src="assets/img/kb/rest-api-guidelines-4.png">
                        <br/> 
                        <img src="assets/img/kb/rest-api-guidelines-5.png">
                        <br/> 
                        <img src="assets/img/kb/rest-api-guidelines-6.png">
                        <br/> 
                        <img src="assets/img/kb/rest-api-guidelines-7.png">
                     </p>
                     
                     <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is web service?</span>
                        <br/> 
                        <img src="assets/img/kb/what-is-webservice.png">
                     </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is first level caching in Hibernate?</span>
                        <br/> 
                        <img src="assets/img/kb/first-level-caching-in-hibernate.png">
                     </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is Isolation level in Hibernate?</span>
                        <br/> 
                        <img src="assets/img/kb/why-isolation.png">
                     </p>
                     
                     
                     <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is diference Between Soap and Rest web services.</span>
                        <br/> 
                        <img src="assets/img/kb/soap-rest-difference.png">
                     </p>


                     <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is WSDL and explain it in brief?</span>
                        <br/> 
                        
                     </p>
                     <p>
                      
                     </p>
                  
                     <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is IOC in spring framework??</span>
                        <br/> 
                       
                        <img src="assets\img\kb\core java\What-is-IOC-in spring-framework.png" >


                     </p>
                     <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is Dependency Injection , please explain in brief ?</span>
                        <br/> 
                       
                     </p>
                     <p class="p-ans">
                        Before understanding what it means in programming, let’s first see what it means in general as it will help us understand the concept better.<br>
                        <br>
                        
                        Dependency or dependent means relying on something for support. Like if I say we are relying too much on mobile phones than it means we are dependent on them.
                     <br>
                     <br>
                     So before getting to dependency injections, first let’s understand what a dependency in programming means.
                     <br>
                     <br>
                     When class A uses some functionality of class B, then its said that class A has a dependency of class B.
                     <br>
                     <br>
                     In Java, before we can use methods of other classes, we first need to create the object of that class (i.e. class A needs to create an instance of class B).
                     <br>
                     <br>
                    <span style="color: #44ce6f;">So, transferring the task of creating the object to someone else and directly using the dependency is called dependency injection.</span>
                    <br>
                    <br>
                    
                    
                   
                    
                   

        
                    

                    <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is advantage of using Hibernate ORM tool??</span>
                        <img src="assets/img/kb/why-orm.png">
                     </p>
                  
                     <div style="height:30px;background-color:#fffff4 ">

                    </div>
                     <p style="font-family: Raleway, sans-serif; ">
                         <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">Why we need service layer??</span>
                       
                         <img src="assets/img/kb/service-doa.png"/>
 
                      </p>
                      <div style="height:30px;background-color:#fffff4 ">

                    </div>
                      <p style="font-family: Raleway, sans-serif; ">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                        <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">Why Spring Security is used in the project??</span>
                        <img src="assets/img/kb/why-spring-security.bmp"/>
                     </p>
                     <div style="height:30px;background-color:#fffff4 ">

                    </div>
                     <p style="font-family: Raleway, sans-serif; ">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                        <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">What is advantage of spring boot 2.x??</span>
                        <img src="assets/img/kb/springboot.bmp"/>
                     </p>
                        <p style="font-family: Raleway, sans-serif; ">
                            <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                            <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">Java Full Stack Course Plan!!!??</span>
                            <img src="assets/img/kb/course-rp.png"/>
                         </p>
                            
                          <div style="height:30px;background-color:#fffff4 ">
                            </div>
                           <p style="font-size:22px;color:black;background-color: ffeafc;">
                            <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                             <span style="font-weight: normal;color:black;font-size:24px;margin-left: 16px;">How to implement Spring Data JPA?</span>
                             <br/>
                             <img src="assets/img/kb/how-to-implement-spring-data-jpa.png"/>
                            </p>  

                            <div style="height:30px;background-color:#fffff4 ">
                            </div>
                           <p style="font-size:22px;color:black;background-color: ffeafc;">
                            <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                             <span style="font-weight: normal;color:black;font-size:24px;margin-left: 16px;">What is proxy object in spring?</span>
                             <br/>
                             <img src="assets/img/kb/proxy-object-in-spring.png"/>
                            </p>  
                            <div style="height:30px;background-color:#fffff4 ">
                            </div>
                           <p style="font-size:22px;color:black;background-color: ffeafc;">
                            <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                             <span style="font-weight: normal;color:black;font-size:24px;margin-left: 16px;">What is microservices?</span>
                             <br/>
                          
                             
                            </p> 

                            <div style="height:30px;background-color:#fffff4 ">
                            </div>
                           <p style="font-size:22px;color:black;background-color: ffeafc;">
                            <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                             <span style="font-weight: normal;color:black;font-size:24px;margin-left: 16px;">What is microservices architecture?</span>
                          
                             <br/>
                             <img src="assets/img/kb/microservices-2.png"/>
                             
                            </p> 
                           
                            <div style="height:30px;background-color:#fffff4 ">
                            </div>
                           <p style="font-size:22px;color:black;background-color: ffeafc;">
                            <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                             <span style="font-weight: normal;color:black;font-size:24px;margin-left: 16px;">What is API gateway?</span>
                             <br/>
                             <img src="assets/img/kb/api-gateway.png"/>
                            </p> 
                           
                            <div style="height:30px;background-color:#fffff4 ">
                            </div>
                           <p style="font-size:22px;color:black;background-color: ffeafc;">
                            <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                             <span style="font-weight: normal;color:black;font-size:24px;margin-left: 16px;">What is Aspect Orientated Programming?</span>
                             <br/>
                             <img src="assets/img/kb/what-is-aop.png"/>
                            </p> 
                            
                           
                            <div style="height:30px;background-color:#fffff4 ">
                            </div>
                           <p style="font-size:22px;color:black;background-color: ffeafc;">
                            <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                             <span style="font-weight: normal;color:black;font-size:24px;margin-left: 16px;">Terminologies used in Aspect Orientated Programming?</span>
                             <br/>
                             <img src="assets/img/kb/terminologies-used-in-aop.png"/>
                            </p> 

                            <div style="height:30px;background-color:#fffff4 ">
                            </div>
                           <p style="font-size:22px;color:black;background-color: ffeafc;">
                            <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                             <span style="font-weight: normal;color:black;font-size:24px;margin-left: 16px;">What is Agile and Scrum software Methodology?</span>
                             <br/>
                             <img src="assets/img/kb/agile-scrum-session1.jpg"/>
                            </p> 

                       


                            <div style="height:30px;background-color:#fffff4 ">
                            </div>
                           <p style="font-size:22px;color:black;background-color: ffeafc;">
                            <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                             <span style="font-weight: normal;color:black;font-size:24px;margin-left: 16px;">Project Architecture for desibank!!?</span>
                             <br/>
                             <img src="assets/img/kb/project_achitecture.png"/>
                            </p> 
                              
                            
                            
                            

                             
                             


                             



                             

                       <br/><br/><br/>          
         
        </div>
    </div>
</div>

<app-feedback></app-feedback>

<app-talk-button></app-talk-button>



<app-download></app-download>