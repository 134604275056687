<!-- Start Banner Area -->
<div class="banner-section">
    <div class="container">
        <div class="row m-0 align-items-center">
            <div class="col-lg-5 col-md-12 p-0">
                <div class="hero-content">
                    <h1 style="font-family: Raleway, sans-serif;">Training Orientation By <span
                            style="color: #44ce6f;">Expert Trainer</span></h1>
                    <p>The Full Stack Java Developer Program is an intense bootcamp offering a complete suite of
                        software development skills.</p>
                    <a routerLink="/" class="btn btn-primary">Ask More Get More!</a>

                </div>
            </div>
            <div class="col-lg-7 col-md-12 p-0">
                <div class="hero-image">
                    <div class="main-image">
                        <img src="assets/img/banner-image/banner1.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/banner-image/banner2.png" class="wow animate__animated animate__fadeInLeft"
                            data-wow-delay="1.5s" alt="image" style="height:120px;">
                        <img src="assets/img/banner-image/banner3.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="2s" alt="image" style="height:100px;">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/banner-image/main-pic.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/banner-image/circle1.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image">
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner Area -->

<!-- Start Partner Area -->
<div class="partner-style-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner1.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner2.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner3.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner4.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner5.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner6.png" alt="image"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">PHASE-1 JAVA TRAINING</h2>
                    <div class="bar"></div>
                    <p style="font-size: 20px;color:black;font-weight: bold;">
                        <img src="./assets/img/course/java.png" alt="image">
                        CORE JAVA 8 TOPICS.
                    </p>
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> OOPS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Constructor</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Static, This Keyword</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Inheritance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Interface</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Abstract Class</span></li>
                        <li><span><i class="flaticon-check-mark"></i> String</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Exception Handling</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Collection Framework</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Generics</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Java8 Features</span></li>
                        <li><span><i class="flaticon-check-mark"></i> JDBC Programming</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Data Structure</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Coding Problems</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h3 style="color:black">
                        <img src="assets/img/banner-image/banner3.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image" style="height:100px;">
                        <span style="font-family: Raleway, sans-serif; font-weight: 400;"> Front End Training </span>
                    </h3>
                    <div class="bar"></div>
                    <!-- <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.</p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> HTML-5</span></li>
                        <li><span><i class="flaticon-check-mark"></i> CSS & SCSS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Bootstrap 5.0</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Java Script</span></li>
                        <li><span><i class="flaticon-check-mark"></i> TypeScript</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Angular12</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">PHASE-2 -> JAVA TRAINING</h2>
                    <div class="bar"></div>
                    <p style="font-size: 20px;color:black;font-weight: bold;">
                        <img src="./assets/img/course/java.png" alt="image">
                        ADVANCE JAVA
                    </p>
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> JSP</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Servlet</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Spring Core</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Spring JDBC</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Spring AOP</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Spring Web</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Spring MVC</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Spring Boot 2.x</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Rest API Design with spring boot </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Http Status Code</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Hibernate ORM</span></li>
                        <li><span><i class="flaticon-check-mark"></i> JPA</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Spring Data JPA</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Global Exception Handling</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Spring Security</span></li>
                        <li><span><i class="flaticon-check-mark"></i> MicroServices</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Eureka Server</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Configuration Server</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->
<!-- Start Services Area -->
<!-- < <div class="services-area ptb-70" >
    <div class=" p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content " >
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">PHASE-3 -> AWS & DevOps TRAINING</h2>
                    <div class="bar"></div>
                    <h3><img src="./assets/img/course/course-content-icon.png"  alt=""  title="Course content"> Course Content </h3>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> MAVEN</span></li>
                        <li><span><i class="flaticon-check-mark"></i> JENKINS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Docker</span></li>
                        <li><span><i class="flaticon-check-mark"></i> EC2 Instance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> AWS Cloud Watch</span></li>
                        <li><span><i class="flaticon-check-mark"></i> AWS Secret Manager </span></li>
                        <li><span><i class="flaticon-check-mark"></i> RDS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> SQS/SNS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> API Gateway </span></li>
                        <li><span><i class="flaticon-check-mark"></i> AWS Lambda</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Auto Scalling</span></li>
                        <li><span><i class="flaticon-check-mark"></i> S3 Bucket</span></li>
                        <li><span><i class="flaticon-check-mark"></i> JUNIT 5.x</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Mockito</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/mocj.jpg" alt="image" height="600px">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  -->


<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">PHASE-3 -> AWS & DevOps TRAINING
                    </h2>
                    <div class="bar"></div>
                    <!-- <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.</p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> MAVEN</span></li>
                        <li><span><i class="flaticon-check-mark"></i> JENKINS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Docker</span></li>
                        <li><span><i class="flaticon-check-mark"></i> EC2 Instance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> AWS Cloud Watch</span></li>
                        <li><span><i class="flaticon-check-mark"></i> AWS Secret Manager </span></li>
                        <li><span><i class="flaticon-check-mark"></i> RDS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> SQS/SNS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> API Gateway </span></li>
                        <li><span><i class="flaticon-check-mark"></i> AWS Lambda</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Auto Scalling</span></li>
                        <li><span><i class="flaticon-check-mark"></i> S3 Bucket</span></li>
                        <li><span><i class="flaticon-check-mark"></i> JUNIT 5.x</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Mockito</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Start Invoicing Area -->
<div class="invoicing-area ptb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Phase-4 Real Hands on with Banking
                        Project</h2>
                    <div class="bar"></div>
                    <p style="font-size:20px;color:black">
                        In this we will learn how to setup an existing banking project from git , client side, server
                        side debugging.
                        We will work on multi module project based on microservices architecture based project. This
                        project will be more and less same as company project.
                        This project will ensure that if you can work here then you can work there as well.
                    </p>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">GITHUB</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">BITBUCKET</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">JIRA</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Confluence</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">intelliJ</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Eclipse</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">MAVEN</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Gradle</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image">
                        <img src="assets/img/Project-Management-Journey.jpg" height="400px"
                            class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/invoicing/invoicing-shape2.png"
                            class="wow animate__animated animate__fadeInLeft" data-wow-delay="1s" alt="image">
                        <img src="assets/img/invoicing/invoicing-shape3.png"
                            class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/invoicing/invoicing-shape4.png"
                            class="wow animate__animated animate__fadeInRight" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/sw-development-teams.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/invoicing-image/circle1.png" alt="image">
                        <img src="assets/img/invoicing-image/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Invoicing Area -->


<app-talk-button></app-talk-button>

<!-- <app-feedback></app-feedback>

<app-download></app-download> -->
