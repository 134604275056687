import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebsiteAppConstants } from 'src/app/shared/app.constant';
import { SharedData } from 'src/app/shared/shareddata';
import { WebsiteService } from 'src/app/shared/websiteservice.service';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss']
})
export class UserHeaderComponent implements OnInit {
  clogo: string;

  constructor(private router: Router,
    location: Location, private websiteService: WebsiteService,
    @Inject(DOCUMENT) private document: any,) { }

  ngOnInit(): void {
    let hostname = this.document.location.hostname;
    console.log("HOSTNAME = " + hostname);
    let sharedData: SharedData = new SharedData();
    sharedData.year = WebsiteAppConstants.Year;
    if (hostname.indexOf("pykube.com") > -1) {
      // this.softwareSection = false;
      sharedData.email = WebsiteAppConstants.PyEmail;
      sharedData.phone = WebsiteAppConstants.PyPhone;
      sharedData.logo = WebsiteAppConstants.PykubeLogo;
      sharedData.address = WebsiteAppConstants.PyAddress;
      sharedData.title = WebsiteAppConstants.PyTitle;
      sharedData.faviconTitle = WebsiteAppConstants.PyFaviconTitle;
      sharedData.faviconLogo = WebsiteAppConstants.PyFaviconLogo;
      this.websiteService.publishData(sharedData);
    } else {
      // this.softwareSection = true;
      sharedData.email = WebsiteAppConstants.TechnoEmail;
      sharedData.phone = WebsiteAppConstants.TechnoPhone;
      sharedData.logo = WebsiteAppConstants.TechnoHunkLogo;
      sharedData.address = WebsiteAppConstants.TechnoAddress;
      sharedData.title = WebsiteAppConstants.TechnoTitle;
      sharedData.faviconTitle = WebsiteAppConstants.TechnoFaviconTitle;
      sharedData.faviconLogo = WebsiteAppConstants.TechnoFaviconLogo;
      this.websiteService.publishData(sharedData);
    }
    // this.title = sharedData.title;

    this.websiteService.getShareData().subscribe((sharedData: SharedData) => {
      this.clogo = sharedData.logo;

    });


  }

}
