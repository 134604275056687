import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControlName, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { User } from 'src/app/Interfaces/domain';
import { AuthService } from 'src/app/services/auth.service';
import { SharedData } from 'src/app/shared/shareddata';
import { WebsiteService } from 'src/app/shared/websiteservice.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  title: string;
  logo: string;
  userData: any;
  user: any;
  message: string;
  loginForm: FormGroup<any>;

  constructor(private router: Router, private authService: AuthService,
    private websiteService: WebsiteService,
    private fb: FormBuilder,) { }

  ngOnInit() {
    this.initForm();
    this.websiteService
      .getShareData()
      .subscribe((sharedData: SharedData) => {
        this.logo = sharedData.logo;
        this.title = sharedData.title;
      });
  }
  public initForm() {
    this.loginForm = new FormGroup({
      loginid: new FormControl(''),
      password: new FormControl('')
    });
  }
  public userLogin() {
    const user = {
      'loginid': this.loginForm.controls['loginid'].value,
      'password': this.loginForm.controls['password'].value,
    };
    this.authService.userLogin(user).subscribe((result: any) => {
      //this.userData=result;
      console.log(result);
      if (result != null && result.role != null && (result.role == 'admin' || result.role == 'user' || result.role == 'consultant')) {
        localStorage.setItem("userDetails", JSON.stringify(result))
        this.router.navigate(['/dashboard']);

      }
      else {
        this.message = result.massage;
      }
    })
  }
}

