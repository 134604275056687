import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { BlogDetails6Component } from "./pages/blogs/blog-details6/blog-details6.component";
import { BlogDetails5Component } from "./pages/blogs/blog-details5/blog-details5.component";
import { BlogDetails4Component } from "./pages/blogs/blog-details4/blog-details4.component";
import { BrowserModule, Title } from "@angular/platform-browser";
import { CommonModule} from '@angular/common'
import { NgModule,NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA  } from "@angular/core";

import { SlickCarouselModule } from "ngx-slick-carousel";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { DefaultComponent } from "./demos/default/default.component";
import { FeedbackComponent } from "./common/feedback/feedback.component";
import { DemoTwoComponent } from "./demos/demo-two/demo-two.component";
import { DemoThreeComponent } from "./demos/demo-three/demo-three.component";
import { FunfactComponent } from "./common/funfact/funfact.component";
import { AboutComponent } from "./pages/about/about.component";
import { PartnerComponent } from "./common/partner/partner.component";
import { FeaturesComponent } from "./pages/features/features.component";
import { DownloadComponent } from "./common/download/download.component";
import { AccountButtonComponent } from "./common/account-button/account-button.component";
import { TalkButtonComponent } from "./common/talk-button/talk-button.component";
import { TeamComponent } from "./pages/team/team.component";
import { PricingComponent } from "./pages/pricing/pricing.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { BlogDetailsComponent } from "./pages/blog-details/blog-details.component";
import { SignupComponent } from "./pages/signup/signup.component";
import { SigninComponent } from "./pages/signin/signin.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { DemoFourComponent } from "./demos/demo-four/demo-four.component";
import { DemoFiveComponent } from "./demos/demo-five/demo-five.component";
import { DemoSixComponent } from "./demos/demo-six/demo-six.component";
import { BlogRightSidebarComponent } from "./pages/blog-right-sidebar/blog-right-sidebar.component";
import { JavaComponent } from "./courses/java/java.component";
import { DatascienceComponent } from "./courses/datascience/datascience.component";
import { DotnetComponent } from "./courses/dotnet/dotnet.component";
import { AdmissionProcessComponent } from "./pages/admission-process/admission-process.component";
import { ModalComponent } from "./pages/modal/modal.component";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { JavaSyllabusDetailsComponent } from "./courses/java-syllabus-details/java-syllabus-details.component";
import { StudentSignupComponent } from "./pages/student-signup/student-signup.component";
import { SignupMassageComponent } from "./pages/signup-massage/signup-massage.component";
import { PaymentComponent } from "./pages/howPykubeWorks/payment/payment.component";
import { GetCallComponent } from "./pages/howPykubeWorks/get-call/get-call.component";
import { InterviewPreComponent } from "./pages/howPykubeWorks/interview-pre/interview-pre.component";
import { SalesMarketingComponent } from "./pages/howPykubeWorks/sales-marketing/sales-marketing.component";
import { AssistanceJobComponent } from "./pages/howPykubeWorks/assistance-job/assistance-job.component";
import { BlogDetails1Component } from "./pages/blogs/blog-details1/blog-details1.component";
import { BlogDetails2Component } from "./pages/blogs/blog-details2/blog-details2.component";
import { BlogDetails3Component } from "./pages/blogs/blog-details3/blog-details3.component";
import { PythonComponent } from "./courses/python/python.component";
import { DataanalystComponent } from "./courses/dataanalyst/dataanalyst.component";
import { DataengineerComponent } from "./courses/dataengineer/dataengineer.component";
import { QaengineerComponent } from "./courses/qaengineer/qaengineer.component";
import { CoursesComponent } from "./courses/courses.component";
import { CustomSoftDevComponent } from "./pages/howPykubeWorks/custom-soft-dev/custom-soft-dev.component";
import { WebDevelopmentComponent } from "./pages/howPykubeWorks/web-development/web-development.component";
import { UIUXDesignComponent } from "./pages/howPykubeWorks/ui-ux-design/ui-ux-design.component";
import { UserHeaderComponent } from './userdashboard/user-header/user-header.component';
import { JavaAdvanceQuestionComponent } from './courses/java-advance-question/java-advance-question.component';
import { JavaInterviewQuizComponent } from './courses/java-interview-quiz/java-interview-quiz.component';
import { JavaMiscAdvanceQuestionComponent } from './courses/Interview-miscellanies-Questions/Interview-miscellanies-Questions.component';
import { AwsInterviewQustionsComponent } from './courses/aws-interview-qustions/aws-interview-qustions.component';
import { JavaScriptInterviewQuestionsComponent } from './courses/java-script-interview-questions/java-script-interview-questions.component';
import { AngularInterviewQuestionsComponent } from './courses/angular-interview-questions/angular-interview-questions.component';


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        DefaultComponent,
        FeedbackComponent,
        DemoTwoComponent,
        DemoThreeComponent,
        FunfactComponent,
        AboutComponent,
        PartnerComponent,
        FeaturesComponent,
        DownloadComponent,
        AccountButtonComponent,
        TalkButtonComponent,
        TeamComponent,
        PricingComponent,
        NotFoundComponent,
        FaqComponent,
        BlogComponent,
        BlogDetailsComponent,
        SignupComponent,
        SigninComponent,
        ContactComponent,
        DemoFourComponent,
        DemoFiveComponent,
        DemoSixComponent,
        BlogRightSidebarComponent,
        JavaComponent,
        DatascienceComponent,
        DotnetComponent,
        AdmissionProcessComponent,
        ModalComponent,
        JavaSyllabusDetailsComponent,
        StudentSignupComponent,
        SignupMassageComponent,
        PaymentComponent,
        GetCallComponent,
        InterviewPreComponent,
        SalesMarketingComponent,
        AssistanceJobComponent,
        BlogDetails1Component,
        BlogDetails2Component,
        BlogDetails3Component,
        BlogDetails4Component,
        BlogDetails5Component,
        BlogDetails6Component,
        PythonComponent,
        DataanalystComponent,
        DataengineerComponent,
        QaengineerComponent,
        CoursesComponent,
        CustomSoftDevComponent,
        WebDevelopmentComponent,
        UIUXDesignComponent,
        DashboardComponent,
        UserHeaderComponent,
        JavaAdvanceQuestionComponent,
        JavaInterviewQuizComponent,
        JavaMiscAdvanceQuestionComponent,
        AwsInterviewQustionsComponent,
        JavaScriptInterviewQuestionsComponent,
        AngularInterviewQuestionsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SlickCarouselModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CommonModule,
    ],
    providers: [
        Title,
    ],
    bootstrap: [AppComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
      ]
})
export class AppModule {}
