import { WebsiteAppConstants } from "./../../shared/app.constant";
import { WebsiteService } from "./../../shared/websiteservice.service";
import { SharedData } from "./../../shared/shareddata";
import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ConsultantsService } from "src/app/services/consultants.service";
import { environment } from "src/environments/environment";
import { DOCUMENT } from "@angular/common";

@Component({
    selector: "app-default",
    templateUrl: "./default.component.html",
    styleUrls: ["./default.component.scss"],
})
export class DefaultComponent implements OnInit {
    constructor(
        private consultantsService: ConsultantsService,
        private websiteService: WebsiteService,
        @Inject(DOCUMENT) private document: any
    ) {
        this.siteKey = "6LeeYoYjAAAAABkm9v8yQoxBcbXnGqFRnkoTwODq";
    }

    @ViewChild("teams") teams!: ElementRef;
    softwareSection: boolean = false;

    siteKey: string;
    email: any;
    name: any;
    phone: number;
    selectedTeam: any;
    testName: any;
    showForm = false;
    heading = "Todo List";
    todoData: any[] = [];
    select = false;
    data: any;
    title: string = "";
    testTitle = "Java";
    autoSlideInterval: any;
    currentIndex = 0;
    toggleForm() {
        this.showForm = !this.showForm;
    }

    testForm = new FormGroup({
        uname: new FormControl("", [
            Validators.required,
            Validators.pattern("[a-zA-Z]*"),
        ]),
        uemail: new FormControl("", [Validators.required, Validators.email]),
        mobile: new FormControl("", [
            Validators.required,
            Validators.minLength(10),
            Validators.pattern("[0-9]*"),
        ]),
    });

    loginUser() {
        console.warn(this.testForm.value);
    }
    get uname() {
        return this.testForm.get("uname");
    }

    get uemail() {
        return this.testForm.get("uemail");
    }

    get mobile() {
        return this.testForm.get("mobile");
    }

    ngOnInit() {
        let hostname = this.document.location.hostname;
        // console.log("HOSTNAME = " + hostname);
        let sharedData: SharedData = new SharedData();
        sharedData.year = WebsiteAppConstants.Year;
        if (
            hostname.indexOf("pykube.com") > -1 ||
            hostname.indexOf("pykube.in") > -1 ||
            hostname.indexOf("pykube.net") > -1 ||
            hostname.indexOf("localhost") > -1
        ) {
            this.softwareSection = false;
            sharedData.email = WebsiteAppConstants.PyEmail;
            sharedData.phone = WebsiteAppConstants.PyPhone;
            sharedData.logo = WebsiteAppConstants.PykubeLogo;
            sharedData.address = WebsiteAppConstants.PyAddress;
            sharedData.title = WebsiteAppConstants.PyTitle;
            sharedData.faviconTitle = WebsiteAppConstants.PyFaviconTitle;
            sharedData.faviconLogo = WebsiteAppConstants.PyFaviconLogo;
            this.websiteService.publishData(sharedData);
        } else {
            this.softwareSection = true;
            sharedData.email = WebsiteAppConstants.TechnoEmail;
            sharedData.phone = WebsiteAppConstants.TechnoPhone;
            sharedData.logo = WebsiteAppConstants.TechnoHunkLogo;
            sharedData.address = WebsiteAppConstants.TechnoAddress;
            sharedData.title = WebsiteAppConstants.TechnoTitle;
            sharedData.faviconTitle = WebsiteAppConstants.TechnoFaviconTitle;
            sharedData.faviconLogo = WebsiteAppConstants.TechnoFaviconLogo;
            this.websiteService.publishData(sharedData);
        }
        // this.title = sharedData.title;
        this.startAutoSlide();
    }

    onSelected() {
        alert(this.teams.nativeElement.value);
    }
    public assignedTest() {
        if (this.teams.nativeElement.value === "Core-Java") {
            this.testName = "Test100";
        } else {
            this.testName = "PythonDemoTest";
        }
        this.consultantsService
            .sendTestLinkToUser(
                this.name,
                this.email,
                this.teams.nativeElement.value,
                this.testName
            )
            .subscribe((res) => {
                this.data = res;
                // window.location.href = this.data.generatedTestLink;
                window.open(this.data.generatedTestLink, "_blank").focus();
                window.location.reload();
            });
    }

    testimonials: any[] = [
        {
            quote: "A Game-Changer for Staffing Needs Pykube Technologies has been instrumental in our company's growth by consistently delivering exceptional software engineers. Their team's meticulous screening process ensures that every candidate not only meets but exceeds our expectations. Their responsiveness and proactive communication make them a pleasure to work with. Highly recommend Pykube Technologies for any company looking to augment their technical team with top-notch talent.",
            client: "Nvidia",
        },
        {
            quote: "Reliable and Responsive Service Pykube Technologies has been our go- to partner for sourcing software engineers.Their ability to quickly understand our requirements and provide candidates who fit seamlessly into our projects is commendable.Their consultants are not only skilled but also proactive in resolving any issues that arise.We appreciate their flexibility and dedication to meeting our staffing needs efficiently. Pykube Technologies has significantly contributed to our project successes, and we look forward to continuing our partnership.",
            client: "Google",
        },
        {
            quote: "Exceptional Engineering Expertise Pykube Technologies has consistently delivered outstanding software engineers who have become integral parts of our development teams. Their consultants bring not only technical proficiency but also a collaborative spirit that aligns perfectly with our company culture. Their commitment to quality and their thorough vetting process ensure that we receive candidates who are well-suited to our specific project requirements. Working with Innovative Talent Partners has been a true partnership, and we highly recommend their services to any company seeking top engineering talent.",
            client: "Wells Fargo",
        },
        {
            quote: "Seamless Integration and Exceptional Support Pykube Technologies has been instrumental in filling critical software engineering roles within our organization. Their team understands our needs deeply and consistently provides candidates who exceed our technical and cultural expectations. Their consultants are responsive and proactive, ensuring that our projects stay on track. Pykube Technologies dedication to quality and their ability to adapt to our evolving requirements make them a trusted partner in our staffing strategy. We're grateful for their contributions to our success and would recommend Pykube Technologies without hesitation.",
            client: "Lockheed Martin",
        },
    ];

    prevTestimonial() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
        } else {
            this.currentIndex = this.testimonials.length - 1;
        }
    }

    nextTestimonial() {
        if (this.currentIndex < this.testimonials.length - 1) {
            this.currentIndex++;
        } else {
            this.currentIndex = 0;
        }
    }

    ngOnDestroy() {
        this.stopAutoSlide();
    }

    startAutoSlide() {
        this.autoSlideInterval = setInterval(() => {
            this.nextTestimonial();
        }, 3000);
    }

    stopAutoSlide() {
        if (this.autoSlideInterval) {
            clearInterval(this.autoSlideInterval);
        }
    }
}
