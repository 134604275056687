import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-java-syllabus-details',
  templateUrl: './java-syllabus-details.component.html',
  styleUrls: ['./java-syllabus-details.component.scss']
})
export class JavaSyllabusDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
