import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-ux-design',
  templateUrl: './ui-ux-design.component.html',
  styleUrls: ['./ui-ux-design.component.scss']
})
export class UIUXDesignComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
