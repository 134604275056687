import { Component, OnInit, ViewChild,ElementRef} from '@angular/core';

@Component({
  selector: 'app-signup-massage',
  templateUrl: './signup-massage.component.html',
  styleUrls: ['./signup-massage.component.scss']
})
export class SignupMassageComponent implements OnInit {

  @ViewChild('popup') popup:ElementRef;
 
  constructor() {
    
   }
 

  ngOnInit(): void {   
    
  } 
  
   openPopup () {   
  this.popup.nativeElement.classList.add("open-popup")
  
  }

  closePopup () {
    this.popup.nativeElement.classList.remove("open-popup")    
  }

}
