import { HttpClient } from "@angular/common/http";
import { SharedData } from "./shareddata";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class WebsiteService {
    url = environment.apiUrls;
    public bsharedData = new BehaviorSubject<any>(""); // hightlight-line

    constructor(private http: HttpClient) {}

    public getShareData(): Observable<SharedData> {
        return this.bsharedData.asObservable();
    }
    public publishData(shareData: SharedData) {
        this.bsharedData.next(shareData);
    }

    sendMessageRecord(messageObj: any) {
        console.log(messageObj);
       return this.http.post(this.url +"/api/contacts", messageObj);
    }
}
