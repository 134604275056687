import { WebsiteService } from "./../../shared/websiteservice.service";
import { Component, Inject, OnInit } from "@angular/core";
import { SharedData } from "src/app/shared/shareddata";
import { DOCUMENT } from "@angular/common";

@Component({
    selector: "app-about",
    templateUrl: "./about.component.html",
    styleUrls: ["./about.component.scss"],
})
export class AboutComponent implements OnInit {
    title: string;
    technoTeam: boolean;
    pyKubeTeam: boolean;

    constructor(
        private websiteService: WebsiteService,
        @Inject(DOCUMENT) private document: any
    ) {}

    ngOnInit() {
        let hostname = this.document.location.hostname;
        console.log("HOSTNAME = " + hostname);
        if (hostname.indexOf("pykube.com") > -1 || hostname.indexOf("pykube.in") > -1 || hostname.indexOf("pykube.net") > -1 || hostname.indexOf("localhost") > -1) {
        
            this.technoTeam = false;
            this.pyKubeTeam = true;
        } else {
            this.technoTeam = true;
            this.pyKubeTeam = false;
        }
        this.websiteService
            .getShareData()
            .subscribe((sharedData: SharedData) => {
                console.log(sharedData);
                this.title = sharedData.title;
            });
    }
}
