<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>UX/UI Design & Ideation</h2>

        </div>
    </div>
</div>

<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>UX/UI Design & Ideation</h2>
                    <div class="bar"></div>

                    <ul class="services-list1">
                       

                        <li><span><i class="flaticon-check-mark"></i> User research and analysis: This involves
                                conducting research to understand the needs
                                and goals of users, and using that information to inform the design of the product. This
                                may include usability testing, user interviews, and surveys..</span>
                        </li>
                        <li><span><i class="flaticon-check-mark"></i> Wireframing and prototyping: This involves
                                creating rough sketches or digital mockups
                                of the product to help visualize and test ideas. This may include creating wireframes,
                                which are low-fidelity representations of the product, or prototypes, which are more
                                interactive and realistic representations.</span></li>

                        <li><span><i class="flaticon-check-mark"></i>User interface design: This involves creating the  visual design of the product, including the layout, color scheme, typography, and other design elements.</span></li>

                        <li><span><i class="flaticon-check-mark"></i>User experience design: This involves designing the  overall user experience of the product, including the flow and functionality of the product, and ensuring that it is intuitive and enjoyable for users.</span></li>

                        <li><span><i class="flaticon-check-mark"></i> Testing and validation: This involves conducting user testing to ensure that the product meets the needs and goals of users, and making any necessary changes based on feedback.</span></li>

                        <li><span><i class="flaticon-check-mark"></i> Documentation and presentation: This may involve creating documentation and presentation materials to communicate the design process and decisions to stakeholders and clients.</span></li>



                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>