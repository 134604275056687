import { User } from 'src/app/Interfaces/domain';
import { Injectable } from "@angular/core";
import { CmApiService } from "./cm-api.service";
import { HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { ConsultantsVO } from '../Interfaces/ConsultantsVO ';

@Injectable({
    providedIn: "root",
})
export class ConsultantsService extends CmApiService {
    userid: string;
    empId: string;
    userDetail: any;
    
    constructor(httpClient: HttpClient,  private router: Router) {
        super(httpClient);
console.log("@@@@@@@@ConsultantsServiceConsultantsServiceConsultantsService############")
       // this.setup();
    }

    public setup(){
        const user=localStorage.getItem("userDetails");
        if(user!=null){
           this.userDetail =JSON.parse(user);
             let data: string[] =this.userDetail.name.split(" ");
             this.userid =this.userDetail.email;
             this.empId = this.userDetail.empId;
        }
    }
    public signupConsultants(consultantsVO: ConsultantsVO) {
        return this.doPost("/consultants/register", consultantsVO);
    }

    public sendTestLinkToUser(name, email, techName, testName) {
        // const params = [];
        // params.push({ name: 'name', value: name });
        // params.push({ name: 'email', value: email });
        // params.push({ name: 'techName', value: techName });
        // params.push({ name: 'testName', value: testName });
        return this.doPost(
            "/send-test-link?name=" +  name + "&email=" + email + "&techName=" + techName + "&testName=" + testName
        );
    }

    public assignedTest(userid: string) {

        return this.doGet('/consultants/assigned/techtest?userId=' + userid);
      }
      public studentAttendance(empId: string) {
        return this.doGet('/student/attendance/' +empId)
      }
      public showHistoryDetails(userid: string) {
        return this.doGet('/consultants/test/history?userid=' + userid)
      }

      public logout(){
        localStorage.clear();
    this.router.navigate(['/signin'],{
        queryParams: {},
    });
      }

}
