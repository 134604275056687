import { AttendanceDetails } from '../../Interfaces/domain';
import { ConsultantsService } from 'src/app/services/consultants.service';
import { Component, OnInit } from '@angular/core';
import { ConsultantAttendanceWrapperDTO } from 'src/app/Interfaces/domain';
import { CmApiService } from 'src/app/services/cm-api.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    attendanceShow: boolean;
    testShow: boolean;
    testHistoryShow: boolean;
    email: string;
    userDetail: any;
    studentAttendances: any;
    assignedTestData: any;
    historyDetails: any;

    constructor(private consultantsService: ConsultantsService, private cmApiService: CmApiService) { }

    ngOnInit(): void {

        const data = localStorage.getItem("userDetails");
        if (data != null) {
            this.userDetail = JSON.parse(data);
            this.email = this.userDetail.email.split(" ");
        }
        this.assignTest();
        this.studentAttendance();
        this.loadTestHistoryDetails();
    }

    public assignTest() {
        this.consultantsService.assignedTest(this.userDetail.email).subscribe(res => {

            this.assignedTestData = res;
            this.testShow = true;
            console.log(this.assignedTestData);
            //  console.log("hello java");

        })
    }
    public studentAttendance() {
        this.consultantsService.studentAttendance(this.userDetail.empId).subscribe(res => {
            this.studentAttendances = res;
            this.attendanceShow = true;
            // console.log(this.studentAttendances);
        })
    }
    public loadTestHistoryDetails() {
        this.consultantsService.showHistoryDetails(this.userDetail.email).subscribe((res => {
            this.historyDetails = res;
            this.testHistoryShow = true;
            //console.log(this.historyDetails);
        }))
    }
    public startTest(testName: string, techName: string, email: string) {
        window.open(environment.appUrl + 'action/lload-tech-test?testName=' + testName + '&techName=' + techName + '&userId=' + email);
    }

    showTestSummary(userId: string, userSessionId: string) {
        window.open(environment.appUrl + 'action/consultants/test/summary?userId=' + userId + '&userSessionId=' + userSessionId)
    }
    public showHistoryDetails(testName: string, techName: string, userId: string, userSessionId: string) {
        window.open(environment.appUrl + 'action/oexamDetail?testName=' + testName + '&techName=' + techName + '&userId=' + userId + '&userSessionId=' + userSessionId)
    }

    public logout() {
        this.consultantsService.logout();
        //  document.location.reload();
    }

}
