import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interview-pre',
  templateUrl: './interview-pre.component.html',
  styleUrls: ['./interview-pre.component.scss']
})
export class InterviewPreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
