<!-- Start Partner Area -->
<div class="partner-area">
    <div class="container">
        <!-- <h3>More that 1.5 million businesses and organizations use Klob</h3> -->

        <div class="partner-inner">
            <div class="row align-items-center">
                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/charles.png" alt="partner">
                        <img src="assets/img/partners/charles.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/Delta-Logo.png" alt="partner">
                        <img src="assets/img/partners/Delta-Logo.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/worldwide-express-logo.png" alt="partner">
                        <img src="assets/img/partners/worldwide-express-logo.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/sentara.png" alt="partner">
                        <img src="assets/img/partners/sentara.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/american_airlines.png" alt="partner">
                        <img src="assets/img/partners/american_airlines.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/Texas_Capital_Bank_Logo.png" alt="partner">
                        <img src="assets/img/partners/Texas_Capital_Bank_Logo.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/Liberty_Mutual_Insurance_Logo.png" alt="partner">
                        <img src="assets/img/partners/Liberty_Mutual_Insurance_Logo.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-3 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/logo.web" alt="partner">
                        <img src="assets/img/partners/partner8.pn" alt="partner">
                    </a>
                </div>

                <!--<div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/partner9.png" alt="partner">
                        <img src="assets/img/partners/partner9.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/partner1.png" alt="partner">
                        <img src="assets/img/partners/partner1.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/partner1.png" alt="partner">
                        <img src="assets/img/partners/partner1.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/partner2.png" alt="partner">
                        <img src="assets/img/partners/partner2.png" alt="partner">
                    </a>
                </div>

                 <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/partner3.png" alt="partner">
                        <img src="assets/img/partners/partner3.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/partner4.png" alt="partner">
                        <img src="assets/img/partners/partner4.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/partner5.png" alt="partner">
                        <img src="assets/img/partners/partner5.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/partner6.png" alt="partner">
                        <img src="assets/img/partners/partner6.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/partner7.png" alt="partner">
                        <img src="assets/img/partners/partner7.png" alt="partner">
                    </a>
                </div>

                <div class="col-lg-2 col-sm-4 col-md-3 col-6">
                    <a href="#">
                        <img src="assets/img/partners/partner8.png" alt="partner">
                        <img src="assets/img/partners/partner8.png" alt="partner">
                    </a>
                </div> -->
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->