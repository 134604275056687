import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-student-signup',
  templateUrl: './student-signup.component.html',
  styleUrls: ['./student-signup.component.scss']
})
export class StudentSignupComponent implements OnInit {

  studentSignupForm: FormGroup;
  url = ""
  constructor(private formBuilder: FormBuilder, private http: HttpClient, private router: Router) { }

  ngOnInit(): void {
    this.studentSignupForm = this.formBuilder.group({
      firstname: [''],
      lastname: [''],
      email: [''],
      mobile: [''],
      dateofjoin: [''],
      gender: [''],
      stream: [''],
      address: [''],
      picture: [''],
      password: ['']
    })
  }

  studentSignUp() {
    this.http.post<any>(this.url, this.studentSignupForm.value).subscribe(res => {
      alert("Your Registration is Successfully ...😊");
      console.log(this.studentSignupForm.value)
      this.studentSignupForm.reset();
      this.router.navigate(['login'])
    }, err => {
      alert("There are somthing problems...😊")
    })
  }

}
