<div class="banner-section">
    <div class="container">
        <div class="row m-0 align-items-center">
            <div class="col-lg-5 col-md-12 p-0">
                <div class="hero-content">
                    <h1 style="font-family: Raleway, sans-serif;">Training Orientation Session By <span style="color: #44ce6f;">Nagendra Yadav</span></h1>
                    <p>The Full Stack Java Developer Program is an intense bootcamp offering a complete suite of
                        software development skills.</p>
                    <a routerLink="/" class="btn btn-primary">Ask More Get More!</a>

                </div>
            </div>
            <div class="col-lg-7 col-md-12 p-0">
                <div class="hero-image">
                    <div class="main-image">
                        <img src="assets/img/banner-image/banner1.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/banner-image/banner2.png" class="wow animate__animated animate__fadeInLeft"
                            data-wow-delay="1.5s" alt="image" style="height:120px;">
                        <img src="assets/img/banner-image/banner3.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="2s" alt="image" style="height:100px;">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/banner-image/main-pic.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/banner-image/circle1.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image">
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner Area -->

<!-- Start Partner Area -->
<div class="partner-style-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner1.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner2.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner3.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner4.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner5.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner6.png" alt="image"></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="margin-top:70px ;">
    <div class="container">
        <div>
            <div>
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;"><b>
                        <img src="https://findicons.com/files/icons/1788/large_icons_social/256/social_network.png" style="height:90px;">
                            
                        Interview Questions</b></h2>
                    <div class="bar"></div>
                    <p   class="p-ans" >
                       Here we will help consultant to be ready for the market. We will do practice with most frequently asked interview questions and coding execercise so that at least consultant can answers at least initial level of questions.
                    </p>
                  

                    <div style="height:30px;background-color:#fffff4 ">
                    </div>
                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">1. Why were client-side frameworks like Angular introduced?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">Back in the day, web developers used VanillaJS and jQuery to develop dynamic websites but, as the logic of one's website grew, the code became more and more tedious to maintain. For applications that use complex logic, developers had to put in extra effort to maintain the separation of concerns for the app. Also, jQuery did not provide facilities for data handling across views.<br><br>
                        For tackling the above problems,<span style=" color: blue;"> client-side frameworks like Angular</span>  came into the picture, which made life easier for the developers by handling the separation of concerns and dividing code into smaller bits of information (In the case of Angular, called Components).<br><br>
                        Client-side frameworks allow one to develop advanced web applications like Single-Page-Application. Not that we cannot develop SPAs using VanillaJS, but by doing so, the development process becomes slower.
                    </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 


                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">2.  What is TypeScript?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">TypeScript is a superset of JavaScript that offers excellent consistency. It is highly recommended, as it provides some syntactic sugar and makes the code base more comfortable to understand and maintain. Ultimately, TypeScript code compiles down to<span style="color: blue;"> JavaScript</span>  that can run efficiently in any environment. </p>
                  
                  
                        
                     
                       


                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 


                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">3. What is data binding? Which type of data binding does Angular deploy?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">Data binding is a phenomenon that allows any internet user to manipulate Web page elements using a Web browser. It uses dynamic HTML and does not require complex scripting or programming. We use data binding in web pages that contain interactive components such as forms, calculators, tutorials, and games. Incremental display of a webpage makes data binding convenient when pages have an enormous amount of data. 

                        <br>
                        <br>
                        Angular uses the two-way binding. Any changes made to the user interface are reflected in the corresponding model state. Conversely, any changes in the model state are reflected in the UI state. This allows the framework to connect the DOM to the Model data via the controller. However, this approach affects performance since every change in the DOM has to be tracked.</p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 


                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">4. What are Single Page Applications (SPA)?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">Single-page applications are web applications that load once with new features just being mere additions to the user interface. It does not load new HTML pages to display the new page's content, instead generated dynamically. This is made possible through JavaScript's ability to manipulate the DOM elements on the existing page itself. A SPA approach is faster, thus providing a seamless user experience.</p>


                     
                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 


                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">5. What are decorators in Angular? </span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">Decorators are a design pattern or functions that define how Angular features work. They are used to make prior modifications to a class, service, or filter. Angular supports four types of decorators, they are:<br>
                    <br>
                        
                    <span style="color: #44ce6f;">1.Class Decorators</span><br><br>
                    <span style="color: #281ebc;">2.Property Decorators</span><br><br>
                    <span style="color: #bc1e1e;">3.Method Decorators</span><br><br>
                    <span style="color: #11e2c3;">4.Parameter Decorators</span>

                    </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 


                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">6. Mention some advantages of Angular.</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">Some of the common advantages of Angular are - <br><br>

                    
                       1.<span style="color: #281ebc;">MVC architecture - </span>  Angular is a full-fledged MVC framework. It provides a firm opinion on how the application should be structured. It also offers bi-directional data flow and updates the real DOM. 
                      <br>
                      <br>
                      2.<span style="color: #09dd3b;">Modules: </span> Angular consists of different design patterns like components, directives, pipes, and services, which help in the smooth creation of applications
                      <br>
                      <br>
                      3.<span style="color: #dd0909;">Dependency injection:  </span>Components dependent on other components can be easily worked around using this feature. 
                    </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 


                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">7. What are the new updates with Angular10? </span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">
                        <br>
                        <img src="assets\img\anguler\ans7.png" >
                        <br>
                        <br>
                        ==> Older versions of TypeScript not supported - Previous versions of Angular supported typescript 3.6, 3.7, and even 3.8. But with Angular 10, TypeScript bumped to TypeScript 3.9.

                        <br>
                        ==> Warnings about CommonJS imports - Logging of unknown property bindings or element names in templates is increased to the "error" level, which was previously a "warning" before.<br>
                        ==>Optional strict setting - Version 10 offers a stricter project setup when you create a new workspace with ng new command.
                       <br>

                       <span style="color: rgb(100, 94, 3);">ng new --strict

                       </span>
                       <br>
                       <br>
                       NGCC Feature - Addition of NGCC features with a program based entry point finder. 
                       <br>
                       <br>
                       ==>Updated URL routing
                       <br>
                       ==>Deprecated APIs - Angular 10 has several deprecated APIs.
                       <br>
                       ==>Bug fixes - With this Angular 10 version, there have been a number of bug fixes, important ones being the compiler avoiding undefined expressions and the core avoiding a migration error when a nonexistent symbol is imported.
                    <br>

                    ==>New Default Browser Configuration - Browser configuration for new projects has been upgraded to outdo older and less used browsers. 
                    
                    </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 


                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">8. What are Templates in Angular?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">Angular Templates are written with HTML that contains Angular-specific elements and attributes. In combination with the model and controller's information, these templates are further rendered to provide a dynamic view to the user.</p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 


                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">9. What are Annotations in Angular?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">Annotations in Angular are used for creating an annotation array. They are the metadata set on the class that is used to reflect the Metadata library.</p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 
                    <div style="background-color: #44ce6f;">
                 
                        <h1>Angular Interview Questions For Beginners</h1>
                        
    
                     </div>

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">10. What are Directives in Angular?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">Directives are attributes that allow the user to write new HTML syntax specific to their applications. They execute whenever the Angular compiler finds them in the DOM. Angular supports three types of directives. 

                        <br>
                        <br>

                        1.<span style="color: #281ebc;">Component Directives </span> <br><br>
                        2.<span style="color: #12db1f;">Structural Directives </span> <br><br>
                        3.<span style="color: #bc1e1e;">Attribute Directives </span><br><br>
                        

                     </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 


                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">11. What is an AOT compilation? What are its advantages?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">The Ahead-of-time (AOT) compiler converts the Angular HTML and TypeScript code into JavaScript code during the build phase, i.e., before the browser downloads and runs the code.

                        <br>
                        <br>
                        Some of its advantages are as follows. 
                        <br>
                        <br>
                        1.<span style="color: #281ebc;">Faster rendering </span> <br><br>
                        2.<span style="color: #aac306;">Fewer asynchronous requests </span> <br><br>
                       
                        3.<span style="color: #9705c8;">Smaller Angular framework download size</span> <br><br>
                        4.<span style="color: #770404;">Quick detection of template errors</span> <br><br>
                        5.<span style="color: #281ebc;">Better security </span> <br><br>
                     </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">12. What are Components in Angular?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">
                        <img src="assets\img\anguler\ans12.png" >
                        <br>
                        <br>

                       <span style="color: #110897;">Components</span>  are the basic building blocks of the user interface in an Angular application. Every component is associated with a template and is a subset of directives. An Angular application typically consists of a root component, which is the AppComponent, that then branches out into other components creating a hierarchy
                     </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">13. What are Pipes in Angular? </span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">

                        <img src="assets\img\anguler\ans13.png" >
                        <br>
                        <br>
                        Pipes are simple functions designed to accept an input value, process, and return as an output, a transformed value in a more technical understanding. Angular supports several built-in pipes. However, you can also create custom pipes that cater to your needs. 
                    <br>
                    <br>
                    Some key features include: 
                    <br>
                    <br>
                    1.<span style="color: #110897;">Pipes are defined using the pipe “|” symbol.</span> <br><br>

                    2.<span style="color: #1ea706;">Pipes can be chained with other pipes.</span> <br><br>

                    3.<span style="color: #c60505;">Pipes can be provided with arguments by using the colon (:) sign.</span> 
                    
                    
                    </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">14. What is the PipeTransform interface?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">As the name suggests, the interface receives an input value and transforms it into the desired format with a <span style="color: #110897;">transform()</span>  method. It is typically used to implement custom pipes.
                    <br>
                    <br>
                    <img src="assets\img\anguler\ans14.png" >
                    
                    </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">15. What are Pure Pipes?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">
                        These pipes are pipes that use pure functions. As a result of this, a pure pipe doesn't use any internal state, and the output remains the same as long as the parameters passed stay the same. Angular calls the pipe only when it detects a change in the parameters being passed. A single instance of the pure pipe is used throughout all components. <br><br>
                        <img src="assets\img\anguler\ans15.png" >

                     </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">16. What are Impure Pipes?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">
                        For every change detection cycle in Angular, an impure pipe is called regardless of the change in the input fields. Multiple pipe instances are created for these pipes. Inputs passed to these pipes can be mutable.
                        <br>
                        <br>
                        By default, all pipes are pure. However, you can specify impure pipes using the pure property, as shown below.

                        <br>
                        <img src="assets\img\anguler\ans16.png" >

                     </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">17. What is an ngModule?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans"> <span style="color: #e10505;">NgModules</span> are containers that reserve a block of code to an application domain or a workflow. <span style="color: #04b953;">@NgModules</span> takes a metadata object that generally describes the way to compile the template of a component and to generate an injector at runtime. In addition, it identifies the module's components, directives, and pipes, making some of them public, through the export property so that external components can use them.</p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">18. What are filters in Angular? Name a few of them.</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">
                        Filters are used to format an expression and present it to the user. They can be used in view templates, controllers, or services. Some inbuilt filters are as follows.
                     
                        <br>
                        <br>
                        <span style="color: #c60505;">date - Format a date to a specified format.</span><br><br>
                        <span style="color: #550303;">filter - Select a subset of items from an array.</span><br><br>
                        <span style="color: #05c64f;">Json - Format an object to a JSON string.</span><br><br>
                        <span style="color: #c60505;">limitTo -  Limits an array/string, into a specified number of elements/characters.</span><br><br>
                        <span style="color: coral">lowercase - Format a string to lowercase.</span>
                    
                    </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">19. What is view encapsulation in Angular?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">View encapsulation defines whether the template and styles defined within the component can affect the whole application or vice versa. Angular provides three encapsulation strategies:
                        <br>
                        <br>
                        1.<span style="color: #c60505;">Emulated - styles from the main HTML propagate to the component.</span><br><br>
                        1.<span style="color:orangered;">Native - styles from the main HTML do not propagate to the component. </span><br><br>
                        1.<span style="color: blue;">None - styles from the component propagate back to the main HTML and therefore are visible to all components on the page.</span><br><br>
                     </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 

                   

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">20. What are controllers?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">AngularJS controllers control the data of AngularJS applications. They are regular JavaScript Objects. The ng-controller directive defines the application controller.</p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">21. What do you understand by scope in Angular?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">The scope in Angular binds the HTML, i.e., the view, and the JavaScript, i.e., the controller. It as expected is an object with the available methods and properties. The scope is available for both the view and the controller. When you make a controller in Angular, you pass the $scope object as an argument. </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">22. Explain the lifecycle hooks in Angular</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">In Angular, every component has a lifecycle. Angular creates and renders these components and also destroys them before removing them from the DOM. This is achieved with the help of lifecycle hooks. Here's the list of them - 
                        <br>
                        <br>
                        1.<span style="color: chocolate;">ngOnChanges() </span> - Responds when Angular sets/resets data-bound input properties.

                        <br>
                        <br>
                       2.<span style="color: #9705c8;">ngOnInit() </span> - Initialize the directive/component after Angular first displays the data-bound properties and sets the directive/component's input properties/
                        <br>
                        <br>
3. <span style="color: #e10505;">ngDoCheck() </span> - Detect and act upon changes that Angular can't or won't detect on its own.
                        <br>
                        <br>
4.<span style="color: #04b953;">ngAfterContentInit() </span>  - Responds after Angular projects external content into the component's view.
                        <br>
                        <br>
5. <span style="color: #110897;">ngAfterContentChecked()</span>  - Respond after Angular checks the content projected into the component.
                        <br>
                        <br>
6. <span style="color: #770404;">ngAfterViewInit() </span> - Respond after Angular initializes the component's views and child views.
                        <br>
                        <br>
7. <span style="color: #e10505;">ngAfterViewChecked() </span> - Respond after Angular checks the component's views and child views.

                        <br>
                        <br>
8.<span style="color:brown ;">ngOnDestroy</span>  - Cleanup just before Angular destroys the directive/component.
                       
                     </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">23. What is String Interpolation in Angular?
                        </span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">String Interpolation is a one-way data-binding technique that outputs the data from TypeScript code to HTML view. It is denoted using double curly braces. This template expression helps display the data from the component to the view. 
                       <br>
                       <br>
                       &#123; &#123; data  &#125; &#125;


                    </p>
                    
                    

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">24. What are Template statements?</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">Template statements are properties or methods used in HTML for responding to user events. With these template statements, the application that you create or are working on, can have the capability to engage users through actions such as submitting forms and displaying dynamic content.
                        <br>
                        For example, 

                        <br>
                        <br>
                        
                      <textarea style="color: #04b953; width: 1000px;"><button (click)="deleteHero()">Delete hero</button></textarea>
                        <br>
                        The template here is deleteHero. The method is called when the user clicks on the button.
                     </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 

                    <div style="background-color: #44ce6f;">
                 
                        <h1>Angular Interview Questions For Advanced Level</h1>
                        
    
                     </div>

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;">25. What is the difference between AOT and JIT? </span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">Ahead of Time (AOT) compilation converts your code during the build time before the browser downloads and runs that code. This ensures faster rendering to the browser. To specify AOT compilation, include the --aot option with the ng build or ng serve command. 
                        <br>
                        <br>
                        The Just-in-Time (JIT) compilation process is a way of compiling computer code to machine code during execution or run time. It is also known as dynamic compilation. JIT compilation is the default when you run the ng build or ng serve CLI commands. 
                     </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 

                    <p style="font-size:22px;color:black">
                        <img src="https://cdn-icons-png.flaticon.com/512/5726/5726532.png" style="height:90px;"/>
                         <span style="font-weight: bold;color:black;font-size:24px;margin-left: 20px;"> 26.Explain the @Component Decorator.</span>
                        <br/> 
                        
                     </p>
                      
                     <p class="p-ans">TypeScript class is one that is used to create components. This genre of class is then decorated with the <span style="color: #04b953;">"@Component"</span>  decorator. The decorato’s purpose is to accept a metadata object that provides relevant information about the component.  

                     </p>

                     <div style="height:30px;background-color:#fffff4 ">
                    </div> 