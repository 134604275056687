import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-Interview-miscellanies-Questions',
  templateUrl: './Interview-miscellanies-Questions.component.html',
  styleUrls: ['./Interview-miscellanies-Questions.component.scss']
})
export class JavaMiscAdvanceQuestionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}