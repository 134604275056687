import { WebsiteService } from "./../../shared/websiteservice.service";
import { Router } from "@angular/router";
import { ConsultantsService } from "./../../services/consultants.service";
import { FormControl, FormGroup, FormControlName, Validators, FormsModule } from "@angular/forms";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { SharedData } from "src/app/shared/shareddata";
import { ConsultantsVO } from "src/app/Interfaces/ConsultantsVO ";

@Component({
    selector: "app-signup",
    templateUrl: "./signup.component.html",
    styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
    //  signupForm = new FormGroup({});
    @ViewChild("teams") teams!: ElementRef;
    email: string;
    name: string;
    mobile: number;
    doj: Date;
    stream: string;
    gender: string;
    image: string;
    address: string;
    signupForm: FormGroup<any>;
    message: string;
    logo: string;
    title: string;
    response: any;
    consultantsVO = {} as ConsultantsVO;
    //this.teams.nativeElement.value,
    constructor(private consultantsService: ConsultantsService, private router: Router,
        private websiteService: WebsiteService
    ) { }

    ngOnInit() {
        this.websiteService.getShareData().subscribe((sharedData: SharedData) => {
            this.logo = sharedData.logo;
            this.title = sharedData.title;
        });
    }
    onSelected() {
        alert(this.teams.nativeElement.value);
    }

    public signupConsultants() {
        const stream = this.teams.nativeElement.value;
        this.consultantsVO.stream = stream;
        this.consultantsService.signupConsultants(this.consultantsVO).subscribe(res => {
            this.response = res;
            if (this.response != null) {
                this.message = this.response.massage;
            } else {
                this.message = this.response.massage;
            }
            console.log(res);
        })

    }
}
