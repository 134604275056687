<!-- Start Banner Area -->
<div class="banner-section">
    <div class="container">
        <div class="row m-0 align-items-center">
            <div class="col-lg-5 col-md-12 p-0">
                <div class="hero-content">
                    <h1 style="font-family: Raleway, sans-serif;">Training Orientation By <span
                            style="color: #44ce6f;">Expert Trainer</span></h1>
                    <p>Big data is changing the way we do business and creating a need for data engineers who can
                        collect and manage large quantities of data..</p>
                    <a routerLink="/" class="btn btn-primary">Ask More Get More!</a>

                </div>
            </div>
            <div class="col-lg-7 col-md-12 p-0">
                <div class="hero-image">
                    <div class="main-image">
                        <img src="assets/img/banner-image/main-modules-removebg.png"
                            class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image"
                            style="width: 500px;">
                        <img src="assets/img/banner-image/database.png"
                            class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.5s" alt="image"
                            style="height:200px;">
                        <img src="assets/img/banner-image/framework.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="2s" alt="image" style="height:200px;">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/banner-image/main-pic.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/banner-image/circle1.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image">
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner Area -->

<!-- Start Partner Area -->
<div class="partner-style-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner1.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner2.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner3.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner4.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner5.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner6.png" alt="image"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">
                        Data-Engineer Syllabus</h2>
                    <div class="bar"></div>
                    <p style="font-size: 20px;color:black;font-weight: bold;">
                        <img src="./assets/img/course/python.png" alt="image">
                        Detailed View of Every Module:
                    </p>
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> What is Python?</span></li>
                        <li><span><i class="flaticon-check-mark"></i> A Brief history of Python</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Why Should I learn Python? </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Installing Python </span></li>
                        <li><span><i class="flaticon-check-mark"></i>How to execute Python program</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Write your first program</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Booleans and comparisons in Python</span></li>
                        <li><span><i class="flaticon-check-mark"></i> and & or in Python</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Joining a list</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Length and sum</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Variables</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Numbers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> String </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Lists, Tuples & Dictionary </span></li>
                        <li><span><i class="flaticon-check-mark"></i> if...statement </span></li>
                        <li><span><i class="flaticon-check-mark"></i> if...else statement </span></li>
                        <li><span><i class="flaticon-check-mark"></i> elif...statement </span></li>
                        <li><span><i class="flaticon-check-mark"></i> The while...Loop </span></li>
                        <li><span><i class="flaticon-check-mark"></i> The for.... Loop </span></li>

                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h3 style="color:black">
                        <img src="./assets/img/course/api.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image" style="height:100px;">
                        <span style="font-family: Raleway, sans-serif; font-weight: 700;"> Python Fundamentals & Control
                            Statements
                        </span>
                    </h3>
                    <div class="bar"></div>
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Destructuring syntax</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Iterating over dictionaries</span></li>
                        <li><span><i class="flaticon-check-mark"></i> List slicing in Python </span></li>
                        <li><span><i class="flaticon-check-mark"></i> List comprehension in Python</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Comprehensions with conditionals</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Set and dictionary comprehensions</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Arguments and parameters</span></li>
                        <li> <span><i class="flaticon-check-mark"></i> Functions and return values in Python</span></li>
                        <li> <span><i class="flaticon-check-mark"></i> Default parameter values</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Continue statement </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Break statement</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Pass statement </span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->
<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h3 style="color:black">
                        <img src="./assets/img/course/api.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image" style="height:100px;">
                        <span style="font-family: Raleway, sans-serif; font-weight: 700;"> Functions, Modules &
                            Packages, Classes & Objects
                        </span>
                    </h3>
                    <div class="bar"></div>
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Define function</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Calling a function</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Function arguments </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Built-in functions</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Modules</span></li>
                        <li><span><i class="flaticon-check-mark"></i> How to import a module...?</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Command line arguments using sys module</span>
                        </li>
                        <li><span><i class="flaticon-check-mark"></i> Standard module- os</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Packages</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Lambda Functions</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Lambda with Map, Reduce & Filter</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Introduction about classes & objects</span></li>
                        <li> <span><i class="flaticon-check-mark"></i> Creating a class & object</span></li>
                        <li> <span><i class="flaticon-check-mark"></i> Inheritance</span></li>
                        <li> <span><i class="flaticon-check-mark"></i> Methods Overriding</span></li>
                        <li> <span><i class="flaticon-check-mark"></i> Data hiding</span></li>
                        <li> <span><i class="flaticon-check-mark"></i> Parameter naming in Python</span></li>
                        <li> <span><i class="flaticon-check-mark"></i> Magic methods in Python</span></li>

                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->
<!-- Start Services Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h3 style="color:black">
                        <img src="./assets/img/course/api.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image" style="height:100px;">
                        <span style="font-family: Raleway, sans-serif; font-weight: 700;"> Errors in Python, Files &
                            Directories
                        </span>
                    </h3>
                    <div class="bar"></div>
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">

                        <li><span><i class="flaticon-check-mark"></i> Introduction to errors in Python</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Built in errors in Python</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Raising errors in Python </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Creating our own errors in Python</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Dealing with Python errors</span></li>
                        <li><span><i class="flaticon-check-mark"></i> The on success block and re-raising
                                exceptions</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Handling those pesky user errors!</span>
                        </li>
                        <li><span><i class="flaticon-check-mark"></i> Debugging with Pycharm</span></li>

                        <li><span><i class="flaticon-check-mark"></i> Writing data to a file</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Reading data from a file</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Additional file methods</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Working with files</span></li>
                        <li> <span><i class="flaticon-check-mark"></i> Working with Directories</span></li>
                        <li> <span><i class="flaticon-check-mark"></i> Using the with syntax in Python</span></li>
                        <li> <span><i class="flaticon-check-mark"></i> Import errors and running as a Python
                                script</span></li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Database Connectivity</h2>
                    <div class="bar"></div>
                    <!-- <p style="font-size: 20px;color:black;font-weight: bold;">
                        <img src="./assets/img/course/django.png" alt="image">
                        Django
                    </p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Installing Pl/Sql </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Basic commands of sql </span></li>
                        <li><span><i class="flaticon-check-mark"></i> CRUD operations through python </span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->
<!-- Start Services Area -->
<!-- < <div class="services-area ptb-70" >
    <div class=" p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content " >
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">PHASE-3 -> AWS & DevOps TRAINING</h2>
                    <div class="bar"></div>
                    <h3><img src="./assets/img/course/course-content-icon.png"  alt=""  title="Course content"> Course Content </h3>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> MAVEN</span></li>
                        <li><span><i class="flaticon-check-mark"></i> JENKINS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Docker</span></li>
                        <li><span><i class="flaticon-check-mark"></i> EC2 Instance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> AWS Cloud Watch</span></li>
                        <li><span><i class="flaticon-check-mark"></i> AWS Secret Manager </span></li>
                        <li><span><i class="flaticon-check-mark"></i> RDS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> SQS/SNS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> API Gateway </span></li>
                        <li><span><i class="flaticon-check-mark"></i> AWS Lambda</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Auto Scalling</span></li>
                        <li><span><i class="flaticon-check-mark"></i> S3 Bucket</span></li>
                        <li><span><i class="flaticon-check-mark"></i> JUNIT 5.x</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Mockito</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/mocj.jpg" alt="image" height="600px">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  -->


<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Advanced built-in functions in
                        Python </h2>
                    <div class="bar"></div>
                    <!-- <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.</p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Generators in Python </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Python generator classes and iterators </span>
                        </li>
                        <li><span><i class="flaticon-check-mark"></i> Iterables in Python</span></li>
                        <li><span><i class="flaticon-check-mark"></i> The filter() function in Python </span></li>
                        <li><span><i class="flaticon-check-mark"></i> The map() function in Python</span></li>
                        <li><span><i class="flaticon-check-mark"></i> any() and all() in Python</span></li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Advanced Python Development </h2>
                    <div class="bar"></div>
                    <!-- <p style="font-size: 20px;color:black;font-weight: bold;">
                        <img src="./assets/img/course/shopping.png" alt="image">
                        Designing a system design for ecommerce
                    </p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Mutability in Python</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Default values for parameters</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Argument unpacking in Python</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Queues in Python </span>
                        </li>
                        <li><span><i class="flaticon-check-mark"></i> Dates and time in Python </span>
                        </li>
                        <li><span><i class="flaticon-check-mark"></i> Regex in Python</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Django Framework</h2>
                    <div class="bar"></div>
                    <p style="font-size: 20px;color:black;font-weight: bold;">
                        <img src="./assets/img/course/django.png" alt="image">
                        Django, Templates, Models
                    </p>
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> What is Django..? </span>
                        </li>
                        <li><span><i class="flaticon-check-mark"></i> Installing Django</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Your First View</span>
                        </li>
                        <li><span><i class="flaticon-check-mark"></i> Mapping urls to Views </span></li>
                        <li><span><i class="flaticon-check-mark"></i> How Django process a request..?</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Urlpatterns</span></li>
                        <li><span><i class="flaticon-check-mark"></i> 404 Errors.</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Template Basic</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Contexts</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Built-in Tags & filters</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Using Templates in Views</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Define models</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Setting up database access</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Creating Django apps</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Defining model fields</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Creating a model</span></li>
                        <li><span><i class="flaticon-check-mark"></i> How to create tables for models in Database</span>
                        </li>
                        <li><span><i class="flaticon-check-mark"></i> Inserting & updating Data</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Deleting Objects</span></li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Django Admin Interface, Django
                        Forms, User Authentication
                    </h2>
                    <div class="bar"></div>
                    <!-- <p style="font-size: 20px;color:black;font-weight: bold;">
                        <img src="./assets/img/course/shopping.png" alt="image">
                        Designing a system design for ecommerce
                    </p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Enabling admin interface </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Creating admin user</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Forms in Django</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Searching Query </span>
                        <li><span><i class="flaticon-check-mark"></i> GET & POST methods </span>
                        <li><span><i class="flaticon-check-mark"></i> Form fields in Django </span>
                        <li><span><i class="flaticon-check-mark"></i> Building a form in Django </span>
                        <li><span><i class="flaticon-check-mark"></i> User objects </span>
                        <li><span><i class="flaticon-check-mark"></i> Using the Django authentication system </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">NumPy:</h2>
                    <div class="bar"></div>
                    <!-- <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.</p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Creating multidimensional array</span></li>
                        <li><span><i class="flaticon-check-mark"></i> NumPy-Data types
                            </span></li>
                        <li><span><i class="flaticon-check-mark"></i>Array attributes</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Indexing and Slicing </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Creating array views and copies</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Manipulating array shapes</span></li>
                        <li><span><i class="flaticon-check-mark"></i> I/O with NumPy</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Panadas
                    </h2>
                    <div class="bar"></div>
                    <!-- <p style="font-size: 20px;color:black;font-weight: bold;">
                        <img src="./assets/img/course/shopping.png" alt="image">
                        Designing a system design for ecommerce
                    </p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Panadas</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Pandas Data frames</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Pandas Series</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Data aggregation with Pandas Data Frames </span>
                        <li><span><i class="flaticon-check-mark"></i> Concatenating and appending Data Frames </span>
                        <li><span><i class="flaticon-check-mark"></i> Joining Data Frames </span>
                        <li><span><i class="flaticon-check-mark"></i> Handling missing data </span>
                        <li><span><i class="flaticon-check-mark"></i> Writing CSV files with NumPy and pandas </span>
                        <li><span><i class="flaticon-check-mark"></i> HDF5 format </span>
                        <li><span><i class="flaticon-check-mark"></i> Reading and writing to Excel with pandas </span>
                        <li><span><i class="flaticon-check-mark"></i> JSON data </span>
                        <li><span><i class="flaticon-check-mark"></i> Parsing HTML with Beautiful Soup </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Statistics and Linear Algebra</h2>
                    <div class="bar"></div>
                    <!-- <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.</p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Basic statistics with NumPy</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Linear Algebra with NumPy </span></li>
                        <li><span><i class="flaticon-check-mark"></i>NumPy random numbers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Creating a NumPy masked array </span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Matplotlob:
                    </h2>
                    <div class="bar"></div>
                    <!-- <p style="font-size: 20px;color:black;font-weight: bold;">
                        <img src="./assets/img/course/shopping.png" alt="image">
                        Designing a system design for ecommerce
                    </p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Installation matplotlib</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Basic matplotlib plots</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Scatter plots</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Saving plots to file</span>
                        <li><span><i class="flaticon-check-mark"></i> plotting functions in pandas </span> </li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Pyspark:</h2>
                    <div class="bar"></div>
                    <!-- <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.</p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Installation</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Sparkconf
                            </span></li>
                        <li><span><i class="flaticon-check-mark"></i>RDD</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Data Frame </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Transformation</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Action</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Various Data Formats –
                                Table, JSON, PARQUET, ORC</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">REST API:
                    </h2>
                    <div class="bar"></div>
                    <!-- <p style="font-size: 20px;color:black;font-weight: bold;">
                        <img src="./assets/img/course/shopping.png" alt="image">
                        Designing a system design for ecommerce
                    </p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> POST API</span></li>
                        <li><span><i class="flaticon-check-mark"></i>GET API</span></li>
                        <li><span><i class="flaticon-check-mark"></i> PUT API</span></li>
                        <li><span><i class="flaticon-check-mark"></i> PATCH API</span>
                        <li><span><i class="flaticon-check-mark"></i> DELETE API</span> </li>
                        <li><span><i class="flaticon-check-mark"></i> ORM</span> </li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Data Ingestion: </h2>
                    <div class="bar"></div>
                    <!-- <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.</p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Batch Data</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Real Time Data </span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Start Invoicing Area -->
<!-- <div class="invoicing-area ptb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Phase-4 Real Hands on with Ecommerce websites </h2>
                    <div class="bar"></div>
                    <p style="font-size:20px;color:black">
                        Designing a system design for ecommerce
                    </p>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">GITHUB</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">BITBUCKET</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">JIRA</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Confluence</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">intelliJ</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Eclipse</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">MAVEN</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Gradle</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image">
                        <img src="assets/img/Project-Management-Journey.jpg" height="400px"
                            class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/invoicing/invoicing-shape2.png"
                            class="wow animate__animated animate__fadeInLeft" data-wow-delay="1s" alt="image">
                        <img src="assets/img/invoicing/invoicing-shape3.png"
                            class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/invoicing/invoicing-shape4.png"
                            class="wow animate__animated animate__fadeInRight" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/sw-development-teams.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/invoicing-image/circle1.png" alt="image">
                        <img src="assets/img/invoicing-image/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Invoicing Area -->

<app-talk-button></app-talk-button>

<!-- <app-feedback></app-feedback>





<app-download></app-download> -->
