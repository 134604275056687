<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Get in Touch</h2>
            <p>If you have an idea, we would love to hear about it.</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<div class="contact-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>Drop us message for any query</h2>
            <div class="bar"></div>
            <p>Let's get this conversation started. Tell us a bit about yourself and we'll get in touch as soon as we
                can.</p>
        </div>
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="fas fa-map-marker-alt"></i>
                            </div>
                            <span>Address</span>
                            {{address}}
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fas fa-envelope"></i>
                            </div>
                            <span>Email</span>
                            <a href="mailto:Info@pykube.com">{{email}}</a>
                            <!-- <a href="mailto:hello@klob.com">fax@email.com</a> -->
                        </li>
                        <li>
                            <div class="icon">
                                <i class="fas fa-phone-volume"></i>
                            </div>
                            <span>Phone</span>
                            <a href="tel:417-254-9197">{{phone}}</a>
                            <!-- <a href="tel:+44587154756">+55 5555 14574</a> -->
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="contact-form">
                    <form class="form" [formGroup]="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="name" id="name" class="form-control"
                                        placeholder="Name">
                                    <ng-container
                                        *ngIf="contactForm.controls.name.touched && contactForm.controls.name.invalid">
                                        <span class="text-red" *ngIf="contactForm.controls.name.errors.required">This
                                            field is
                                            mandatory</span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" formControlName="email" id="email" class="form-control"
                                        placeholder="Email">
                                    <ng-container
                                        *ngIf="contactForm.controls.email.touched && contactForm.controls.email.invalid">
                                        <span class="text-red" *ngIf="contactForm.controls.email.errors.required">This
                                            field is mandatory</span>
                                        <span class="text-red" *ngIf="contactForm.controls.email.errors.pattern">Please
                                            enter a valid email address</span>
                                    </ng-container>
                                </div>

                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="phone_number" id="phone_number" required
                                        data-error="Please enter your number" class="form-control" placeholder="Phone">
                                    <ng-container
                                        *ngIf="contactForm.controls.phone_number.touched && contactForm.controls.phone_number.invalid">
                                        <span class="text-red"
                                            *ngIf="contactForm.controls.phone_number.errors.required">This
                                            field is mandatory</span>
                                        <span class="text-red"
                                            *ngIf="contactForm.controls.phone_number.errors.pattern">Please
                                            enter a valid contact number</span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" formControlName="msg_subject" id="msg_subject"
                                        class="form-control" placeholder="Subject">
                                    <ng-container
                                        *ngIf="contactForm.controls.msg_subject.touched && contactForm.controls.msg_subject.invalid">
                                        <span class="text-red"
                                            *ngIf="contactForm.controls.msg_subject.errors.required">This field is
                                            mandatory</span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea formControlName="message" class="form-control" id="message" cols="30"
                                        rows="6" placeholder="Your Message"></textarea>
                                    <ng-container
                                        *ngIf="contactForm.controls.message.touched && contactForm.controls.message.invalid">
                                        <span class="text-red" *ngIf="contactForm.controls.message.errors.required">This
                                            field is
                                            mandatory</span>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit"  (click)="handleSubmit()"
                                    class="btn btn-primary">Send
                                    Message</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="response-msg" *ngIf="responseMessageDesign">{{responseMessage}}</div>
            </div>
        </div>
    </div>
    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
</div>

<!-- <app-account-button></app-account-button> -->