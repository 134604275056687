<!-- Start Banner Area -->
<div class="banner-section">
    <div class="container">
        <div class="row m-0 align-items-center">
            <div class="col-lg-5 col-md-12 p-0">
                <div class="hero-content">
                    <h1 style="font-family: Raleway, sans-serif;">Training Orientation By <span
                            style="color: #44ce6f;">Expert Trainer</span></h1>
                    <p>A data analyst gathers, cleans, and studies data sets to help solve problems. Here's how you can
                        start on a path to become one.</p>
                    <a routerLink="/" class="btn btn-primary">Ask More Get More!</a>

                </div>
            </div>
            <div class="col-lg-7 col-md-12 p-0">
                <div class="hero-image">
                    <div class="main-image">
                        <img src="assets/img/banner-image/banner1.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/banner-image/banner2.png" class="wow animate__animated animate__fadeInLeft"
                            data-wow-delay="1.5s" alt="image" style="height:120px;">
                        <img src="assets/img/banner-image/banner3.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="2s" alt="image" style="height:100px;">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/banner-image/main-pic.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/banner-image/circle1.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image">
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner Area -->

<!-- Start Partner Area -->
<div class="partner-style-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner1.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner2.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner3.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner4.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner5.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="./assets/img/home-six/partner/partner6.png" alt="image"></a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Partner Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">
                        Data-Analyst Syllabus</h2>
                    <div class="bar"></div>
                    <p style="font-size: 20px;color:black;font-weight: bold;">
                        <img src="./assets/img/course/python.png" alt="image">
                        Data-Analyst
                    </p>
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> What is Python?</span></li>
                        <li><span><i class="flaticon-check-mark"></i> A Brief history of Python</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Why Should I learn Python? </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Installing Python </span></li>
                        <li><span><i class="flaticon-check-mark"></i>How to execute Python program</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Write your first program</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Variables</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Numbers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> String </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Lists, Tuples & Dictionary </span></li>
                        <li><span><i class="flaticon-check-mark"></i> if...statement </span></li>
                        <li><span><i class="flaticon-check-mark"></i> if...else statement </span></li>
                        <li><span><i class="flaticon-check-mark"></i> elif...statement </span></li>
                        <li><span><i class="flaticon-check-mark"></i> The while...Loop </span></li>
                        <li><span><i class="flaticon-check-mark"></i> The for.... Loop </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Continue statement </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Break statement</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Pass statement </span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h3 style="color:black">
                        <!-- <img src="./assets/img/course/api.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image" style="height:100px;"> -->
                        <span style="font-family: Raleway, sans-serif; font-weight: 700;"> Functions & Classes & Objects
                        </span>
                    </h3>
                    <div class="bar"></div>
                    <!-- <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.</p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Define function</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Calling a function</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Function arguments </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Built-in functions</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Lambda Functions</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Lambda with Map, Reduce & Filter</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Introduction about classes & objects</span></li>
                        <li> <span><i class="flaticon-check-mark"></i> Creating a class & object</span></li>
                        <li> <span><i class="flaticon-check-mark"></i> Inheritance</span></li>
                        <li> <span><i class="flaticon-check-mark"></i> Methods Overriding</span></li>
                        <li> <span><i class="flaticon-check-mark"></i> Data hiding</span></li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->

<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Getting Started with Python
                        Libraries </h2>
                    <div class="bar"></div>
                    <!-- <p style="font-size: 20px;color:black;font-weight: bold;">
                        <img src="./assets/img/course/django.png" alt="image">
                        Django
                    </p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> what is data analysis? </span></li>
                        <li><span><i class="flaticon-check-mark"></i> why python for data analysis? </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Essential Python Libraries </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Installation and setup</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Ipython </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Jupyter Notebook</span></li>

                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->
<!-- Start Services Area -->
<!-- < <div class="services-area ptb-70" >
    <div class=" p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content " >
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">PHASE-3 -> AWS & DevOps TRAINING</h2>
                    <div class="bar"></div>
                    <h3><img src="./assets/img/course/course-content-icon.png"  alt=""  title="Course content"> Course Content </h3>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> MAVEN</span></li>
                        <li><span><i class="flaticon-check-mark"></i> JENKINS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Docker</span></li>
                        <li><span><i class="flaticon-check-mark"></i> EC2 Instance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> AWS Cloud Watch</span></li>
                        <li><span><i class="flaticon-check-mark"></i> AWS Secret Manager </span></li>
                        <li><span><i class="flaticon-check-mark"></i> RDS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> SQS/SNS</span></li>
                        <li><span><i class="flaticon-check-mark"></i> API Gateway </span></li>
                        <li><span><i class="flaticon-check-mark"></i> AWS Lambda</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Auto Scalling</span></li>
                        <li><span><i class="flaticon-check-mark"></i> S3 Bucket</span></li>
                        <li><span><i class="flaticon-check-mark"></i> JUNIT 5.x</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Mockito</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/mocj.jpg" alt="image" height="600px">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>  -->


<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">NumPy Arrays </h2>
                    <div class="bar"></div>
                    <!-- <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.</p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Creating multidimensional array </span></li>
                        <li><span><i class="flaticon-check-mark"></i> NumPy-Data types </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Array attributes</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Indexing and Slicing </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Creating array views and copies</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Manipulating array shapes</span></li>
                        <li><span><i class="flaticon-check-mark"></i> I/O with NumPy</span></li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Working with panadas </h2>
                    <div class="bar"></div>
                    <!-- <p style="font-size: 20px;color:black;font-weight: bold;">
                        <img src="./assets/img/course/shopping.png" alt="image">
                        Designing a system design for ecommerce
                    </p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Installing pandas </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Pandas Data Frame</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Pandas Series</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Data aggregation with Pandas Data Frame </span>
                        </li>
                        <li><span><i class="flaticon-check-mark"></i> Concatenating and appending Data Frame </span>
                        </li>
                        <li><span><i class="flaticon-check-mark"></i> Joining Data Frame </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Handling missing data</span></li>

                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Data Loading, Storage and File
                        Format</h2>
                    <div class="bar"></div>
                    <!-- <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.</p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Writing CSV files with NumPy and pandas </span>
                        </li>
                        <li><span><i class="flaticon-check-mark"></i> HDF5 format </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Reading and writing to Excel with pandas</span>
                        </li>
                        <li><span><i class="flaticon-check-mark"></i> JSON data </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Parsing HTML with Beautiful Soup</span></li>
                        <li><span><i class="flaticon-check-mark"></i> PyTables</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Statistics and Linear Algebra
                    </h2>
                    <div class="bar"></div>
                    <!-- <p style="font-size: 20px;color:black;font-weight: bold;">
                        <img src="./assets/img/course/shopping.png" alt="image">
                        Designing a system design for ecommerce
                    </p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Basic statistics with NumPy </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Linear Algebra with NumPy</span></li>
                        <li><span><i class="flaticon-check-mark"></i> NumPy random numbers</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Creating a NumPy masked array </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/3.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="services-area ptb-70 bg-f7fafd">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/2.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="overview-content">
                <div class="content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Data Visualization </h2>
                    <div class="bar"></div>
                    <!-- <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua, lorem ipsum dolor sit amet,
                        consectetur adipiscing elit.</p> -->
                    <h3><img src="./assets/img/course/course-content-icon.png" alt="" title="Course content"> Course
                        Content </h3>
                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Installation matplotlib </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Basic matplotlib plots
                            </span></li>
                        <li><span><i class="flaticon-check-mark"></i> Scatter plots</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Saving plots to file </span></li>
                        <li><span><i class="flaticon-check-mark"></i> plotting functions in pandas</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Start Invoicing Area -->
<!-- <div class="invoicing-area ptb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-content">
                    <h2 style="font-family: Raleway, sans-serif; font-weight: 400;">Phase-4 Real Hands on with Ecommerce websites </h2>
                    <div class="bar"></div>
                    <p style="font-size:20px;color:black">
                        Designing a system design for ecommerce
                    </p>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">GITHUB</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">BITBUCKET</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">JIRA</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Confluence</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">intelliJ</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Eclipse</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">MAVEN</a>
                    <a routerLink="/" class="btn btn-primary" style="margin-right: 10px;">Gradle</a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="invoicing-image">
                    <div class="main-image">
                        <img src="assets/img/Project-Management-Journey.jpg" height="400px"
                            class="wow animate__animated animate__zoomIn" data-wow-delay="0.7s" alt="image">
                        <img src="assets/img/invoicing/invoicing-shape2.png"
                            class="wow animate__animated animate__fadeInLeft" data-wow-delay="1s" alt="image">
                        <img src="assets/img/invoicing/invoicing-shape3.png"
                            class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image">
                        <img src="assets/img/invoicing/invoicing-shape4.png"
                            class="wow animate__animated animate__fadeInRight" data-wow-delay="1s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/sw-development-teams.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/invoicing-image/circle1.png" alt="image">
                        <img src="assets/img/invoicing-image/circle2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Invoicing Area -->

<app-talk-button></app-talk-button>

<!-- <app-feedback></app-feedback>

<app-download></app-download> -->
