<!-- Start Page Title Area -->
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
            <p>Since last many years, {{title}} has been a leader in the bootcamp industry, providing market-driven
                education to help people change their lives.</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start About Area -->
<div class="about-area ptb-50">
    <div class="container">
        <div class="dFlex">
            <div class="heading">
                <div>
                    <span> Our Mission:</span>
                </div>
                <p>Empowering tomorrow’s workforce through education</p>
            </div>
            <div class="paragraph">
                <p> As a leader in the tech industry, {{title}} believes that education is the best investment you can
                    make in your future. We’re committed to helping change lives and the world for the better.</p>
                <p>Our courses provide an immersive, outcomes-driven curriculum for students looking to launch a career
                    in Software Engineering, Data Science, or Product Design. Courses are offered online only </p>
                <p>{{title}} is frequently ranked as a top coding bootcamp by industry publications. In addition to
                    student programs, we also offer targeted education solutions for organizations.</p>
            </div>
        </div>

    </div>
</div>


<div class="container ptb-50">
    <div class="section-title">
        <h2> Our Values</h2>
        <div class="bar"></div>
    </div>

    <div class="box-container">
        <div class="box">
            <h3>Make No Little Plans</h3>
            <span>Build for scale, create for the future, execute short-term goals in view of long-term strategy</span>
        </div>

        <div class="box-color">
            <h3>Radiate Positivity</h3>
            <span>Be kind, have fun, find what to love</span>
        </div>

        <div class="box">
            <h3>Strive for Excellence</h3>
            <span>Drive results, hold yourself and each other accountable to our shared goals</span>
        </div>

        <div class="box-color">
            <h3>Be A Lifelong Learner</h3>
            <span>Embrace a growth mindset, be curious, get comfortable with being uncomfortable</span>
        </div>

        <div class="box">
            <h3>Work Together</h3>
            <span>Collaborate, embrace transparency, empower others to succeed</span>
        </div>

        <div class="box-color">
            <h3>Nurture Difference</h3>
            <span>Seek to understand, notice who or what is missing, actively seek out different opinions, create space
                for other voices to be heard</span>
        </div>
    </div>
</div>





<!-- <div class="about-area ptb-50">
    <div class="container" style="text-align: justify;">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">

                    <span style="text-align: center;">About us</span>
                    <p><span>The {{title}} history and background:</span> This could include when the company was
                        founded, the founders' vision and mission, and any milestones or achievements the
                        company has achieved.
                    </p>
                    <label>Services:</label>
                    <p> Describe the types of IT services that the company offers, such as
                        consulting, development, support, and maintenance.
                    </p>
                    <label>Expertise:</label>
                    <p> Highlight the company's areas of expertise, such as specific
                        technologies, industries, or business processes.
                    </p>
                    <label>Values:</label>
                    <p> Outline the company's core values and how they guide the way
                        the company operates and serves its clients.
                    </p>
                    <label>Clients and partners:</label>
                    <p> Mention any notable clients or partners that the
                        company has worked with, and describe the results the company has achieved for them.
                    </p>
                    <label>Team:</label>
                    <p> Introduce the team members and highlight their skills and
                        experience.
                    </p>
                    <label>Culture:</label>
                    <p> Describe the company's culture and working environment, and
                        what sets it apart from other IT companies or consultancies.
                    </p>
                    <p>We handle the sourcing, screening, paperwork, initial interviews and
                        background screening services and present you with only well qualified candidates.
                    </p>
                    <p>Overall, the 'About {{title}}" section should provide a comprehensive overview of the company
                        and give potential clients a sense of what they can expect when working with the company.
                    </p>



                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img1.jpg" alt="image">
                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i
                            class="fas fa-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- End About Area -->

<div class="team-area ptb-50 bg-f7fafd mb-5" *ngIf="technoTeam">
    <div class="container">
        <div class="section-title">
            <h2>Leadership team</h2>
            <div class="bar"></div>
            <p>We have dedicated teams for each of our enterprise clients and ensure to align with your strategic
                initiatives.</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image-circle">
                        <img src="assets/img/team/nag.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3>Nagendra Kumar Yadav</h3>
                        <span>CEO & Founder</span>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image-circle">
                        <img src="assets/img/team/jitendra.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3>Jitendra Yadav</h3>
                        <span>CTO & Java Developer</span>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image-circle">
                        <img src="assets/img/team/adityakumar.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3>Aditya Kumar</h3>
                        <span>Full Stack Developer</span>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image-circle">
                        <img src="assets/img/team/amans.png" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3>Aman Singh</h3>
                        <span>Java Developer</span>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image-circle">
                        <img src="assets/img/team/team-avatar.webp" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3>Himanshu Sharma</h3>
                        <span>Java Developer</span>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image-circle">
                        <img src="assets/img/team/rahul.jpeg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3>Rahul Sinha</h3>
                        <span>Developer</span>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley.</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Start Team Area -->
<div class="team-area ptb-50 bg-f7fafd mb-5" *ngIf="pyKubeTeam">
    <div class="container">
        <div class="section-title">
            <h2>Meet our team</h2>
            <div class="bar"></div>
            <p>We have dedicated teams for each of our enterprise clients and ensure to align with your strategic
                initiatives.</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image-circle">
                        <img src="assets/img/team/team-avatar.webp" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3>Krishna Kumar</h3>
                        <span>CEO & Founder</span>
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image-circle">
                        <img src="assets\img\Our-Team\IMG_0137 (1).JPG" alt="image">
                        
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3> Aman Yadav</h3>
                        <span>Managing Director</span>
                        <a href="https://www.linkedin.com/in/-conecthere/" class="fa fa-linkedin"></a>

                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image-circle">
                        <img src="assets\img\Our-Team\harsh.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3>Harsh Tomar</h3>
                        <span>Technical Marketing Lead</span>
                       
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image-circle">
                        <img src="assets\img\Our-Team\vishal.jpeg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3>Vishal Chaudhary</h3>
                        <span>Data-Tech Lead </span>
                        <a href="https://www.linkedin.com/in/vishal-chaudhary-38402a240/" class="fa fa-linkedin"></a>

                        
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley.</p> -->
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3>Himanshu Sharma</h3>
                        <span>Java Developer</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/img/team/jitendra.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                    <div class="member-content">
                        <h3>Jitendra Yadav</h3>
                        <span>Developer</span>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley.</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- End Team Area -->

<!-- Start Ready To Talk Area -->
<!-- <div class="ready-to-talk">
    <div class="container">
        <div class="ready-to-talk-content">
            <h3>Ready to talk?</h3>
            <p>Our team is here to answer your question about TechnoHunk</p>
            <a routerLink="/" class="btn btn-primary">Contact Us</a>
            <span><a routerLink="/">Or, get started now with a free trial</a></span>
        </div>
    </div>
</div> -->
<!-- End Ready To Talk Area -->

<!-- <app-partner></app-partner> -->

<!-- Start App Download Area -->
<!-- <div class="app-download-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="app-image">
                    <div class="main-image">
                        <img src="assets/img/mobile-app1.png" class="wow animate__animated animate__fadeInLeft"
                            data-wow-delay="0.6s" alt="image">
                        <img src="assets/img/mobile-app2.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.9s" alt="image">
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/main-mobile.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="0.6s" alt="image">
                    </div>
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="app-download-content">
                    <h2>You can find all the thing you need to payout</h2>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip.</p>
                    <div class="btn-box">
                        <a href="#" class="app-store-btn" target="_blank">
                            <i class="flaticon-apple"></i>
                            Download on
                            <span>App Store</span>
                        </a>
                        <a href="#" class="play-store-btn" target="_blank">
                            <i class="flaticon-play-store"></i>
                            Download on
                            <span>Google play</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End App Download Area -->
<!-- <app-download></app-download> -->
<!-- Start Account Create Area -->
<!-- <div class="account-create-area">
    <div class="container">
        <div class="account-create-content">
            <h2>Apply for an account in minutes</h2>
            <p>Get your Klob account today!</p>
            <a routerLink="/" class="btn btn-primary">Get Your Klob Account</a>
        </div>
    </div>
</div> -->
<!-- End Account Create Area -->

<!-- <app-account-button></app-account-button> -->