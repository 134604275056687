import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../Interfaces/domain';
import { CmApiService } from './cm-api.service';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService extends CmApiService{

    private _isLoggedIn = new BehaviorSubject<boolean>(false);
  constructor(http: HttpClient) {
    super(http);
  }


  get isLoggedIn() {
    return this._isLoggedIn.asObservable();
}
  public userLogin(user:User){
    //return this.doPost('/user/login',{loginid: username, password: password});
    return this.doPost('/user/login',user);
  }

  login_user(form: string) {
    // Call this._isLoggedIn.next(true) or this._isLoggedIn.next(false) depending on the result
    this._isLoggedIn.next(true)
}
}
