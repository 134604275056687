import { WebsiteService } from './../shared/websiteservice.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {DOCUMENT, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { SharedData } from '../shared/shareddata';
import { WebsiteAppConstants } from '../shared/app.constant';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class HeaderComponent implements OnInit {
    location: any;
    layoutClass: string;
    clogo:string;

    constructor(
        private router: Router,
        location: Location,private websiteService:WebsiteService,
        @Inject(DOCUMENT) private document: any,
    ) {
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                this.location = location.path();
                if(this.location == '/demo-3' || this.location == '/demo-6' || this.location==''){
                    this.layoutClass = 'navbar-style-two';
                } else {
                    this.layoutClass = '';
                }
            }
        });
    }

    ngOnInit() {
        let hostname = this.document.location.hostname;
        console.log("HOSTNAME = " + hostname);
        let sharedData: SharedData = new SharedData();
        sharedData.year = WebsiteAppConstants.Year;
        if (hostname.indexOf("pykube.com") > -1 || hostname.indexOf("pykube.in") > -1 || hostname.indexOf("pykube.net") > -1 || hostname.indexOf("localhost") > -1) {
                  // this.softwareSection = false;
            sharedData.email = WebsiteAppConstants.PyEmail;
            sharedData.phone = WebsiteAppConstants.PyPhone;
            sharedData.logo = WebsiteAppConstants.PykubeLogo;
            sharedData.address = WebsiteAppConstants.PyAddress;
            sharedData.title = WebsiteAppConstants.PyTitle;
            sharedData.faviconTitle = WebsiteAppConstants.PyFaviconTitle;
            sharedData.faviconLogo = WebsiteAppConstants.PyFaviconLogo;
            this.websiteService.publishData(sharedData);
        } else {
            // this.softwareSection = true;
            sharedData.email = WebsiteAppConstants.TechnoEmail;
            sharedData.phone = WebsiteAppConstants.TechnoPhone;
            sharedData.logo = WebsiteAppConstants.TechnoHunkLogo;
            sharedData.address = WebsiteAppConstants.TechnoAddress;
            sharedData.title = WebsiteAppConstants.TechnoTitle;
            sharedData.faviconTitle = WebsiteAppConstants.TechnoFaviconTitle;
            sharedData.faviconLogo = WebsiteAppConstants.TechnoFaviconLogo;
            this.websiteService.publishData(sharedData);
        }
        // this.title = sharedData.title;

        this.websiteService.getShareData().subscribe((sharedData : SharedData)=>{
            this.clogo=sharedData.logo;

         });

    }

}
