import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CmApiService {
  static doPost(arg0: string, consultantsVO: any) {
    throw new Error('Method not implemented.');
  }

  // tslint:disable-next-line:member-ordering
  // tslint:disable-next-line:member-ordering
  API_URL = environment.apiUrls;


  constructor(private http: HttpClient) { }
  public getHttpParams(params: Array<{ name: string; value: any; }>): HttpParams {
    let httpParams = new HttpParams();
    params.forEach(param => {
      httpParams = httpParams.append(param.name, param.value);

    });
    return httpParams;
  }

  doGet(urlFragment: string, params: Array<{ name: string; value: any; }> = []) {
    const httpParams = this.getHttpParams(params);
    return this.http.get(this.API_URL + urlFragment, { params: httpParams });
  }
  doPost(urlFragment: string, data: any = {}, params: Array<{ name: string; value: any; }> = []) {
    const httpParams = this.getHttpParams(params);
    return this.http.post(this.API_URL + urlFragment, data, { params: httpParams });
  }
    doDelete(urlFragment: string, data: any = {}, params: Array<{ name: string; value: any; }> = []): Observable<any> {
        const httpParams = this.getHttpParams(params);
        const cheatObj: any = { body: data, params: httpParams };
        return this.http.delete(this.API_URL + urlFragment, cheatObj);
      }
      doPut(urlFragment: string, data: any = {}, params: Array<{ name: string; value: any; }> = []) {
        const httpParams = this.getHttpParams(params);
        return this.http.put(this.API_URL + urlFragment, data, { params: httpParams });
      }
      doPatch(urlFragment: string, data: any = {}, params: Array<{ name: string; value: any; }> = []) {
        const httpParams = this.getHttpParams(params);
        return this.http.patch(this.API_URL + urlFragment, data, { params: httpParams });
      }

}

