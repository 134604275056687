<!-- Start Main Banner Woman Area -->
<div class="main-banner-woman-area">
    <div class="container">
        <ul class="banner-woman-list">
            <li>
                <a routerLink="signup"><i class="fa-solid fa-user"></i>Register</a>
            </li>
            <li>
                <a href="" data-bs-toggle="modal" data-bs-target="#exampleModal" #java> <i
                        class="fa-solid fa-certificate"></i>
                    Quick Test</a>
            </li>
        </ul>
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="main-banner-woman-content">
                    <h1>Achieve Your Career Goals</h1>
                    <p>
                        Learn today’s most in-demand digital skills and kickstart your new career</p>
                    <ul class="btn-list">
                        <li>
                            <a routerLink="/contact" class="btn btn-primary">Get Started</a>
                        </li>
                        <li>
                            <a routerLink="/contact" class="discover-more-btn">Discover More <i
                                    class="fa-solid fa-chevron-right"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="main-banner-woman-image">
                    <img src="assets/img/home-six/banner/banner-woman.png" alt="image">
                    <div class="woman-shape">
                        <img src="assets/img/home-six/banner/woman-shape-1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="banner-woman-shape">
        <img src="assets/img/home-six/banner/woman-shape-2.png" alt="image">
    </div>
</div>
<!-- End Main Banner Woman Area -->

<!-- Start Partner Area -->
<div class="partner-style-area pt-100 pb-50">
    <div class="section-title">
        <!-- <span ><b>Our Popular Cources</b></span> -->
        <h2>Our Clients</h2>
        <div class="bar"></div>
    </div>
    <div class="container">
        <marquee scrollamount="20" behavior="scroll"  >
        <div class="row align-items-center">
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="assets/img/home-six/partner/partner1.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="assets/img/home-six/partner/partner2.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="assets/img/home-six/partner/partner3.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="assets/img/home-six/partner/partner4.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="assets/img/home-six/partner/partner5.png" alt="image"></a>
                </div>
            </div>
            <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                <div class="single-partner-card">
                    <a href="#" target="_blank"><img src="assets/img/home-six/partner/partner6.png" alt="image"></a>
                </div>
            </div>
        </div>
    </marquee>
    </div>

</div>
<!-- End Partner Area -->

<div class="blog-wrap-area pt-100" *ngIf="softwareSection">
    <div class="container">
        <div class="section-title">
            <h2>Best Services for Your Business</h2>
            <div class="bar"></div>
            <p>We handle the sourcing, screening, paperwork, initial interviews and background screening services and
                present you with only well qualified candidates.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/interview-preparation" class="d-block">
                            <img src="assets/img/services/job-interview.webp" alt="image">
                        </a>
                        <!-- <span class="date">October 30, 2022</span> -->
                    </div>
                    <div class="content">
                        <h3><a routerLink="/interview-preparation">Interview Preparation for job</a></h3>
                        <p>How to Prepare for a Job Interview So You’ll Convince Them You’re the Right Hire. Here
                            you can work on in your practice interviews.</p>
                        <a routerLink="/interview-preparation" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/sale-marketing" class="d-block">
                            <img src="assets/img/services/sales-markrting.jpg" alt="image">
                        </a>
                        <!-- <span class="date">October 30, 2022</span> -->
                    </div>
                    <div class="content">
                        <h3><a routerLink="/sale-marketing">Sales and Marketing </a></h3>
                        <p>Sales and marketing are two business functions within an organization -- they both impact
                            lead generation and revenue. </p>
                        <a routerLink="/sale-marketing" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/job-assistance" class="d-block">
                            <img src="assets/img/services/corporate-business.webp" alt="image">
                        </a>
                        <!-- <span class="date">October 30, 2022</span> -->
                    </div>
                    <div class="content">
                        <h3><a routerLink="/job-assistance">Assistance for getting job</a></h3>
                        <p>100% Job Assistance. Assessing the Various Job Options Open to You. Get Professional,
                            Personalized Job Guidance.</p>
                        <a routerLink="/job-assistance" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/software-developer" class="d-block">
                            <img src="https://img.freepik.com/free-photo/african-american-engineer-programming-app-code-developing-online-software-with-cloud-server-firewall-security-coding-new-user-interface-with-binary-code-html-program-web-development_482257-49867.jpg?w=1380&t=st=1671650155~exp=1671650755~hmac=280bc3dd399c93eefaae1e6ae3084b5893062adfdf965686c586d2793d30c959"
                                alt="image">
                        </a>
                        <!-- <span class="date">October 30, 2022</span> -->
                    </div>
                    <div class="content">
                        <h3><a routerLink="/software-developer"> Software Development</a></h3>
                        <p>We offer custom software development services specifically designed to meet business goals at
                            reduced costs.</p>
                        <a routerLink="/software-developer" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/ui-ux-design" class="d-block">
                            <img src="https://img.freepik.com/free-vector/gradient-ui-ux-background_23-2149065782.jpg?w=1380&t=st=1671650062~exp=1671650662~hmac=b41098c0b1fa9329c1107eb956f7c8ad257329a6842d55d87ddddefe1877c025"
                                alt="image">
                        </a>
                        <!-- <span class="date">October 30, 2022</span> -->
                    </div>
                    <div class="content">
                        <h3><a routerLink="/ui-ux-design">UX/UI Design & Ideation</a></h3>
                        <p>Engross more customers by leveraging a well-defined design thinking approach and UI/UX
                            strategies helpful...</p>
                        <a routerLink="/ui-ux-design" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/web-development" class="d-block">
                            <img src="https://img.freepik.com/free-photo/programmers-questioning-source-cyber-attacks-system-security-coverage-software-technician-triangulates-hacker-location-by-updating-safety-script-codes-data-processor-sequencer_482257-40508.jpg?w=1380&t=st=1671650232~exp=1671650832~hmac=548c5d42235d47e1c72e4e04d78d27fce90bea82819bb29d97b252b90e916b1f"
                                alt="image">
                        </a>
                        <!-- <span class="date">October 30, 2022</span> -->
                    </div>
                    <div class="content">
                        <h3><a routerLink="/web-development">Web Development</a></h3>
                        <p>We offer a variety of web-based software development services from creating mobile web
                            development solutions....</p>
                        <a routerLink="/web-development" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Start Blog Wrap Area -->
<div class="blog-wrap-area pt-100 pb-75">
    <div class="container">
        <div class="section-title">
            <!-- <span ><b>Our Popular Cources</b></span> -->
            <h2>Our Popular Courses</h2>
            <div class="bar"></div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/java-syllabus-details" class="d-block">
                            <img src="assets/img/course/app-development.webp" alt="image">
                        </a>
                        <span class="date">October 30, 2023</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/java-syllabus-details">Java Full Stack Developer</a></h3>
                        <p>A full-stack Java developer is a professional who is skilled in end-to-end software
                            development. So, full-stack Java developers should have front-end development skills (HTML,
                            CSS, Javascript....</p>
                        <a routerLink="/java-syllabus-details" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/python" class="d-block">
                            <img src="assets/img/course/python-developer.webp" alt="image">
                        </a>
                        <span class="date">October 30, 2023</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/python">Python </a></h3>
                        <p>Python is by far, the most preferred programming language in Artificial Intelligence, Big
                            Data, Internet of Things, Robotics and Web Development.
                            By training you to become an Python Developer ...</p>
                        <a routerLink="/python" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/quality-assurance" class="d-block">
                            <img src="assets/img/course/qa-engg.jpg" alt="image">
                        </a>
                        <span class="date">October 30, 2023</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/quality-assurance">Quality Assurance (QA)</a></h3>
                        <p> Quality Assurance (QA) testing or software testing exists to confirm that the product being
                            built is the product that the client wants and is released with no bugs...</p>
                        <!-- <p> An SDET is someone who is involved in the project right from its planning stage and can help
                            automate the testing process.
                            Whereas, a QA Engineer is someone who has complete knowledge of various testing processes
                            and methodologies. </p> -->
                        <a routerLink="/quality-assurance" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/data-analyst" class="d-block">
                            <img src="assets/img/course/data-analytical.webp" alt="image">
                        </a>
                        <span class="date">October 30, 2022</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/data-analyst">Data Analyst</a></h3>
                        <p>A data analyst collects, cleans, and interprets data sets in order to answer a question or
                            solve a problem. They work in many industries, including business, finance, criminal
                            justice, science, medicine, and government...</p>
                        <a routerLink="/data-analyst" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/data-engineer" class="d-block">
                            <img src="assets/img/course/Data-Engineer.jpg" alt="image">
                        </a>
                        <span class="date">October 30, 2022</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/data-engineer">Data Engineer
                            </a></h3>
                        <p>A Data Engineer should be able to design, build, operationalize, secure, and monitor data
                            processing systems with a particular emphasis on security and compliance; scalability and
                            efficiency; reliability and fidelity; and flexibility and portability...</p>
                        <a routerLink="/data-engineer" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-card">
                    <div class="image">
                        <a routerLink="/data-scientist" class="d-block">
                            <img src="assets/img/course/data-science.jpg" alt="image">
                        </a>
                        <span class="date">October 30, 2022</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/data-scientist">Data Scientist</a></h3>
                        <p>Data scientists are big data wranglers, gathering and analyzing large sets of structured and
                            unstructured data. A data scientist’s role combines computer science, statistics, and
                            mathematics. They analyze, process, and model data...</p>
                        <a routerLink="/data-scientist" class="read-more-btn">Read more <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Wrap Area -->

<div class="blog-area ptb-70 pt-0">
    <div class="container">
        <div class="section-title">
            <h2>Ours Upcoming Bootcamp Courses</h2>
            <div class="bar"></div>

        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/java-syllabus-details">
                            <img src="assets/img/bootcamp/java-developer-bootcamp.webp" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> <span style="font-weight: 600;">Start Date 11-Jan-2023,
                                End Date 15-March-2023</span>
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3><a routerLink="/java-syllabus-details">Java Full Stack Developer</a></h3><br>
                        <!-- <span>by <a routerLink="/">admin</a></span> -->
                        <p>A full-stack Java developer is a professional who is skilled in end-to-end software
                            development. So, full-stack Java developers should have front-end development skills (HTML,
                            CSS, Javascript....</p>
                        <a routerLink="/java-syllabus-details" class="read-more-btn">View Details <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/python">
                            <img src="assets/img/bootcamp/python-bootcamp.webp" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> <span style="font-weight: 600;">Start Date 11-Jan-2023,
                                End Date 15-March-2023</span>
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3><a routerLink="/python">Python</a></h3><br>
                        <!-- <span>by <a routerLink="/">admin</a></span> -->
                        <p>Python is by far, the most preferred programming language in Artificial Intelligence, Big
                            Data, Internet of Things, Robotics and Web Development. By training you to become an Python
                            Developer ...</p>
                        <a routerLink="/python" class="read-more-btn">View Details <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/quality-assurance">
                            <img src="assets/img/bootcamp/qa-bootcamp.webp" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> <span style="font-weight: 600;">Start Date 11-Jan-2023,
                                End Date 15-March-2023</span>
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3><a routerLink="/quality-assurance">Quality Assurance</a></h3><br>
                        <!-- <span>by <a routerLink="/">admin</a></span> -->
                        <p>The world is becoming more digitalized by the passing day. You use software and applications
                            daily, from smart phone to laptop. Who is responsible for ensuring they work
                            properly? That’s where QA specialists come in...</p>
                        <a routerLink="/quality-assurance" class="read-more-btn">View Details <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="how-it-works-area ptb-70">
    <div class="container">
        <div class="section-title">
            <h2>How {{title}} Works</h2>
            <div class="bar"></div>
            <p>We handle the sourcing, screening, paperwork, initial interviews and background screening services and
                present you with only well qualified candidates.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <a routerLink="/signup"> <img src="assets/img/how-it-works/how-it-work1.png" alt="image"></a>
                    <h3><a routerLink="/signup"> 1. Register </a> </h3>
                    <p>Get Registered for Free Demo Course or Online Classes</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <a routerLink="/get-call-from-executive">
                        <img src="assets/img/how-it-works/how-it-work3.png" alt="image"></a>
                    <h3><a routerLink="/get-call-from-executive">2. Get a Call From Our Executive </a> </h3>
                    <p>Registered User Will Get a Call From Our Team</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <a routerLink="/payment">
                        <img src="assets/img/how-it-works/how-it-work4.png" alt="image"></a>
                    <a routerLink="/payment">
                        <h3>3. Pay Fee for Training</h3>
                    </a>
                    <p>Please select your desired Course and pay the course fee</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <a routerLink="/interview-preparation">
                        <img src="assets/img/how-it-works/interview-preparation.png" alt="image"></a>
                    <a routerLink="/interview-preparation">
                        <h3>4. Interview Preparation for job</h3>
                    </a>
                    <p>How to Prepare for a Job Interview So You’ll Convince Them You’re the Right Hire</p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <a routerLink="/sale-marketing"> <img src="assets/img/how-it-works/sales-pipeline.png"
                            alt="image"></a>
                    <a routerLink="/sale-marketing">
                        <h3>5. Sales and Marketing</h3>
                    </a>
                    <p>Sales and marketing are two business functions within an organization -- they both impact lead
                        generation and revenue. </p>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-how-it-works">
                    <a routerLink="/job-assistance"> <img src="assets/img/how-it-works/assistance.png" alt="image"></a>
                    <a routerLink="/job-assistance">
                        <h3>6. Assistance for getting job</h3>
                    </a>
                    <p>100% Job Assistance · Assessing the Various Job Options Open to You · Get Professional,
                        Personalized Job Guidance</p>
                </div>
            </div>


        </div>
    </div>
</div>

<!-- End Features Area -->


<!-- Start Services Area -->
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2>WHY YOU’LL BE HAPPY WITH {{title}}?</h2>
                    <div class="bar"></div>

                    <ul class="services-list">
                        <li><span><i class="flaticon-check-mark"></i> Expert Trainer</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Clear communication</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Interview Preparation</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Meeting expectations</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Job Assistance</span></li>
                        <li><span><i class="flaticon-check-mark"></i> 100% Placement</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Personalized support</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Expert Team</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Timely response</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Building a relationship</span></li>
                        <li><span style="width: max-content;"><i class="flaticon-check-mark"></i> Opportunities for
                                practice and application</span></li>
                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Services Area -->


<!-- Start Blog Area -->
<div class="blog-area ptb-70 pt-0">
    <div class="container">
        <div class="section-title">
            <h2> Our Blog</h2>
            <div class="bar"></div>

        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-Details1">
                            <img src="assets/img/blog-image/young-attractive-smiling-student.webp" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 15, 2020
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3 style="margin-bottom: 10px;"><a routerLink="/blog-Details1">How To Get Into Tech</a></h3>
                        <!-- <span>by <a routerLink="/">admin</a></span> -->
                        <p>Tech is a great career choice if you want stability, good pay, and immense growth potential
                            in uncertain times. The industry is a fast-paced, ever-evolving field and you don’t need a
                            degree or years of experience to break.....</p>
                        <a routerLink="/blog-Details1" class="read-more-btn">Read More <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-Details2">
                            <img src="assets/img/blog-image/young-student-woman.webp" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> September 17, 2020
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3 style="margin-bottom: 10px;"><a routerLink="/blog-Details2">9 of the Best UI Design
                                Portfolios</a></h3>
                        <!-- <span>by <a routerLink="/">admin</a></span> -->
                        <p>In the eyes of future employers and clients, a UI designer is only as good as their
                            portfolio. But what does an impressive UI design portfolio actually look like—and how can
                            you take your portfolio to the next level?</p>
                        <a routerLink="/blog-Details2" class="read-more-btn">Read More <i
                                class="fa-solid fa-chevron-right"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-image">
                        <a routerLink="/blog-Details3">
                            <img src="assets/img/blog-image/programmer-coding-software.webp" alt="image">
                        </a>
                        <div class="date">
                            <i class="far fa-calendar-alt"></i> NOVEMBER 28, 2022
                        </div>
                    </div>
                    <div class="blog-post-content">
                        <h3 style="margin-bottom: 10px;"><a routerLink="/blog-Details3">10 Most Popular Programming
                                Lang</a></h3>
                        <!-- <span>by <a routerLink="/">admin</a></span> -->
                        <p> A quick Google search has led you to this article, and you can now join in the enlightening
                            debate and tell them interesting facts about “PHP”, all the while ignoring that urgent text
                            message from your boss asking....</p>
                        <a routerLink="/blog-Details3" class="read-more-btn">Read More <i
                                class="fa-solid fa-chevron-right"></i></a>
                        <!-- <a href="#course">Course</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Area -->


<div class="testimonial-container container">
    <div class="section-title">
        <h2>What Our Clients Say?</h2>
        <div class="bar"></div>
    </div>
    <div class="testimonial-card">
      <button class="nav-button" (click)="prevTestimonial()">&lt;</button>
      <div class="testimonial-content">
        <p class="review-text">"{{ testimonials[currentIndex].quote }}"</p>
        <p class="client-name">{{ testimonials[currentIndex].client }}</p>
      </div>
      <button class="nav-button" (click)="nextTestimonial()">&gt;</button>
    </div>
  </div>
<!-- Start Account Create Area -->
<!-- <app-account-button></app-account-button> -->
<!-- End Account Create Area -->


<!-- Pop up Modal Form -->
<!-- <div *ngIf="showForm">
    <div class="modal fade" id="modalRegisterForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header text-center">
                    <h4 class="modal-title w-100 font-weight-bold">Sign up</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-3">
                    <div class="md-form mb-5">
                        <i class="fas fa-user prefix grey-text"></i>
                        <input type="text" id="orangeForm-name" class="form-control validate">
                        <label data-error="wrong" data-success="right" for="orangeForm-name">Your name</label>
                    </div>
                    <div class="md-form mb-5">
                        <i class="fas fa-envelope prefix grey-text"></i>
                        <input type="email" id="orangeForm-email" class="form-control validate">
                        <label data-error="wrong" data-success="right" for="orangeForm-email">Your email</label>
                    </div>

                    <div class="md-form mb-4">
                        <i class="fas fa-lock prefix grey-text"></i>
                        <input type="password" id="orangeForm-pass" class="form-control validate">
                        <label data-error="wrong" data-success="right" for="orangeForm-pass">Your password</label>
                    </div>

                </div>
                <div class="modal-footer d-flex justify-content-center">
                    <button class="btn btn-primary">Sign up</button>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- Modal section -->

<!-- Button trigger modal -->
<!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
    Launch demo modal
</button> -->

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Test your knowledge</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form [formGroup]="testForm">
                <div class="modal-body row g-3">
                    <div class="mb-3">
                        <i class="fas fa-user prefix grey-text"></i>&nbsp;
                        <label for="formGroupExampleInput" class="form-label sm">Name</label>
                        <input type="text" class="form-control" id="formGroupExampleInput" [(ngModel)]="name"
                            placeholder="Enter your name" formControlName="uname">
                        <div *ngIf="uname.touched && uname.errors?.required">
                            <small class="ValdaText">Name is Required </small>
                        </div>
                    </div>
                    <div class="mb-3">
                        <i class="fas fa-envelope prefix grey-text"></i>&nbsp;
                        <label for="formGroupExampleInput2" class="form-label">Email</label>
                        <input type="email" class="form-control" id="formGroupExampleInput2" [(ngModel)]="email"
                            placeholder="Enter your email" formControlName="uemail">
                        <div *ngIf="uemail.touched   && uemail.invalid">
                            <small class="ValdaText"> Enter Valid email address </small>
                        </div>
                    </div>
                    <div class="mb-3">
                        <i class="fas fa-phone prefix grey-text"></i>&nbsp;
                        <label for="formGroupExampleInput2" class="form-label">Mobile No</label>
                        <input type="phone" class="form-control" id="formGroupExampleInput2" [(ngModel)]="phone"
                            placeholder="Enter your mobile no" required formControlName="mobile">
                        <div *ngIf="mobile.errors?.pattern && mobile.touched">
                            <small class="ValdaText"> Enter valid mobile number </small>
                        </div>
                    </div>
                    <div class="mb-3">

                        <label for="formGroupExampleInput2" class="form-label">Technology</label>
                        <select #teams (change)="onSelected()">
                            <option selected>Select a technology</option>
                            <option value="Core-Java"> Core-Java</option>
                            <option value="Python">Python</option>
                            <option value="Angular">Angular</option>
                        </select>
                    </div>

                    <!-- <ngx-recaptcha2 #captchaElem
                      [siteKey]="siteKey">
                    </ngx-recaptcha2> -->

                </div>
                <div class="modal-footer">
                    <button type="button" *ngIf="testForm.valid" class="btn btn-primary" (click)="assignedTest()">Start
                        test</button>
                </div>
            </form>
        </div>
    </div>
</div>