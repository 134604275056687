import { WebsiteService } from "./../../shared/websiteservice.service";
import { Component, OnInit } from "@angular/core";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { SharedData } from "src/app/shared/shareddata";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    address: string;
    phone: string;
    email: string;
    responseMessage: string = "";
    responseMessageDesign = false;
    contactForm: FormGroup;
    constructor(
        private websiteService: WebsiteService,
        private formBuilder: FormBuilder
    ) { }

    ngOnInit() {
        this.contactForm = this.formBuilder.group({
            name: new FormControl("", [Validators.required]),
            email: [ "",[Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
            phone_number: ["", [Validators.required, Validators.pattern("^([+-]{0,1}[ ]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[ ]{0,1}){0,4}$")]],
            msg_subject: new FormControl("", [Validators.required]),
            message: new FormControl("", [Validators.required]),
        });

        this.websiteService
            .getShareData()
            .subscribe((sharedData: SharedData) => {
                this.email = sharedData.email;
                this.phone = sharedData.phone;
                this.address = sharedData.address;
            });
    }

    handleSubmit() {
        if(this.contactForm.valid){
            var formData = this.contactForm.value;
            var data = {
                name: formData.name,
                email: formData.email,
                phone: formData.phone_number,
                subject: formData.msg_subject,
                message: formData.message,
            };
            this.contactForm.reset();
            this.websiteService.sendMessageRecord(data).subscribe((res: any) => {
                if (res) {
                    this.responseMessage = "Your message sent Successfully.";
                    this.responseMessageDesign = true;
                }
                setTimeout(() => (this.responseMessage = undefined), 3000);
                setTimeout(() => (this.responseMessageDesign = false), 3000);
            });
        }else{
            this.contactForm.markAllAsTouched();
        }
       
    }
}
