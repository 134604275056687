<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Blog</h2>
            <p>Our latest news</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<div class="blog-details-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details">
                    <div class="article-image">
                        <img src="https://img.freepik.com/free-photo/web-template-layout-draft-sketch_53876-125031.jpg?w=1380&t=st=1671050393~exp=1671050993~hmac=bcb6a0a2c67229b1515942c2010e08939a7e0ddb9d59cb656ba84431ce4da7df"
                            alt="image">
                    </div>
                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><span>Posted On:</span> <a routerLink="/">DECEMBER 6, 2022</a></li>
                                <!-- <li><span>Posted By:</span> <a routerLink="/">John Anderson</a></li> -->
                            </ul>
                        </div>
                        <h3>9 of the Best UI Design Portfolios That Will Inspire You</h3>
                        <p>In the eyes of future employers and clients, a UI designer is only as good as their
                            portfolio. But what does an impressive UI design portfolio actually look like—and how can
                            you take your portfolio to the next level?</p>

                        <p>Whether you’re a UI designer in the making who’s starting from scratch, or an experienced
                            designer looking to breathe new life into your existing portfolio, we’ve rounded up nine
                            gorgeous UI design portfolios that will serve as inspiration. As we go through them, we’ll
                            highlight some best practices and key takeaways that will help you to take your UI design
                            portfolio from “okay” to “eye-catching.”</p>

                        <p>Before we dive in, let’s touch on why UI design portfolios are so vital, and some general
                            notes on what they should include. Rather skip straight to the portfolios? No problem,
                            simply select one from the list below!</p>

                        <!-- <blockquote class="wp-block-quote">
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                            <cite>Tom Cruise</cite>
                        </blockquote> -->

                        <ul class="article-features-list">
                            <li>Sam Small</li>
                            <li>Jeremiah Shaw</li>
                            <li>Mengdi Zhang</li>
                            <li>Bady</li>
                            <li>Tom Parkes</li>
                            <li>Stefan Hiienurm</li>
                            <li>Bradley Haynes</li>
                            <li>Corey Snyder</li>
                            <li>Sinem Kurt </li>
                        </ul>

                        <!-- <ul class="wp-block-gallery columns-3">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog-image/blog1.jpg" alt="image">
                                </figure>
                            </li>
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog-image/blog2.jpg" alt="image">
                                </figure>
                            </li>
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog-image/blog3.jpg" alt="image">
                                </figure>
                            </li>
                        </ul> -->
                        <!-- <h3>10 Tactics for marketing your company</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry’s standard dummy text ever since the 1500s, when an unknown printer took.
                        </p> -->
                        <!-- <ul class="article-features-list">
                            <li>Devices can grind down to a halt.</li>
                            <li>Small companies Repairing business.</li>
                            <li>Repairs are competitive with anyone</li>
                            <li>Repair broken buttons, speakers</li>
                            <li>Repairs are competitive with anyone</li>
                        </ul> -->
                        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book. It has survived not only five
                            centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry’s standard dummy text ever since the 1500s, when an unknown printer took.
                        </p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book. It has survived not only five
                            centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p> -->
                    </div>
                    <!-- <div class="article-footer">
                        <div class="article-tags">
                            <span><i class="fas fa-bookmark"></i></span>
                            <a routerLink="/">Fashion</a>,
                            <a routerLink="/">Games</a>,
                            <a routerLink="/">Travel</a>
                        </div>
                        <div class="article-share">
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="comments-area">
                    <h3 class="comments-title">2 Comments:</h3>
                    <ol class="comment-list">
                        <li class="comment">
                            <article class="comment-body">
                                <footer class="comment-meta">
                                    <div class="comment-author vcard">
                                        <img src="assets/img/author1.jpg" class="avatar" alt="image">
                                        <b class="fn">James Anderson</b>
                                        <span class="says">says:</span>
                                    </div>
                                    <div class="comment-metadata">
                                        <a routerLink="/">
                                            <time>April 24, 2020 at 10:59 am</time>
                                        </a>
                                    </div>
                                </footer>
                                <div class="comment-content">
                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                                <div class="reply">
                                    <a routerLink="/" class="comment-reply-link">Reply</a>
                                </div>
                            </article>
                            <ol class="children">
                                <li class="comment">
                                    <article class="comment-body">
                                        <footer class="comment-meta">
                                            <div class="comment-author vcard">
                                                <img src="assets/img/author2.jpg" class="avatar" alt="image">
                                                <b class="fn">Steven Smith</b>
                                                <span class="says">says:</span>
                                            </div>
                                            <div class="comment-metadata">
                                                <a routerLink="/">
                                                    <time>April 24, 2020 at 10:59 am</time>
                                                </a>
                                            </div>
                                        </footer>
                                        <div class="comment-content">
                                            <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                        <div class="reply">
                                            <a routerLink="/" class="comment-reply-link">Reply</a>
                                        </div>
                                    </article>
                                </li>
                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/author3.jpg" class="avatar" alt="image">
                                                    <b class="fn">Sarah Taylor</b>
                                                    <span class="says">says:</span>
                                                </div>
                                                <div class="comment-metadata">
                                                    <a routerLink="/">
                                                        <time>April 24, 2020 at 10:59 am</time>
                                                    </a>
                                                </div>
                                            </footer>
                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                            </div>
                                            <div class="reply">
                                                <a routerLink="/" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>
                                </ol>
                            </ol>
                        </li>
                        <li class="comment">
                            <article class="comment-body">
                                <footer class="comment-meta">
                                    <div class="comment-author vcard">
                                        <img src="assets/img/author2.jpg" class="avatar" alt="image">
                                        <b class="fn">John Doe</b>
                                        <span class="says">says:</span>
                                    </div>
                                    <div class="comment-metadata">
                                        <a routerLink="/">
                                            <time>April 24, 2020 at 10:59 am</time>
                                        </a>
                                    </div>
                                </footer>
                                <div class="comment-content">
                                    <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                </div>
                                <div class="reply">
                                    <a routerLink="/" class="comment-reply-link">Reply</a>
                                </div>
                            </article>
                            <ol class="children">
                                <li class="comment">
                                    <article class="comment-body">
                                        <footer class="comment-meta">
                                            <div class="comment-author vcard">
                                                <img src="assets/img/author4.jpg" class="avatar" alt="image">
                                                <b class="fn">James Anderson</b>
                                                <span class="says">says:</span>
                                            </div>
                                            <div class="comment-metadata">
                                                <a routerLink="/">
                                                    <time>April 24, 2020 at 10:59 am</time>
                                                </a>
                                            </div>
                                        </footer>
                                        <div class="comment-content">
                                            <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                        </div>
                                        <div class="reply">
                                            <a routerLink="/" class="comment-reply-link">Reply</a>
                                        </div>
                                    </article>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <div class="comment-respond">
                        <h3 class="comment-reply-title">Leave a Reply</h3>
                        <form class="comment-form">
                            <p class="comment-notes">
                                <span id="email-notes">Your email address will not be published.</span>
                                Required fields are marked
                                <span class="required">*</span>
                            </p>
                            <p class="comment-form-comment">
                                <label>Comment</label>
                                <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525" required="required"></textarea>
                            </p>
                            <p class="comment-form-author">
                                <label>Name <span class="required">*</span></label>
                                <input type="text" id="author" name="author" required="required">
                            </p>
                            <p class="comment-form-email">
                                <label>Email <span class="required">*</span></label>
                                <input type="email" id="email" name="email" required="required">
                            </p>
                            <p class="comment-form-url">
                                <label>Website</label>
                                <input type="url" id="url" name="url">
                            </p>
                            <p class="comment-form-cookies-consent">
                                <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent">
                                <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                            </p>
                            <p class="form-submit">
                                <input type="submit" name="submit" id="submit" class="submit" value="Post Comment">
                            </p>
                        </form>
                    </div>
                </div> -->
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <div class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="fas fa-search"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_klob_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <article class="item">
                            <a routerLink="/blog-Details1" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2020-06-30">DECEMBER 14, 2022</time>
                                <h4 class="title usmall"><a routerLink="/blog-Details1">How To Get Into Tech (With
                                        Little to No Experience)</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-Details2" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2020-06-30">DECEMBER 6, 2022</time>
                                <h4 class="title usmall"><a routerLink="/blog-Details2">9 of the Best UI Design
                                        Portfolios That Will Inspire You</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/blog-Details3" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <time datetime="2020-06-30">NOVEMBER 28, 2022</time>
                                <h4 class="title usmall"><a routerLink="/blog-Details3">A Beginner’s Guide to the 10
                                        Most Popular Programming Languages</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_recent_entries">
                        <h3 class="widget-title">Recent Posts</h3>
                        <ul>
                            <li><a>How to Become a Successful Entry Level UX Designer</a>
                            </li>
                            <li><a>How to start your business as an entrepreneur</a></li>
                            <li><a>How to be a successful entrepreneur</a></li>
                            <li><a>10 Building Mobile Apps With Ionic And React</a></li>
                            <li><a>Protect your workplace from cyber attacks</a></li>
                        </ul>
                    </div>
                    <!-- <div class="widget widget_archive">
                        <h3 class="widget-title">Archives</h3>
                        <ul>
                            <li><a routerLink="/">May 2020</a></li>
                            <li><a routerLink="/">April 2020</a></li>
                            <li><a routerLink="/">June 2020</a></li>
                        </ul>
                    </div> -->
                    <!-- <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li><a routerLink="/">Business</a></li>
                            <li><a routerLink="/">Privacy</a></li>
                            <li><a routerLink="/">Technology</a></li>
                            <li><a routerLink="/">Tips</a></li>
                            <li><a routerLink="/">Uncategorized</a></li>
                        </ul>
                    </div> -->
                    <!-- <div class="widget widget_meta">
                        <h3 class="widget-title">Meta</h3>
                        <ul>
                            <li><a routerLink="/">Log in</a></li>
                            <li><a routerLink="/">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/">WordPress.org</a></li>
                        </ul>
                    </div> -->
                    <!-- <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/">IT <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Klob <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Games <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </div> -->
                </aside>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Details Area -->

<!-- <app-account-button></app-account-button> -->
