<!-- Start Login Area -->
<div class="login-area">
    <div class="row m-0">
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-image">
                <img src="assets/img/login-bg.jpg" alt="image" width="200px">
            </div>
        </div>
        <div class="col-lg-6 col-md-12 p-0">
            <div class="login-content">
                <div class="d-table">
                    <div class="col-lg-6 col-md-12 p-0">
                        <div class="login-form">
                            <div class="logo">
                                <a routerLink="/"><img src="assets/img/pykube logo1.png" alt="image"></a>
                            </div>
                            <div class="form">
                                <form action="">
                                    <table>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <h2 style="margin-left: 150px;">PAY HERE</h2>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text"> Name*</td>
                                            <td class="input"><input type="text" placeholder="Enter Your Name" required
                                                    autofocus>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text">Email*</td>
                                            <td><input type="text" placeholder="Enter Your Email" required autofocus>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text">Amount*</td>
                                            <td><input type="text" placeholder="Enter Amount to be paid" required
                                                    autofocus></td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td> <button class="btn1">Pay Now</button></td>
                                        </tr>
                                    </table>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login Area -->
