<!-- Preloader -->
<div class="preloader">
    <div class="loader">
        <div class="shadow"></div>
        <div class="box"></div>
    </div>
</div>
<!-- End Preloader -->

<app-header
    *ngIf="!(location == '/signup' || location == '/signin' || location == '/dashboard')">
</app-header>
<!-- <app-user-header></app-user-header> -->
<router-outlet></router-outlet>
<app-footer *ngIf="!(location == '/signup' || location == '/signin' )"></app-footer>