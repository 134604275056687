import { WebsiteService } from "./../../shared/websiteservice.service";
import { Component, Inject, OnInit } from "@angular/core";
import { SharedData } from "src/app/shared/shareddata";
import { DOCUMENT } from "@angular/common";

@Component({
    selector: "app-features",
    templateUrl: "./features.component.html",
    styleUrls: ["./features.component.scss"],
})
export class FeaturesComponent implements OnInit {
    title: string;
    technoSection: boolean;

    constructor(
        private websiteService: WebsiteService,
        @Inject(DOCUMENT) private document: any
    ) {}

    ngOnInit() {
        let hostname = this.document.location.hostname;
        console.log("HOSTNAME = " + hostname);
        if (hostname.indexOf("pykube.com") > -1) {
            this.technoSection = false;
        } else {
            this.technoSection = true;
        }
        this.websiteService
            .getShareData()
            .subscribe((sharedData: SharedData) => {
                this.title = sharedData.title;
            });
    }
}
