
<div class="container">
    <button type="button" class="btn" (click)="openPopup()">Submit</button>

    <div class="popup"  #popup>
        <img src="https://w7.pngwing.com/pngs/944/793/png-transparent-computer-icons-check-mark-presentation-symbol-check-list-miscellaneous-angle-text-thumbnail.png" alt="">
        <h2>Thank You!</h2>
        <p>Your dtails has been successfully submitted. Thanks!</p>
        <button type="button" (click)="closePopup()" >OK</button>
    </div>
</div>

