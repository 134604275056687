<!-- Start Account Create Area -->
<!-- <div class="account-create-area">
    <div class="container">
        <div class="account-create-content">
            <h2>Apply for an account in minutes</h2>
            <p>Get your Klob account today!</p>
            <a routerLink="/" class="btn btn-primary">Get Your Klob Account</a>
        </div>
    </div>
</div> -->
<!-- End Account Create Area -->

<!-- Start Subscribe Wrap Area -->
<div class="subscribe-wrap-area pb-100">
    <div class="container">
        <div class="subscribe-wrap-inner-box">
            <div class="subscribe-wrap-box-content">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="subscribe-content">
                            <h3>Subscribe our newsletter</h3>
                            <p>Don’t miss any update on new promotions</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <form class="newsletter-form">
                            <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                            <button type="submit" class="btn btn-primary">Subscribe Now<span></span></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subscribe Wrap Area -->