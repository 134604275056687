import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-details1',
  templateUrl: './blog-details1.component.html',
  styleUrls: ['./blog-details1.component.scss']
})
export class BlogDetails1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
