<!-- Start Navbar Area -->
<div class="navbar-area {{layoutClass}}">
    <div class="klob-nav">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/{{clogo}}" alt="logo">
                    <img src="assets/img/{{clogo}}" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link" routerLink="/" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home </a>
                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}" class="nav-link">Home Demo - 1</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/demo-2" routerLinkActive="active" class="nav-link">Home Demo - 2</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/demo-3" routerLinkActive="active" class="nav-link">Home Demo - 3</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/demo-4" routerLinkActive="active" class="nav-link">Home Demo - 4</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/demo-5" routerLinkActive="active" class="nav-link">Home Demo - 5</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/demo-6" routerLinkActive="active" class="nav-link">Home Demo - 6</a>
                                </li>
                            </ul> -->
                        </li>
                        <li class="nav-item">
                            <a routerLink="/about-us" routerLinkActive="active" class="nav-link">About Us</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/services" routerLinkActive="active" class="nav-link">Services</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/courses" routerLinkActive="active" class="nav-link">Courses</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/java-advance-questions" routerLinkActive="active" class="nav-link">Questions</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/java-advance-questions" routerLinkActive="active" class="nav-link">java-advance-questions</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/java-interview-quiz" routerLinkActive="active" class="nav-link">java-interview-quiz</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/java-interview-misc" routerLinkActive="active" class="nav-link">Interview-miscellanies-Questions</a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/aws-interview-Qustions" routerLinkActive="active" class="nav-link">Aws interview Qustions </a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/java-script-interview-questions" routerLinkActive="active" class="nav-link">java script interview questions </a>
                                </li>

                                <li class="nav-item">
                                    <a routerLink="/angular-interview-questions" routerLinkActive="active" class="nav-link">Angular Interview Questions </a>
                                </li>
                               
                            </ul>
                        </li>
                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">
                                Pages <i class="fas fa-chevron-down"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/about-us" routerLinkActive="active" class="nav-link">About Us</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/features" routerLinkActive="active" class="nav-link">Features</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/team" routerLinkActive="active" class="nav-link">Team</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/pricing" routerLinkActive="active" class="nav-link">Pricing</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/error-404" routerLinkActive="active" class="nav-link">404 Error Page</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/faq" routerLinkActive="active" class="nav-link">FAQ</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/signup" routerLinkActive="active" class="nav-link">Signup</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/signin" routerLinkActive="active" class="nav-link">Signin</a>
                                </li>
                            </ul>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a routerLink="/courses" routerLinkActive="active" class="nav-link">Our Courses</a>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a routerLink="/admission-process" routerLinkActive="active" class="nav-link">Admission
                                Process</a>
                        </li> -->
                        <li class="nav-item">
                            <a routerLink="/blog" routerLinkActive="active" class="nav-link">Blog</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Blog <i class="fas fa-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a routerLink="/blog" routerLinkActive="active" class="nav-link">Blog</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/blog-right-sidebar" routerLinkActive="active" class="nav-link">Blog Right Sidebar</a>
                                </li>
                                <li class="nav-item">
                                    <a routerLink="/blog-details" routerLinkActive="active" class="nav-link">Blog Details</a>
                                </li>
                            </ul>
                        </li> -->
                        <li class="nav-item">
                            <a routerLink="/contact" routerLinkActive="active" class="nav-link">Contact</a>
                        </li>
                    </ul>
                    <div class="others-options">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link" routerLink="/signin" class="login-btn"><i
                                        class="flaticon-user">
                                    </i> Log In</a>
                                <!-- <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a routerLink="/signin"  class="nav-link">Students Login</a>
                                    </li>
                                    <li class="nav-item">
                                        <a href="http://localhost:1113/auth/login" target="_blank" class="nav-link">Trainer Login</a>
                                    </li>
                                </ul> -->
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar Area -->
