import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-java-interview-quiz',
  templateUrl: './java-interview-quiz.component.html',
  styleUrls: ['./java-interview-quiz.component.scss']
})
export class JavaInterviewQuizComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
