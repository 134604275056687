<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2>Get a call</h2>

        </div>
    </div>
</div>

<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="overview-box">
            <div class="overview-content">
                <div class="content left-content">
                    <h2> Get a Call From Our Executive</h2>
                    <div class="bar"></div>

                    <ul class="services-list1">
                        <li><span><i class="flaticon-check-mark"></i> Identifying the candidate's skills, experience, and career goals to assess whether the training program would be a good fit.</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Contacting the candidate by phone or email to schedule a call to discuss the training program in more detail.</span></li>
                        <li><span><i class="flaticon-check-mark"></i> Providing an overview of the training program, including the topics covered, the duration of the program, and any prerequisites or requirements.</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Discussing the benefits of the training program and how it can help the candidate acquire the skills and knowledge needed to succeed in their chosen field.</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Providing information on the cost of the training program and any financial assistance or payment plans that may be available.</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Answering any questions the candidate may have about the training program, including details on the course materials, instructors, and any additional resources or support provided.</span></li>
                        <li><span><i class="flaticon-check-mark"></i>Scheduling a follow-up call or meeting to discuss the candidate's enrollment in the training program and to provide any additional information or assistance needed.</span></li>

                    </ul>
                </div>
            </div>
            <div class="overview-image">
                <div class="image">
                    <img src="assets/img/1.png" alt="image">
                    <div class="circle-img">
                        <img src="assets/img/circle.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
