<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2> Courses</h2>
            <p>Useful & Popular Courses</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- <div class="features-area ptb-70 bg-f6f4f8">
    <div class="heading">
        <h2>Everything you need to succeed</h2>
        <p>Get the best blend of online learning, in-class support, and affordability to make it easy to start today and
            finish strong.</p>
    </div>
    <div class="box-container">
        <div class="row1">
            <div class="box box1">
                <div class="img">
                    <img src="https://www.nucamp.co/assets/imgs/homepage/benefits/graphic-instructor-led2.svg" alt="">
                </div>
                <div class="content">
                    <h4>Instructor-led Experience</h4>
                    <p>You're not alone. Each bootcamp is limited to a maximum of 15 students and a dedicated instructor
                        who is available daily to help you succeed.</p>
                </div>
            </div>


            <div class="box box2">
                <div class="img">
                    <img src="https://www.nucamp.co/assets/imgs/homepage/benefits/graphic-food.svg" alt="">
                </div>
                <div class="content">
                    <h4>Unique Classroom Experience</h4>
                    <p>Meet your classmates online every week, collaborate on projects and do peer-programming. Become
                        part of a caring community of learners like you.</p>
                </div>
            </div>

            <div class="box box3">
                <div class="img">
                    <img src="https://www.nucamp.co/assets/imgs/homepage/benefits/graphic-workshop.svg" alt="">
                </div>
                <div class="content">
                    <h4>Weekly Live Online Workshops</h4>
                    <p>Stay on track with collaborative, live online, 4-hour workshops with the instructor and up to 15
                        students as you work through course projects.</p>
                </div>
            </div>

            <div class="box box4">
                <div class="img">
                    <img src="https://www.nucamp.co/assets/imgs/homepage/benefits/graphic-courses4.svg" alt="">
                </div>
                <div class="content">
                    <h4>Proven Courses & Curriculum</h4>
                    <p>Build confidence knowing course content was designed by former Microsoft Learning experts based
                        on open-source content from renowned professors.</p>
                </div>
            </div>

            <div class="box box5">
                <div class="img">
                    <img src="https://www.nucamp.co/assets/imgs/homepage/benefits/graphic-portfolio.svg" alt="">
                </div>
                <div class="content">
                    <h4>Start Building Your Coding Portfolio</h4>
                    <p>Learn better by doing. In addition to the weekly assignments, you will be working on your own
                        coding project you can add to your portfolio.</p>
                </div>
            </div>

            <div class="box box6">
                <div class="img">
                    <img src="https://www.nucamp.co/assets/imgs/homepage/benefits/graphic-career-services-fuchsia.svg"
                        alt="">
                </div>
                <div class="content">
                    <h4>Career & Job Placement Services</h4>
                    <p>Get 1:1 career coaching, access to an exclusive job board and career development course, and
                        hands-on help with your resume and LinkedIn recommendations.</p>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- Start Pricing Area -->
<div class="pricing-area ptb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <a routerLink="/java-syllabus-details">
                    <img src="assets/img/course/java-fullstack-development.jpeg" alt="Java">
                </a>
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <a routerLink="/java-syllabus-details">
                            <h3><b>Full Strack Java Developer</b></h3>
                        </a>
                        <p>A full-stack Java developer is a professional who is skilled in end-to-end
                            software
                            development. So, full-stack Java developers should have front-end development
                            skills (HTML,
                            CSS, Javascript....</p>
                    </div>
                    <div class="buy-btn mt-3">
                        <a routerLink="/java-syllabus-details" class="btn btn-primary">More This Course</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <a routerLink="/python">
                    <img src="assets/img/course/Scientific-Python.webp" alt="python">
                </a>
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <a routerLink="/python">
                            <h3><b>Python</b></h3>
                        </a>
                        <p>Python is by far, the most preferred programming language in Artificial
                            Intelligence, Big
                            Data, Internet of Things, Robotics and Web Development. By training you to
                            become an Python
                            Developer...</p>
                    </div>
                    <div class="buy-btn mt-3">
                        <a routerLink="/python" class="btn btn-primary">More This Course</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <a routerLink="/quality-assurance">
                    <img src="assets/img/course/qa-engineer.jpg" alt="qa-engineer"></a>
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <a routerLink="/quality-assurance">
                            <h3><b> Assurance (QA)</b></h3>
                        </a>
                        <p>The world is becoming more digitalized by the passing day. You use software and applications
                            daily, from smart phone to laptop. Who is responsible for ensuring they work
                            properly? That’s where QA specialists come in...</p>
                    </div>
                    <div class="buy-btn mt-3">
                        <a routerLink="/quality-assurance" class="btn btn-primary">More This Course</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <a routerLink="/data-analyst">
                    <img src="assets/img/course/data-analiyst.png" alt="data-analiyst"></a>
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <a routerLink="/data-analyst">
                            <h3><b>Data Analyst</b></h3>
                        </a>
                        <p>A data analyst collects, cleans, and interprets data sets in order to answer a question or
                            solve a problem. They work in many industries, including business, finance, criminal
                            justice, medicine, and government. what skills you'll need, and how you can start
                            on a path to become one...</p>
                    </div>
                    <div class="buy-btn mt-3">
                        <a routerLink="/data-analyst" class="btn btn-primary">More This Course</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <a routerLink="/data-engineer">
                    <img src="assets/img/course/data-engg.png" alt="data-engg"></a>
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <a routerLink="/data-engineer">
                            <h3><b>Data Engineer</b></h3>
                        </a>
                        <p>A Data Engineer should be able to design, build, operationalize, secure, and monitor data
                            processing systems with a particular emphasis on security and compliance; scalability and
                            efficiency; reliability and fidelity; and flexibility and portability...</p>
                    </div>
                    <div class="buy-btn mt-3">
                        <a routerLink="/data-engineer" class="btn btn-primary">More This Course</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <a routerLink="/data-scientist">
                    <img src="assets/img/course/data-science.png" alt="data-science"></a>
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <a routerLink="/data-scientist">
                            <h3><b>Data Scientist</b></h3>
                        </a>
                        <p>Data scientists are big data wranglers, gathering and analyzing large sets of structured and
                            unstructured data. A data scientist’s role combines computer science, statistics, and
                            mathematics. They analyze, process, and model data...</p>
                    </div>
                    <div class="buy-btn mt-3">
                        <a routerLink="/data-scientist" class="btn btn-primary">More This Course</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Pricing Area -->

<!-- <app-account-button></app-account-button> -->