import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-java-advance-question',
  templateUrl: './java-advance-question.component.html',
  styleUrls: ['./java-advance-question.component.scss']
})
export class JavaAdvanceQuestionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
