import { SharedData } from "./../shared/shareddata";
import { WebsiteService } from "./../shared/websiteservice.service";
import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
    email: string;
    phone: string;
    clogo: string;
    title: string;
    address: string;
    year: string;
    constructor(private websiteService: WebsiteService) {}

    ngOnInit() {
        this.websiteService
            .getShareData()
            .subscribe((sharedData: SharedData) => {
                this.email = sharedData.email;
                this.phone = sharedData.phone;
                this.clogo = sharedData.logo;
                this.title = sharedData.title;
                this.address = sharedData.address;
                this.year = sharedData.year;
            });
    }
}
