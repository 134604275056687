<!-- Start Banner Area -->

<div class="banner-section">
    <div class="container">
        <div class="row m-0 align-items-center">
            <div class="col-lg-5 col-md-12 p-0">
                <div class="hero-content">
                    <div class="profile-container">
                        <div class="profile-img">
                            <img src="assets/img/client-image/client1.jpg" alt="image">
                        </div>
                        <h1> </h1>
                        <div class="Name">
                            <h3 style="color:brown; font-style: italic;">{{userDetail.name | uppercase}}</h3>
                        </div>
                    </div>
                    <div class="contact-info">
                        <ul>
                            <li>
                                <div class="icon "
                                    style="height:50px; width: 50px; display: flex;align-items: center; justify-content: center;">
                                    <i class="fas fa-map-marker-alt"></i>
                                </div>
                                <span style="font-weight: 400;">ADDRESS</span>
                                <span style="font-weight:200;"> ({{userDetail.address | uppercase}})</span>
                            </li>
                            <li>
                                <div class="icon"
                                    style="height:50px; width: 50px; display: flex;align-items: center; justify-content: center;">
                                    <i class="fas fa-envelope"></i>
                                </div>
                                <span style="font-weight: 400;">EMAIL</span>
                                <a href="mailto:Info@pykube.com" style="font-weight: 400;">({{userDetail.email}})</a>

                                <!-- <a href="mailto:hello@klob.com">fax@email.com</a> -->
                            </li>
                            <li>
                                <div class="icon"
                                    style="height:50px; width: 50px; display: flex;align-items: center; justify-content: center;">
                                    <i class="fas fa-phone-volume"></i>
                                </div>
                                <span style="font-weight: 400;">PHONE</span>
                                <a href="tel:(281) 271 5552" style="font-weight: 400;">({{userDetail.mobile}})</a>
                                <!-- <a href="tel:+44587154756">+55 5555 14574</a> -->
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12 p-0">
                <div class="hero-image">
                    <div class="main-image">
                        <img src="assets/img/banner-image/recruit.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                        <!-- <img src="assets/img/banner-image/banner2.png" class="wow animate__animated animate__fadeInLeft" data-wow-delay="1.3s" alt="image"> -->
                        <!-- <img src="assets/img/banner-image/banner3.png" class="wow animate__animated animate__fadeInUp" data-wow-delay="1s" alt="image"> -->
                    </div>
                    <div class="main-mobile-image">
                        <img src="assets/img/banner-image/main-pic.png" class="wow animate__animated animate__zoomIn"
                            data-wow-delay="0.7s" alt="image">
                    </div>
                    <div class="circle-image">
                        <img src="assets/img/banner-image/circle1.png" class="wow animate__animated animate__fadeInUp"
                            data-wow-delay="1s" alt="image">
                    </div>
                    <!-- <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" class="video-btn popup-youtube"><i
              class="fas fa-play"></i> Play Video</a> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Banner Area -->


<!-- Start Information Area -->
<div class="information-area">
    <div class="container" *ngIf="testShow">
        <div class="row align-items-center">
            <h5 style="margin-bottom: 20px;">Assigned Test(s)</h5>
            <hr>
            <ng-container *ngFor="let item of assignedTestData">
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-information-box">
                        <div class="icon">
                            <i> class="flaticon-piggy-bank"</i>
                        </div>
                        <h3>{{item.testName}}&nbsp;&nbsp;({{item.numberOfQuestions}})</h3>
                        ({{item.techName}})
                        <p>Duration : {{item.durationInMin}}</p>
                        <p>Expire on : {{item.testExpireOn | date:'dd-MMM+-yyyy'}}</p>
                        <a href="" style="color: red;"
                            (click)="startTest(item.testName,item.techName,item.userId)">startTest</a>
                    </div>
                </div>
            </ng-container>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-information-box">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="container">
            <div class="box">
                <h5>Attendance Details</h5>
                <hr>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Day</th>
                            <th scope="col">Topic</th>
                            <th scope="col">Description</th>
                            <th scope="col">Trainer</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="attendanceShow">
                        <tr *ngFor="let item of studentAttendances.consultantAttendanceList">
                            <td scope="row">{{item.doclass | date: 'dd-MMM-yyyy'}}</td>
                            <td>{{item.day}}</td>
                            <td><i class="fa-brands fa-java"></i>{{item.topics}}</td>
                            <td>{{item.description}}</td>
                            <td>{{item.trainer}}</td>
                            <td *ngIf="item.status=='Present'" style="color:green">{{item.status}}</td>
                            <td *ngIf="item.status=='Absent'" style="color:red">{{item.status}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="services-area ptb-70">
    <div class="container-fluid p-0">
        <div class="container">
            <div class="box">
                <h5>Technical Test History</h5>
                <hr>
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Date</th>
                            <th scope="col">testName</th>
                            <th scope="col">Technology</th>
                            <th scope="col">Total Question</th>
                            <th scope="col">Total Marks</th>
                            <th scope="col">Status</th>
                            <th scope="col">showHistoryDetails</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="testHistoryShow">
                        <tr *ngFor="let item of historyDetails">
                            <td scope="row">{{item.dateOfTest | date: 'dd-MMM-yyyy'}}</td>
                            <td (click)="showTestSummary(item.userId, item.userSessionId)"><a
                                    style="color: red;">{{item.testName}}</a></td>
                            <td><i class="fa-brands fa-java"></i><a>{{item.techName}}</a></td>
                            <td>{{item.totalNoQuestion}}</td>
                            <td>{{item.totalNoAnsweredQuestion}}</td>
                            <td *ngIf="item.examStatus=='complete'" style="color: green">{{item.examStatus}}</td>
                            <td *ngIf="item.examStatus=='incomplete'" span style="color: red">{{item.examStatus}}</td>
                            <td
                                (click)="showHistoryDetails(item.testName,item.techName,item.userId, item.userSessionId)">
                                <span> <a href="" style="color: red;"><i class="fa fa-arrow-right 2x"> </i></a></span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <button class="btn btn-primary" (click)="logout()"><i class="fa fa-arrow-left ">
        </i>&nbsp;&nbsp;logout</button>
</div>


<!-- <button>{{ consultantsService.isLoggedIn | async ? 'Logout' : 'Login' }}</button> -->
<!-- <button>{{ userService.isLoggedIn | async ? 'Logout' : 'Login' }}</button> -->
