<!-- Start Page Title Area -->
<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <h2> Courses</h2>
            <p>Useful & Popular Courses</p>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- <div class="features-area ptb-70 bg-f6f4f8">
    <div class="heading">
        <h2>Everything you need to succeed</h2>
        <p>Get the best blend of online learning, in-class support, and affordability to make it easy to start today and
            finish strong.</p>
    </div>
    <div class="box-container">
        <div class="row1">
            <div class="box box1">
                <div class="img">
                    <img src="https://www.nucamp.co/assets/imgs/homepage/benefits/graphic-instructor-led2.svg" alt="">
                </div>
                <div class="content">
                    <h4>Instructor-led Experience</h4>
                    <p>You're not alone. Each bootcamp is limited to a maximum of 15 students and a dedicated instructor
                        who is available daily to help you succeed.</p>
                </div>
            </div>


            <div class="box box2">
                <div class="img">
                    <img src="https://www.nucamp.co/assets/imgs/homepage/benefits/graphic-food.svg" alt="">
                </div>
                <div class="content">
                    <h4>Unique Classroom Experience</h4>
                    <p>Meet your classmates online every week, collaborate on projects and do peer-programming. Become
                        part of a caring community of learners like you.</p>
                </div>
            </div>

            <div class="box box3">
                <div class="img">
                    <img src="https://www.nucamp.co/assets/imgs/homepage/benefits/graphic-workshop.svg" alt="">
                </div>
                <div class="content">
                    <h4>Weekly Live Online Workshops</h4>
                    <p>Stay on track with collaborative, live online, 4-hour workshops with the instructor and up to 15
                        students as you work through course projects.</p>
                </div>
            </div>

            <div class="box box4">
                <div class="img">
                    <img src="https://www.nucamp.co/assets/imgs/homepage/benefits/graphic-courses4.svg" alt="">
                </div>
                <div class="content">
                    <h4>Proven Courses & Curriculum</h4>
                    <p>Build confidence knowing course content was designed by former Microsoft Learning experts based
                        on open-source content from renowned professors.</p>
                </div>
            </div>

            <div class="box box5">
                <div class="img">
                    <img src="https://www.nucamp.co/assets/imgs/homepage/benefits/graphic-portfolio.svg" alt="">
                </div>
                <div class="content">
                    <h4>Start Building Your Coding Portfolio</h4>
                    <p>Learn better by doing. In addition to the weekly assignments, you will be working on your own
                        coding project you can add to your portfolio.</p>
                </div>
            </div>

            <div class="box box6">
                <div class="img">
                    <img src="https://www.nucamp.co/assets/imgs/homepage/benefits/graphic-career-services-fuchsia.svg"
                        alt="">
                </div>
                <div class="content">
                    <h4>Career & Job Placement Services</h4>
                    <p>Get 1:1 career coaching, access to an exclusive job board and career development course, and
                        hands-on help with your resume and LinkedIn recommendations.</p>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- Start Pricing Area -->
<div class="pricing-area ptb-70">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <a routerLink="/java-syllabus-details">
                    <img src="assets/img/course/java-fullstack-development.jpeg" alt="python">
                </a>
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Full Strack Java Developer</h3>
                        <p>A full-stack Java developer is a professional who is skilled in end-to-end
                            software
                            development. So, full-stack Java developers should have front-end development
                            skills (HTML,
                            CSS, Javascript....</p>
                    </div>
                    <!-- <div class="price">
                        $0 <span>/m</span>
                    </div> -->
                    <div class="buy-btn mt-3">
                        <a routerLink="/java-syllabus-details" class="btn btn-primary">More This Course</a>
                    </div>
                    <!-- <ul class="pricing-features">
                        <li><i class="fas fa-check"></i> 100 outgoing transfers/mo</li>
                        <li><i class="fas fa-check"></i> 5 Klob Users </li>
                        <li><i class="fas fa-check"></i> 2 Business Mastercards</li>
                        <li><i class="fas fa-check"></i> Premium Support</li>
                        <li><i class="fas fa-check"></i> International payments</li>
                        <li><i class="fas fa-check"></i> Accounting integrations</li>
                    </ul> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <img src="assets/img/course/Python-DataScience.png" alt="python">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Data Science/Python Developer</h3>
                        <p>Python is by far, the most preferred programming language in Artificial
                            Intelligence, Big
                            Data, Internet of Things, Robotics and Web Development. By training you to
                            become an Python
                            Developer ...</p>
                    </div>
                    <!-- <div class="price">

                        $12 <span>/m</span>
                    </div> -->
                    <div class="buy-btn mt-3">
                        <a routerLink="/data-science/python" class="btn btn-primary">More This Course</a>
                    </div>
                    <!-- <ul class="pricing-features">
                        <li><i class="fas fa-check"></i> 100 outgoing transfers/mo</li>
                        <li><i class="fas fa-check"></i> 5 Klob Users </li>
                        <li><i class="fas fa-check"></i> 2 Business Mastercards</li>
                        <li><i class="fas fa-check"></i> Premium Support</li>
                        <li><i class="fas fa-check"></i> International payments</li>
                        <li><i class="fas fa-check"></i> Accounting integrations</li>
                    </ul> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <img src="assets/img/course/dotnet_dev.jpg" alt="python">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Full Stack Dotnet Developer</h3>
                        <p>.NET is a free, cross-platform, open source developer platform for building many
                            different
                            types of applications. With .NET, you can use multiple languages, editors, and
                            libraries to
                            build ...</p>
                    </div>
                    <!-- <div class="price">
                        $24 <span>/m</span>
                    </div> -->
                    <div class="buy-btn mt-3">
                        <a routerLink="/" class="btn btn-primary">More This Course</a>
                    </div>
                    <!-- <ul class="pricing-features">
                        <li><i class="fas fa-check"></i> 100 outgoing transfers/mo</li>
                        <li><i class="fas fa-check"></i> 5 Klob Users </li>
                        <li><i class="fas fa-check"></i> 2 Business Mastercards</li>
                        <li><i class="fas fa-check"></i> Premium Support</li>
                        <li><i class="fas fa-check"></i> International payments</li>
                        <li><i class="fas fa-check"></i> Accounting integrations</li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Pricing Area -->

<!-- <app-account-button></app-account-button> -->
